<template>
    <modal id="hotel-modal"
        size="large"
        v-model="showModal"
        :title="generateModalTitle('hotel')"
        @submit="submitData" :loading="loading"
        :preloader="preloader">

        <form
            ref="form"
            :data-url="`${TENANT_BASE_URL}app/hotel/list`"
            @submit.prevent="submitData"
        >
            <div class="form-group">
                <div class="row">
                    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
                        <label for="hotel-thumbnail">{{ $t('thumbnail') }}</label>
                    </div>
                    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
                        <app-input
                            id="hotel-thumbnail"
                            type="custom-file-upload"
                            :generate-file-url="false"
                            v-model="formData.thumbnail"
                            :label="$t('change_image')"
                            :error-message="$errorMessage(errors, 'thumbnail')"
                        />
                    </div>
                </div>
            </div>
            <app-form-group
                page="page"
                :label="$t('name')"
                type="text"
                id="input-text-name"
                :placeholder="$placeholder('name', '')"
                v-model="formData.name"
                :error-message="$errorMessage(errors, 'name')"
            />
            <app-form-group
                page="page"
                :label="$t('acronym')"
                type="text"
                id="input-text-acronym"
                :placeholder="$placeholder('acronym', '')"
                v-model="formData.acronym"
                :error-message="$errorMessage(errors, 'acronym')"
                @keypress="validateString($event)"
                @change="validateString($event)"
            >
                <template slot="suggestion">
                    <small class="text-muted font-italic mt-3 d-inline-block">
                        {{ $t('acronym_recommendation') }}
                    </small>
                </template>
            </app-form-group>
            <app-form-group
                page="page"
                :label="$t('address')"
                type="text"
                id="input-text-address"
                :placeholder="formData.address ? $placeholder('address') : this.$t('not_added_yet')"
                v-model="formData.address"
                :error-message="$errorMessage(errors, 'address')"
            />
            <app-form-group
                page="page"
                :label="$t('password')"
                type="password"
                id="input-text-password"
                :placeholder="$t('password')"
                v-model="formData.password"
                :error-message="$errorMessage(errors, 'password')"
                :show-password="true"
            />
            <app-form-group
                page="page"
                labelAlignment="top"
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :error-message="$errorMessage(errors, 'remark')"
            />
            <app-form-group
                page="page"
                labelAlignment="top"
                :label="$t('access')"
                type="text-editor"
                id="input-text-access"
                :placeholder="$textAreaPlaceHolder('access')"
                v-model="formData.access"
                :error-message="$errorMessage(errors, 'access')"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "HotelCreateModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#hotel-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'hotel-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
        validateString: function (e) {
            if (e.type === 'change') {
                const newVal = []
                const value  = e.target.value.split('')

                for (const str of value) {
                    if (/^[A-Z1-9]+$/.test(str)) {
                        newVal.push(str)
                    }
                }

                this.formData.acronym = newVal.join('')

                return true
            }

            const char = String.fromCharCode(e.keyCode);

            if (/^[A-Z1-9]+$/.test(char)) {
                return true
            } else {
                e.preventDefault()
            }
        }
    },
}
</script>

