import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('claim_type'),
                url              : `${TENANT_BASE_URL}app/hotel/claim-type`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('title'),
                        type     : 'text',
                        key      : 'title',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'textarea',
                        key      : 'remark',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_claim_type')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/claim-type/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_hotel_claim_type')
                    }
                ],
            }
        }
    },
}