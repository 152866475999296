<template>
    <modal id="lost-found-modal"
           v-model="showModal"
           :title="generateModalTitle('lost_and_found')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='`${TENANT_BASE_URL}app/hotel/lost-found`'
            @submit.prevent="submitData"
        >
            <div class="form-group">
                <label>{{ $t('attachments') }}</label>
                <app-input
                    type="dropzone"
                    v-model="files"
                    :error-message="$errorMessage(errors, 'files.0')"
                />
            </div>
            <app-form-group
                type="date"
                :label="$t('date')"
                v-model="formData.date"
                :placeholder="formData.date ? $placeholder('date') : this.$t('not_added_yet')"
                :error-message="$errorMessage(errors, 'date')"
            />
            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {formatDateForServer} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "LostFoundCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        room : {
            type: Object
        },
        hotel: {
            type: Object
        }
    },
    data() {
        return {
            TENANT_BASE_URL,
            files   : [],
            formData: {},
        }
    },
    methods: {
        submitData() {
            this.formData.date = this.formData.date ? formatDateForServer(this.formData.date) : '';

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            this.files.forEach(file => formData.append('files[]', file))

            formData.append('hotel_id', this.hotel.id);
            formData.append('room_id', this.room.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#lost-found-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'room-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

