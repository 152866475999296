import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";
import {HotelFilterMixin} from "./FilterMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('achievement'),
                url              : `${TENANT_BASE_URL}app/hotel/achievement`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('hotel'),
                        type     : 'custom-html',
                        key      : 'hotel',
                        isVisible: true,
                        modifier : (value) => value.name
                    },
                    {
                        title    : this.$t('floor'),
                        type     : 'text',
                        key      : 'floor',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('document'),
                        type     : 'custom-html',
                        key      : 'files',
                        isVisible: true,
                        modifier : (files) => {
                            let html = '';

                            if (files.length > 0) {
                                for (const file_url of files) {
                                    html += `<a href="${urlGenerator(file_url)}" class="d-block">${this.$t('download')}</a>`
                                }
                            }

                            return html
                        }
                    },
                    {
                        title    : this.$t('date'),
                        type     : 'custom-html',
                        key      : 'date',
                        isVisible: true,
                        modifier : (value) => value ? formatDateToLocal(value) : 'N/A'
                    },
                    {
                        title    : this.$t('created'),
                        type     : 'custom-html',
                        key      : 'created_at',
                        isVisible: true,
                        modifier : (value) => value ? formatDateToLocal(value) : 'N/A'
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('hotel'),
                        type          : "multi-select-filter",
                        key           : "hotels",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_list')
                    },
                    {
                        title         : this.$t('floor'),
                        type          : "multi-select-filter",
                        key           : "floor",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_room')
                    },
                    {
                        title : this.$t('date'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_achievement')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/achievement/`,
                        name     : 'delete',
                        modifier : row => row.achievement && this.$can('update_hotel_achievement')
                    }
                ],
            }
        }
    },
    methods: {
        getFilterValues(value) {
            const roomFilter = this.options.filters.find(item => item.key === 'floor');

            if (value.hotels) {
                axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${value.hotels}&group=floor&format=floor`)
                .then(({data}) => roomFilter.option = data)
                .catch(error => this.$toastr.e(error.response.data.message))
            } else {
                roomFilter.option = []
            }
        },
    },
}