<template>
  <modal id="employee-day-shift-create-multiple-modal"
         size="large"
         v-model="showModal"
         :title="$fieldTitle(buttonFirstTitle, 'employee_day_shift', true)"
         @submit="submitData"
         :scrollable="false"
         :loading="loading"
         :preloader="preloader">
    <form
        ref="form"
        @submit.prevent="submitData"
    >
      <app-form-group-selectable
          :label="$t('month')"
          type="search-select"
          v-if="canUpdateStatus"
          v-model="formData.date"
          list-value-field="name"
          :placeholder="$placeholder('month', '')"
          :error-message="$errorMessage(errors, 'date')"
          :fetch-url="`${apiUrl.COMMON}/get-months`"
      />

      <app-form-group-selectable
          type="multi-select"
          :label="$t('employee')"
          list-value-field="full_name"
          v-model="formData.user_ids"
          form-group-class="mb-0"
          :error-message="$errorMessage(errors, 'user_ids') || $errorMessage(errors, 'user_ids.*')"
          :fetch-url="`${apiUrl.SELECTABLE_WORK_SHIFT_USERS}?without=admin&excluded_departments=${formData.departments}&employee=only`"
      />

      <div class="row pt-3">
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('monday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.monday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'monday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('tuesday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.tuesday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'tuesday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('wednesday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.wednesday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'wednesday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('thursday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.thursday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'thursday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('friday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.friday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'friday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('saturday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.saturday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'saturday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-form-group-selectable
              :label="$t('sunday') + ' ' + $t('day_shift')"
              type="search-select"
              v-if="canUpdateStatus"
              v-model="formData.sunday_shift_id"
              list-value-field="name"
              :placeholder="$t('search_and_select_a_day_shift')"
              :error-message="$errorMessage(errors, 'sunday_shift_id')"
              :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
          />
        </div>
      </div>

      <app-form-group
          :label="$t('short_description')"
          type="textarea"
          form-group-class="mb-0"
          :text-area-rows="4"
          v-model="formData.description"
          list-value-field="pivot.description"
          :placeholder="$placeholder('description_here')"
          :error-message="$errorMessage(errors, 'description')"
      />
    </form>
  </modal>
</template>

<script>
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {
  formatDateTimeForServer,
  formatDateForServer,
  formatUtcToLocal
} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
  name: "EmployeeDayShiftCreateMultipleModal",
  mixins: [ModalMixin, FormHelperMixins],
  props: {
    tableId: {},
    employee: {},
    specificId: {},
  },
  data() {
    return {
      formData: {},
    }
  },
  mounted() {
    this.$nextTick(()=>{
      if (window.innerHeight < 700){
        document.getElementsByClassName('modal-body')[0].style.height = '560px'
      }
    })
  },
  methods: {
    submitData() {
      this.loading = true;
      const formData = {...this.formData};

      let requestUrl = `${this.apiUrl.EMPLOYEE_DAY_SHIFT}/create-multiple`;
      this.submitFromFixin('post', requestUrl, formData);
    },
    afterSuccess({data}) {
      this.loading = false;
      $('#employee-day-shift-create-multiple-modal').modal('hide');
      this.$toastr.s('', data.message);
      // if (this.tableId) {
      //     this.$hub.$emit(`reload-${this.tableId}`);
      // } else {
      this.$emit('createMultipleSuccess')
      // }
    },
  },
  created() {
    if (!this.employee && !this.canUpdateStatus && !this.specificId) {
      this.formData.user_id = window.user.id
    }

    if (this.specificId) {
      this.formData.user_id = this.specificId
    }

    if (this.selectedColumn) {
      this.formData.user_id = this.selectedColumn.user_id;
      this.formData.date = this.selectedColumn.date;
    }
  },
  computed: {
    canUpdateStatus() {
      if (this.specificId && this.$can('update_attendance_status')) {
        return false;
      }
      if (this.employee && this.$can('update_attendance_status')) {
        this.formData.user_id = this.employee.id;
        return false;
      }
      return this.$can('update_attendance_status');
    },
    user_id() {
      return window.user.id;
    },
    buttonFirstTitle() {
      return this.$can('update_attendance_status') ? 'edit' : 'request';
    }
  }
}
</script>