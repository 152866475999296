<template>
    <modal id="employee-day-shift-modal"
           size="large"
           v-model="showModal"
           :title="$fieldTitle(buttonFirstTitle, 'employee_day_shift', true)"
           @submit="submitData"
           :scrollable="false"
           :loading="loading"
           :preloader="preloader">
        <form
            ref="form"
            @submit.prevent="submitData"
        >
            <app-form-group-selectable
                :label="$t('employee')"
                type="search-select"
                v-if="canUpdateStatus"
                v-model="formData.user_id"
                list-value-field="full_name"
                :placeholder="$t('search_and_select_an_employee')"
                :error-message="$errorMessage(errors, 'user_id')"
                :fetch-url="`${apiUrl.TENANT_SELECTABLE_USER}?without=admin&employee=only&with_auth=yes`"
                disabled
            />

            <app-form-group-selectable
                :label="$t('day_shift')"
                type="search-select"
                v-if="canUpdateStatus"
                v-model="formData.day_shift_id"
                list-value-field="name"
                :placeholder="$t('search_and_select_a_day_shift')"
                :error-message="$errorMessage(errors, 'day_shift_id')"
                :fetch-url="`${apiUrl.DAY_SHIFT}?get-all=1`"
            />

            <app-form-group
                :label="$t('date')"
                type="date"
                v-model="formData.date"
                :required="true"
                :placeholder="$placeholder('date', '')"
                :error-message="$errorMessage(errors, 'date')"
                disabled
            />

            <app-form-group
                :label="$t('short_description')"
                type="textarea"
                form-group-class="mb-0"
                :text-area-rows="4"
                v-model="formData.description"
                list-value-field="pivot.description"
                :placeholder="$placeholder('description_here')"
                :error-message="$errorMessage(errors, 'description')"
            />
        </form>
    </modal>
</template>

<script>
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {
    formatDateTimeForServer,
    formatDateForServer,
    formatUtcToLocal
} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name: "EmployeeDayShiftCreateEditModal",
    mixins: [ModalMixin, FormHelperMixins],
    props: {
        tableId: {},
        employee: {},
        specificId: {},
        selectedColumn: {},
        selectedUrl: null,
    },
    data() {
        return {
            formData: {},
        }
    },
    mounted() {
        this.$nextTick(()=>{
            if (window.innerHeight < 700){
                document.getElementsByClassName('modal-body')[0].style.height = '560px'
            }
        })
    },
    methods: {
        submitData() {
            this.loading = true;
            const formData = {...this.formData};
            formData.date = formatDateForServer(this.formData.date);

            let requestUrl = `${this.apiUrl.EMPLOYEE_DAY_SHIFT}/update`;
            if (!this.selectedUrl) {
                requestUrl = `${this.apiUrl.EMPLOYEE_DAY_SHIFT}/create`;
            }
            this.submitFromFixin('post', requestUrl, formData);
        },
        afterSuccess({data}) {
            this.loading = false;
            $('#employee-day-shift-modal').modal('hide');
            this.$toastr.s('', data.message);
            if (this.tableId) {
                this.$hub.$emit(`reload-${this.tableId}`);
            } else {
                this.$emit('updateSuccess')
            }
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData = response.data;
            this.formData.user_id = this.formData.pivot.user_id;
            this.formData.day_shift_id = this.formData.pivot.day_shift_id;
            this.formData.date = this.formData.pivot.date;
            this.formData.description = this.formData.pivot.description;
        },
    },
    created() {
        if (!this.employee && !this.canUpdateStatus && !this.specificId) {
            this.formData.user_id = window.user.id
        }

        if (this.specificId) {
            this.formData.user_id = this.specificId
        }

        if (this.selectedColumn) {
            this.formData.user_id = this.selectedColumn.user_id;
            this.formData.date = this.selectedColumn.date;
        }
    },
    computed: {
        canUpdateStatus() {
            if (this.specificId && this.$can('update_attendance_status')) {
                return false;
            }
            if (this.employee && this.$can('update_attendance_status')) {
                this.formData.user_id = this.employee.id;
                return false;
            }
            return this.$can('update_attendance_status');
        },
        user_id() {
            return window.user.id;
        },
        buttonFirstTitle() {
            return this.$can('update_attendance_status') ? 'edit' : 'request';
        }
    }
}
</script>