<template>
    <div>
        <app-page-top-section :title="$t('pricing')">
            <app-default-button
                :title="$fieldTitle('add', 'pricing', true)"
                v-if="$can('create_hotel_pricing')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="pricing-table"
            :options="options"
            @action="triggerActions"
        />

        <app-hotel-pricing-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            :hotel="tab.hotel"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('pricing-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelPricingMixins from "../../../Mixins/HotelPricingMixins";

export default {
    name  : "HotelPricing",
    mixins: [FormHelperMixins, HotelPricingMixins],
    props : {
        id: {
            type: String
        }
    },
    data() {
        return {
            tab          : this.$parent.$props.tabs.find(e => e.component === 'app-hotel-pricing'),
            isModalActive: false,
            selectedUrl  : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/hotel/pricing/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    },
}
</script>