<template>
    <div>
        <app-page-top-section :title="$t('support')">
            <app-default-button
                :title="$fieldTitle('add', 'support', true)"
                v-if="$can('create_hotel_support')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="support-table"
            :options="options"
            @action="triggerActions"
        />

        <app-hotel-support-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="supportUrl"
            :hotel="tab.hotel"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('support-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelSupportMixins from "../../../Mixins/HotelSupportMixins";

export default {
    name  : "HotelSupport",
    mixins: [FormHelperMixins, HotelSupportMixins],
    props : {
        id: {
            type: String
        }
    },
    data() {
        return {
            tab          : this.$parent.$props.tabs.find(e => e.component === 'app-hotel-support'),
            isModalActive: false,
            supportUrl   : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.supportUrl    = `${TENANT_BASE_URL}app/hotel/support/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.supportUrl    = ''
        },
    },
}
</script>