var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-note',{staticClass:"mb-primary",attrs:{"title":_vm.$t('note'),"content-type":"html","notes":`<ol>
                    <li>${_vm.$t('any_type_of_change_will_be_effected_from_next_day')}</li>
                    <li>${_vm.$t('how_leave_settings_work_message',{
                        link: `<a href='#' target='_blank'>${_vm.$t('documentation')}</a>`
                    })}</li>
                    <li>${_vm.$t('cron_job_setting_warning')}</li>
                    <li>${_vm.$t('leave_carry_forward_message')}</li>
                </ol>`}}),_vm._v(" "),_c('app-table',{attrs:{"id":"leave-types-table","options":_vm.options},on:{"action":_vm.triggerActions}}),_vm._v(" "),(_vm.isModalActive)?_c('app-leave-type-create-edit',{attrs:{"selected-url":_vm.selectedUrl},model:{value:(_vm.isModalActive),callback:function ($$v) {_vm.isModalActive=$$v},expression:"isModalActive"}}):_vm._e(),_vm._v(" "),(_vm.confirmationModalActive)?_c('app-confirmation-modal',{attrs:{"icon":"trash-2","modal-id":"app-confirmation-modal"},on:{"confirmed":function($event){return _vm.confirmed('leave-types-table')},"cancelled":_vm.cancelled}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }