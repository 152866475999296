import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('ticket'),
                url              : `${TENANT_BASE_URL}app/ticket/history?ticket_id=` + this.ticketId,
                showHeader       : true,
                showSearch       : false,
                showFilter       : false,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('status'),
                        type     : 'custom-html',
                        key      : 'status_name',
                        isVisible: true,
                        modifier : (data, row) => {
                            if (row.status == 'closed') {
                                return '<div class="badge badge-pill badge-secondary">' + data + '</div>';
                            }
                            if (row.status == 'resolved') {
                                return '<div class="badge badge-pill badge-success">' + data + '</div>';
                            }
                            if (row.status == 'in_progress') {
                                return '<div class="badge badge-pill badge-warning">' + data + '</div>';
                            }
                            return '<div class="badge badge-pill badge-primary">' + data + '</div>';
                        }
                    },
                    {
                        title    : this.$t('changed_by'),
                        type     : 'custom-html',
                        key      : 'changed_by',
                        isVisible: true,
                        modifier : (changed_by, row) => {
                            return row.user.full_name;
                        }
                    },
                    {
                        title    : this.$t('created'),
                        type     : 'custom-html',
                        key      : 'created_at',
                        isVisible: true,
                        modifier : date => formatDateToLocal(date, true)
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : false,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [],
            }
        }
    },
}