<template>
    <modal id="commute-details-table-modal"
        size="large"
        v-model="showModal"
        :title="generateModalTitle('commute_details')"
        @submit="submitData" :loading="loading"
        :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/commute-details`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="search-select"
                        :label="$t('employee')"
                        list-value-field="full_name"
                        v-model="formData.employee_id"
                        form-group-class="mb-0"
                        :error-message="$errorMessage(errors, 'employee_id')"
                        :fetch-url="`${apiUrl.SELECTABLE_WORK_SHIFT_USERS}?without=admin&member=only&with_auth=yes`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('type')"
                        list-value-field="name"
                        v-model="formData.type_id"
                        :chooseLabel="$t('type')"
                        :error-message="$errorMessage(errors, 'type_id')"
                        :fetch-url="`${TENANT_BASE_URL}api/commute-details/type`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('date')"
                        :placeholder="$placeholder('date')"
                        v-model="formData.date"
                        :error-message="$errorMessage(errors, 'date')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('departure_station')"
                        :placeholder="$placeholder('departure_station')"
                        v-model="formData.departure_station"
                        :error-message="$errorMessage(errors, 'departure_station')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('arrival_station')"
                        :placeholder="$placeholder('arrival_station')"
                        v-model="formData.arrival_station"
                        :error-message="$errorMessage(errors, 'arrival_station')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('cost')"
                        :placeholder="$placeholder('cost')"
                        v-model="formData.cost"
                        :error-message="$errorMessage(errors, 'cost')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('purpose')"
                        list-value-field="name"
                        v-model="formData.purpose_id"
                        :chooseLabel="$t('purpose')"
                        :error-message="$errorMessage(errors, 'purpose_id')"
                        :fetch-url="`${TENANT_BASE_URL}api/commute-details/purpose`"
                    />
                </div>
                <div class="col-md-12">
                    <app-form-group
                        :label="$t('document')"
                        type="file"
                        v-model="formData.file"
                        :required="true"
                        :placeholder="$placeholder('document', '')"
                        :file-label="$placeholder('document', '')"
                        :error-message="$errorMessage(errors, 'file')"
                    >
                        <template slot="suggestion">
                            <small class="text-muted font-italic mt-3 d-inline-block">
                                {{ $t('document_recommendation') }}
                            </small>
                        </template>
                    </app-form-group>

                    <app-form-group
                        type="textarea"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :text-area-rows="4"
                        :error-message="$errorMessage(errors, 'remark')">
                    </app-form-group>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../common/Helper/Support/FormHelper";
import {formatDateForServer, today} from "../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "DetailsCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            TENANT_BASE_URL,
            formData: {
                date: formatDateForServer(today()),
            },
        }
    },
    methods: {
        submitData() {
            this.loading   = true
            const url      = this.$refs.form.dataset["url"];
            const formData = formDataAssigner(new FormData, this.formData);

            formData.set('date', formatDateForServer(this.formData.date))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#commute-details-table-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'commute-details-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

