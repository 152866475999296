<template>
    <div class="card card-with-shadow border-0 h-100 employee-preview-card">
        <div class="card-body position-relative d-flex flex-column justify-content-between">
            <div v-if="showAction" class="dropdown options-dropdown position-absolute">
                <button type="button"
                        class="btn-option btn d-flex align-items-center justify-content-center"
                        data-toggle="dropdown">
                    <app-icon name="more-horizontal"/>
                </button>
                <div class="dropdown-menu dropdown-menu-right py-2 mt-1">
                    <a class="dropdown-item px-4 py-2" href="#" v-for="(action,index) in actions" :key="index"
                       v-if="checkIfVisible(action, hotel)" @click.prevent="callAction(action)">
                        {{ action.title }}
                    </a>
                </div>
            </div>
            <div class="text-center">
                <app-avatar
                    avatar-class="avatars-w-100 mb-3 mx-auto d-inline-block"
                    :title="hotel.name"
                    :img="hotel.thumbnail || ''"
                />

                <p class="mb-2">{{ hotel.name }}<span v-if="hotel.acronym"> ({{ hotel.acronym }}) </span></p>

                <p class="mb-2 font-size-80 text-muted">{{ hotel.rooms_count + ' ' + $t('room') }}</p>

                <p class="mb-2 font-size-80 text-muted">{{ hotel.members_count + ' ' + $t('employee') }}</p>
            </div>
            <div class="text-center mt-3">
                <a href="#" @click.prevent="callAction(edit_action)" class="btn btn-primary rounded-pill">
                    {{ $t('view_details') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import CoreLibrary from "../../../../../core/helpers/CoreLibrary";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";

export default {
    name   : "HotelPreviewCard",
    extends: CoreLibrary,
    mixins : [FormHelperMixins],
    props  : {
        hotel     : {
            type    : Object,
            required: true
        },
        actions   : {
            type   : Array,
            require: true
        },
        showAction: {
            type   : Boolean,
            default: true
        },
        id        : {
            type   : String,
            require: true
        }
    },
    data() {
        return {
            edit_action: this.actions.find(e => e.title === this.$t('edit')),
        }
    },
    methods : {
        callAction(action) {
            this.$emit('action-' + this.id, this.hotel, action, true)
        },
        checkIfVisible(action, hotel) {
            if (this.isFunction(action.modifier)) {
                return action.modifier(hotel);
            }
            return true;
        },
    },
}
</script>