import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal, onlyTime} from "../../../common/Helper/Support/DateTimeHelper";
import {HotelFilterMixin} from "./FilterMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
    data() {
        return {
            options: {
                name             : this.$t('checking'),
                url              : `${TENANT_BASE_URL}app/hotel/checking`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('hotel'),
                        type     : 'custom-html',
                        key      : 'hotel',
                        isVisible: true,
                        modifier : (hotel) => {
                            return hotel ? hotel.name : '';
                        }
                    },
                    {
                        title    : this.$t('room'),
                        type     : 'custom-html',
                        key      : 'room',
                        isVisible: true,
                        modifier : (room) => {
                            return room ? room.number : '';
                        }
                    },
                    {
                        title    : this.$t('date'),
                        type     : 'custom-html',
                        key      : 'date',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title    : this.$t('start_time'),
                        type     : 'text',
                        key      : 'start_time',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('end_time'),
                        type     : 'text',
                        key      : 'end_time',
                        isVisible: true,
                    },
                    {
                        title        : this.$t('shortcoming'),
                        type         : 'component',
                        key          : 'shortcoming',
                        isVisible    : true,
                        componentName: 'app-hotel-checking-confirmed',
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('hotel'),
                        type          : "multi-select-filter",
                        key           : "hotels",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_list')
                    },
                    {
                        title : this.$t('date'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_checking')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/checking/`,
                        name     : 'delete',
                        modifier : row => this.$can('update_hotel_checking')
                    }
                ],
            }
        }
    },
}