<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('summery')">
            <div class="d-flex d-inline">
                <app-default-button
                    btn-class="btn btn-success mr-2"
                    :title="$t('export_all')"
                    @click="viewConfirmationModal('allEmployee')"
                />
                <app-default-button
                    btn-class="btn btn-success mr-2"
                    :title="$t('export')"
                    @click="viewConfirmationModal('employee')"
                />
                <div class="btn-group dropdown">
                    <button class="btn btn-primary rounded-right px-3" data-toggle="dropdown">
                        {{ $t('another_report') }}<i class="fas fa-chevron-down fa-sm ml-2"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <app-default-button
                            :title="$t('type')"
                            btn-class="dropdown-item"
                            @click="viewConfirmationModal('type')"
                        />
                        <app-default-button
                            :title="$t('members')"
                            btn-class="dropdown-item"
                            @click="viewConfirmationModal('members')"
                        />
                        <app-default-button
                            :title="$t('benefit')"
                            btn-class="dropdown-item"
                            @click="viewConfirmationModal('benefit')"
                        />
                    </div>
                </div>
            </div>
        </app-page-top-section>

        <app-filter-with-search
            :options="tableOptions"
            @filteredValue="setUserId"
        />

        <div class="card card-with-shadow border-0" style="min-height: 400px;">
            <div class="card-header d-flex align-items-center justify-content-between primary-card-color p-primary">
                <app-month-calendar/>
                <app-period-calendar/>
            </div>

            <app-overlay-loader v-if="preloader"/>

            <div class="card-body p-primary" v-else>
                <div v-if="employee" class="d-flex justify-content-between">
                    <div class="media align-items-center">
                        <app-avatar
                            :title="employee.full_name"
                            avatar-class="avatars-w-50"
                            :status="$optional(employee, 'status', 'class')"
                            :img="$optional(employee, 'profile_picture', 'full_url')"
                        />

                        <div class="media-body ml-3">
                            <a :href="profileUrl">{{ employee.full_name }}</a>
                            <p class="text-muted font-size-90 mb-0">
                                {{ hotel.name }}, {{ employee.profile.employee_id }}
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <a href="#" @click="changeUser('previous')" :class="{'disabled text-muted' : isPreviousNull}">
                            {{ $t('previous') }}
                        </a>
                        <span class="text-muted px-2">|</span>
                        <a href="#" @click="changeUser('next')" :class="{'disabled text-muted' : isNextNull}">
                            {{ $t('next') }}
                        </a>
                    </div>
                </div>

                <hotel-summary
                    :summaries="summaries"
                    :data="dataSet"
                    :room_type="room_type"
                />

                <app-table
                    id="house-keeping-table"
                    :options="options"
                    :filteredData="filterData"
                />
            </div>
        </div>

        <app-confirmation-modal
            v-if="exportConfirm"
            :title="modalSubtitle"
            :message="modalMessage"
            modal-id="app-confirmation-modal"
            modal-class="primary"
            icon="download"
            :first-button-name="$t('export')"
            :second-button-name="$t('cancel')"
            @confirmed="exportFilteredAttendance()"
            @cancelled="exportConfirm = false"
            :self-close="false"
        />
    </div>
</template>

<script>
import MemoizeMixins from "../../../../../common/Helper/Support/MemoizeMixins";
import SelectAbleUserFilterMixin from "../../../Mixins/SelectAbleUserFilterMixin";
import {axiosGet, urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import {collection} from "../../../../../common/Helper/helpers";
import HotelSummary from "./HotelSummary.vue";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import HotelHouseKeepingMixins from "../../../Mixins/HotelHouseKeepingMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import AppAttendanceTopButtons from "../../Attendance/Component/AppAttendanceTopButtons.vue";
import {localTimeZone} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name      : "AppHotelReport",
    components: {AppAttendanceTopButtons, HotelSummary},
    mixins    : [
        MemoizeMixins,
        SelectAbleUserFilterMixin,
        HelperMixin,
        HotelHouseKeepingMixins
    ],
    props     : {
        hotel    : Object,
        room_type: Array,
    },
    data() {
        return {
            preloader    : false,
            summaries    : {},
            dataSet      : [],
            filterData   : {},
            exportConfirm: false,
            exportType   : null,
            modalMessage : '',
            modalSubtitle: '',
        }
    },
    mounted() {
        this.options.filters    = []
        this.options.actions    = []
        this.options.showAction = false
        this.options.showFilter = false
        this.options.showSearch = false
        this.options.columns    = this.options.columns.filter(e => e.type !== 'action')
    },
    watch   : {
        queryString: {
            handler  : function (queryString) {
                if (!queryString) {
                    return;
                }
                this.getHotelSummary(this.employee?.id);
            },
            immediate: true
        }
    },
    computed: {
        isPreviousNull() {
            return collection(this.getAllUsers).first().id === this.employee.id
        },
        getAllUsers() {
            return this.tableOptions.filters.find(data => data.key === 'user').option
        },
        isNextNull() {
            return collection(this.getAllUsers).last().id === this.employee.id
        },
        queryString() {
            return this.$store.getters.getFilterUrls;
        },
        profileUrl() {
            return urlGenerator(`${this.apiUrl.EMPLOYEES_PROFILE}/${this.employee.id}/profile`)
        },
        employee() {
            return this.summaries.employee || null
        },
    },
    methods : {
        getSelectableUsers() {
            axiosGet(`selectable/hotel/member?hotel_id=${this.hotel.id}`).then(response => {
                this.tableOptions.filters.find(fl => fl.key === 'user' && fl.type === 'drop-down-filter').option = response.data.map(member => member.user);
                this.tableOptions.filters.find(fl => fl.key === 'user' && fl.type === 'avatar-filter').option    = response.data.map(member => {
                    return {
                        ...member.user,
                        status: member.user.status.class
                    }
                });
            })
        },
        getHotelSummary(userId = false) {
            if (userId) {
                this.preloader   = true;
                this.options.url = `${TENANT_BASE_URL}app/hotel/house-keeping?${this.queryString}&maid_id=${userId}`

                axiosGet(`${this.apiUrl.ATTENDANCES}/${userId}/summaries?${this.queryString}`).then(response => {
                    this.summaries                         = response.data;
                    const {regular, early, late, on_leave} = this.summaries;
                    this.dataSet                           = [regular, early, late, on_leave];
                }).finally(() => {
                    axiosGet(`${TENANT_BASE_URL}/selectable/hotel/house-keeping?${this.queryString}&maid_id=${userId}&group=date`).then(response => {
                        this.summaries.total_work = response.data.length
                    }).finally(() => {
                        this.preloader = false;
                    })
                });
            } else {
                this.options.url = `${TENANT_BASE_URL}app/hotel/house-keeping?${this.queryString}`
                this.$hub.$emit(`reload-house-keeping-table`)
            }
        },
        changeUser(type) {
            const currentIndex = this.getAllUsers.findIndex(e => e.id === this.employee.id);
            const next         = this.getAllUsers[currentIndex + 1];
            const prev         = this.getAllUsers[currentIndex - 1];
            const user_id      = type === 'next' ? next.id : prev.id;

            this.setUserId({user: user_id});
        },
        setUserId(filter) {
            this.getHotelSummary(filter.user)
            this.setActiveUserToAvatarFilter(filter.user)
            this.setActiveUserToDropdownFilter(filter.user);
        },
        viewConfirmationModal(type = null) {
            this.exportType = type;

            if (type === 'allEmployee') {
                this.modalSubtitle = this.$t('all_employees_hotel_export_title');
                this.modalMessage  = this.$t('all_employees_hotel_export_message');
            }

            if (type === 'employee') {
                this.modalSubtitle = this.$t('hotel_export_title');
                this.modalMessage  = this.$t('hotel_export_message');

                if (!this.employee) {
                    return alert('Please select user.')
                }
            }

            if (type === 'type') {
                this.modalSubtitle = this.$t('hotel_type_export_title');
                this.modalMessage  = this.$t('hotel_type_export_message');
            }

            if (type === 'option') {
                this.modalSubtitle = this.$t('hotel_option_export_title');
                this.modalMessage  = this.$t('hotel_option_export_message');
            }

            if (type === 'members') {
                this.modalSubtitle = this.$t('hotel_members_export_title');
                this.modalMessage  = this.$t('hotel_members_export_message');
            }

            if (type === 'benefit') {
                this.modalSubtitle = this.$t('hotel_benefit_export_title');
                this.modalMessage  = this.$t('hotel_benefit_export_message');
            }

            this.exportConfirm = true;
        },
        exportFilteredAttendance() {
            let url = null;

            if (this.exportType === 'allEmployee') {
                url = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}/house-keeping?${this.queryString}&timeZone=${localTimeZone}`);
            }

            if (this.exportType === 'employee') {
                url = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}/house-keeping?${this.queryString}&timeZone=${localTimeZone}&user_id=${this.employee.id}`);
            }

            if (this.exportType === 'type') {
                url = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}/type?${this.queryString}&timeZone=${localTimeZone}`);
            }

            if (this.exportType === 'option') {
                url = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}/option?${this.queryString}&timeZone=${localTimeZone}`);
            }

            if (this.exportType === 'members') {
                url = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}/members?${this.queryString}&timeZone=${localTimeZone}`);
            }

            if (this.exportType === 'benefit') {
                url = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}/benefit?${this.queryString}&timeZone=${localTimeZone}`);
            }

            window.location = url;

            $("#app-confirmation-modal").modal('hide');
            $(".modal-backdrop").remove();

            this.exportConfirm = false;
            this.exportType    = null;
        }
    }
}
</script>