<template>
    <modal id="option-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('option')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/option`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('name')"
                        type="text"
                        :placeholder="$placeholder('name', '')"
                        v-model="formData.name"
                        :error-message="$errorMessage(errors, 'name')"
                        :required="true"
                    />
                </div>
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('selling_price')"
                        :placeholder="$placeholder('selling_price')"
                        v-model="formData.selling_price"
                        :required="true"
                        :error-message="$errorMessage(errors, 'selling_price')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('contract_price')"
                        :placeholder="$placeholder('contract_price')"
                        v-model="formData.contract_price"
                        :required="true"
                        :error-message="$errorMessage(errors, 'contract_price')">
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        type="textarea"
                        label-alignment="top"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :error-message="$errorMessage(errors, 'remark')"
                        :required="false">
                    </app-form-group>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "OptionCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#option-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'option-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

