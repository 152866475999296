<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('work_place')">
            <app-default-button
                :title="$fieldTitle('add', 'work_place', true)"
                v-if="$can('create_work_place')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="work-place-table"
            :options="options"
            @action="triggerActions"
        />

        <app-work-place-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('work-place-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import WorkPlaceMixins from "../../../Mixins/WorkPlaceMixins";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "AppWorkPlace",
    mixins: [HelperMixin, WorkPlaceMixins],
    data() {
        return {
            isModalActive: false,
            selectedUrl  : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/work-place/${row.id}`;
                this.isModalActive = true;
            } else if (action.title === this.$t('copy')) {
                navigator.clipboard.writeText(urlGenerator(`${TENANT_BASE_URL}interview-form/${row.id}`))
                this.$toastr.s(this.$t('Copy success!'))
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>