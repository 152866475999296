import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {DOCUMENT_FILE} from "../../Config/ApiUrl";
import {urlGenerator} from "../../../common/Helper/AxiosHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name          : this.$t('tenant_groups'),
                url           : DOCUMENT_FILE,
                showHeader    : true,
                showCount     : true,
                columns       : [
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('department'),
                        type     : 'custom-html',
                        key      : 'departments',
                        isVisible: true,
                        modifier : (departments) => {
                            let html = ''

                            for (const department of departments) {
                                html += '- ' + department.name + '<br>'
                            }

                            return html;
                        }
                    },
                    {
                        title    : this.$t('employee'),
                        type     : 'custom-html',
                        key      : 'users',
                        isVisible: true,
                        modifier : (users) => {
                            let html = ''

                            for (const user of users) {
                                html += '- ' + user.full_name + '<br>'
                            }

                            return html;
                        }
                    },
                    {
                        title    : this.$t('type'),
                        type     : 'custom-html',
                        key      : 'type',
                        isVisible: true,
                        modifier : (type) => {
                            return type.name || '-';
                        }
                    },
                    {
                        title    : this.$t('file'),
                        type     : 'custom-html',
                        key      : 'file',
                        isVisible: true,
                        modifier : value => `<a href="${urlGenerator(value)}" target="_blank"><i data-feather="external-link"/></a>`
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'custom-html',
                        key      : 'remark',
                        isVisible: true,
                        modifier : (remark) => {
                            return remark ? remark : '-';
                        }
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'invoice',
                        isVisible: true
                    },
                ],
                paginationType: "pagination",
                responsive    : true,
                rowLimit      : 10,
                showAction    : true,
                orderBy       : 'desc',
                actionType    : "default",
                actions       : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: () => this.$can('update_document_file')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : DOCUMENT_FILE,
                        name     : 'delete',
                        modifier : (row) => this.$can('delete_document_file') && !parseInt(row.is_default)
                    }
                ],
            },
        }
    }
}
