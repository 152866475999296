<template>
    <modal id="hotel-lost-found-modal"
           size="large"
           v-model="showModal"
           :title="$t('attachments')"
           :hideFooter="true"
           :loading="loading"
           :preloader="preloader">
        <div v-for="file_url in files" class="py-2">
            <img class="img-fluid" :src="urlGenerator(file_url)" alt="">
        </div>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name   : "HotelLostFoundGalleryModal",
    mixins : [FormHelperMixins, ModalMixin],
    props  : {
        files: {},
    },
    data() {
        return {
            TENANT_BASE_URL,
        }
    },
}
</script>

