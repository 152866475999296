<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('house_keeping') + (hotel.name ? ' - ' + hotel.name : '')">
            <app-default-button
                :title="$fieldTitle(!isFormActive ? 'add' : 'close', 'house_keeping', true)"
                v-if="$can('create_hotel_house_keeping')"
                @click="isFormActive ? isFormActive = false : isFormActive = true"
            />
        </app-page-top-section>

        <app-hotel-house-keeping-form
            v-if="isFormActive"
            :hotel="hotel"
        />

        <app-table
            id="house-keeping-table"
            :options="options"
            @action="triggerActions"
            @getFilteredValues="getFilterValues"
        />

        <app-hotel-house-keeping-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            :hotel="hotel"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('house-keeping-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import HotelHouseKeepingMixins from "../../../Mixins/HotelHouseKeepingMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "AppHotelHouseKeeping",
    mixins: [HelperMixin, HotelHouseKeepingMixins],
    props : {
        hotel: {},
    },
    data() {
        return {
            TENANT_BASE_URL,
            isModalTypeActive: false,
            isModalActive    : false,
            isFormActive     : false,
            selectedUrl      : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/hotel/house-keeping/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>