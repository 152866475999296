<template>
    <modal id="contract-modal"
           v-model="showModal"
           :title="$t('preview_contract')"
           @submit="submitData"
           :loading="loading"
           :hide-submit-button="true"
           :preloader="preloader"
            :cancel-btn-label="$t('close')">

        <div id="content" v-html="content"></div>

        <div id="agreement-items">
            <ol>
                <li v-for="item in agreementItems">{{ item.content }}</li>
            </ol>
        </div>

        <canvas v-if="contractData.signature" id="preview-signature"></canvas>
        <div v-else class="mt-3">({{ $t('sign_here') }})</div>

    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { TEMPLATE } from "../../../../Config/ApiUrl";
import SignaturePad from "signature_pad";
import moment from "moment";
import { axiosGet } from "../../../../../common/Helper/AxiosHelper";

export default {
        name: "ContractPreviewModal",
        mixins: [FormHelperMixins, ModalMixin],
        props: {
            contractData: {
                required: true,
                default: function () {
                    return {};
                }
            }
        },
        data() {
            return {
                content: '',
                agreementItems: [],
                signaturePad: null,
            }
        },
        methods: {
            initSignaturePad(signature) {
                const canvas = document.querySelector("#preview-signature");
                if (!canvas) {
                    return;
                }

                this.signaturePad = new SignaturePad(canvas, {});
                if (signature) {
                    this.signaturePad.fromDataURL(signature);
                }
            },
            getContent() {
                const replaceKeys = {
                    '{date}': 'date',
                    '{first_name}': 'first_name',
                    '{last_name}': 'last_name',
                    '{birthday}': 'birthday',
                    '{email}': 'email',
                    '{mobile}': 'mobile',
                    '{address}': 'address',
                };
                const templateId = this.contractData.template_id;

                this.contractData.birthday = this.contractData.birthday ? moment(this.contractData.birthday).format('YYYY-MM-DD') : null;
                this.contractData.date = this.contractData.date ? moment(this.contractData.date).format('YYYY-MM-DD') : null;

                this.loading = true;
                axiosGet(`${TEMPLATE}/${templateId}`).then(({data}) => {
                    let templateContent = data.content;
                    for (const key of Object.keys(replaceKeys)) {
                        const formProperty = replaceKeys[key];
                        const replaceValue = this.contractData[formProperty];

                        if (replaceValue) {
                            const regex = new RegExp(key, 'g');
                            templateContent = templateContent.replace(regex, replaceValue);
                        }
                    }

                    this.content = templateContent;
                    this.agreementItems = data.agreement_items || [];

                    this.initSignaturePad(this.contractData.signature);
                    this.loading = false;
                })
            },
        },
        mounted() {
            this.getContent();
        }
}
</script>

