export default {
    data(){
        return{
            listTabs: [
                {
                    'name'      : this.$t('template'),
                    'title'     : this.$t('template'),
                    'component' : 'app-detail-template-card-view',
                    'permission': true,
                    'props': {
                        template_id: this.templateId,
                    }
                },
                {
                    'name': this.$t('agreement-item'),
                    'title': this.$t('agreement-item'),
                    'component': 'app-agreement-item',
                    'permission': true,
                    'headerButton': {
                        'label': this.$t('add_new'),
                    },
                    'props': {
                        template_id: this.templateId,
                        is_in_tab: true,
                    }
                },
            ],
        }
    }
}