<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('card')">
            <app-default-button
                :btnClass="'btn btn-success mr-2'"
                :title="$fieldTitle('import', 'card', true)"
                v-if="$can('create_sale_card')"
                @click="openModalImport()"
            />
            <app-default-button
                :title="$fieldTitle('add', 'card', true)"
                v-if="$can('create_sale_card')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="sale-card-table"
            :options="options"
            @action="triggerActions"
        />

        <app-sale-visit-create
            v-if="isModalVisitActive"
            v-model="isModalVisitActive"
            :card-id="card_id"
            @close="isModalVisitActive = false"
        />

        <app-sale-card-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-sale-card-import
            v-if="isModalImport"
            v-model="isModalImport"
            @close="isModalImport = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('sale-card-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import SaleCardMixin from "../../../Mixins/SaleCardMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "SaleCard",
    mixins: [HelperMixin, SaleCardMixin, TENANT_BASE_URL],
    data() {
        return {
            isModalVisitActive: false,
            isModalImport     : false,
            isModalActive     : false,
            selectedUrl       : '',
            card_id           : null,
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/sale-card/${row.id}`;
                this.isModalActive = true;
            } else if (action.title === this.$t('add_visit')) {
                this.card_id            = row.id;
                this.isModalVisitActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
        openModalImport() {
            this.isModalImport = true;
        },
    }
}
</script>