<template>
    <modal id="manual-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('manual')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/manual`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>{{ $t('manual') }}</label>
                        <app-input
                            type="dropzone"
                            v-model="files"
                            :error-message="$errorMessage(errors, 'files.0')"
                        />
                    </div>
                </div>
                <div class="col-12">
                    <app-form-group
                        :label="$t('title')"
                        :placeholder="$placeholder('title')"
                        v-model="formData.title"
                        :required="true"
                        :error-message="$errorMessage(errors, 'title')"
                    />
                </div>
                <div class="col-12">
                    <app-form-group-selectable
                        :label="$fieldLabel('hotel', '')"
                        type="multi-select"
                        v-model="formData.hotel_ids"
                        list-value-field="name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'hotel_ids')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('publish_date')"
                        v-model="formData.publish_date"
                        :required="true"
                        :placeholder="formData.publish_date ? $placeholder('publish_date') : this.$t('not_added_yet')"
                        :error-message="$errorMessage(errors, 'publish_date')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('end_date')"
                        v-model="formData.end_date"
                        :required="true"
                        :placeholder="formData.end_date ? $placeholder('end_date') : this.$t('not_added_yet')"
                        :error-message="$errorMessage(errors, 'end_date')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('type_name')"
                        list-value-field="name"
                        v-model="formData.type_id"
                        :chooseLabel="$t('type_name')"
                        :error-message="$errorMessage(errors, 'type_id')"
                        :fetch-url="type_url"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('category')"
                        list-value-field="name"
                        v-model="formData.category_id"
                        :required="true"
                        :chooseLabel="$t('category')"
                        :error-message="$errorMessage(errors, 'category_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/category`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('level')"
                        list-value-field="name"
                        v-model="formData.level_id"
                        :chooseLabel="$t('level')"
                        :error-message="$errorMessage(errors, 'level_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/level`"
                    />
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="text-editor-for-remark">{{ $t('remark') }}</label>
                        <app-input
                            type="text-editor"
                            v-model="formData.remark"
                            id="text-editor-for-remark"
                            :placeholder="$textAreaPlaceHolder('remark')"
                            :error-message="$errorMessage(errors, 'remark')"
                            row="4"
                        />
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {formatDateForServer} from "../../../../../common/Helper/Support/DateTimeHelper";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "ManualCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            files   : [],
            formData: {},
            type_url: null,
            TENANT_BASE_URL,
        }
    },
    watch  : {
        'formData.hotel_ids': {
            handler  : function (value) {
                const hotel_id = value && typeof value !== "undefined" ? value : 0
                this.type_url  = `${TENANT_BASE_URL}selectable/hotel/type?hotel_id=${hotel_id}`
            },
            immediate: true
        },
    },
    methods: {
        submitData() {
            this.loading = true

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            this.files.forEach(file => formData.append('files[]', file))

            if (this.formData.publish_date) {
                formData.set('publish_date', formatDateForServer(this.formData.publish_date))
            }

            if (this.formData.end_date) {
                formData.set('end_date', formatDateForServer(this.formData.end_date))
            }

            if (this.formData.hotel_ids) {
                formData.set('hotel_ids', JSON.stringify(this.formData.hotel_ids))
            }

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#manual-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'hotel-manual-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

