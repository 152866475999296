<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('commute_details')">
            <app-default-button
                :title="$fieldTitle('add', 'commute_details', true)"
                v-if="$can('create_commute_details')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="commute-details-table"
            :options="options"
            @action="triggerActions"
        />

        <app-commute-details-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('commute-details-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import CommuteDetailsMixin from "../../Mixins/CommuteDetailsMixin";
import {TENANT_BASE_URL} from "../../../../common/Config/UrlHelper";

export default {
    name  : "CommuteDetails",
    mixins: [HelperMixin, CommuteDetailsMixin, TENANT_BASE_URL],
    data() {
        return {
            isModalTypeActive: false,
            isModalActive    : false,
            selectedUrl      : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/commute-details/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>