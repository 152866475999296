<template>
    <div>
        <app-page-top-section :title="back_url ? tab.hotel.name : $t('room')">
            <a v-if="back_url" :href="back_url" class="btn btn-success mr-2">
                <app-icon class="size-20 mr-2" name="arrow-left"/>
                {{ $t('back') }}
            </a>
            <app-default-button
                :title="$fieldTitle('add', 'room', true)"
                v-if="$can('create_hotel_room')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="room-table"
            :options="options"
            :card-view="true"
            @action="triggerActions"
        />

        <app-hotel-room-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="roomUrl"
            :hotel="tab.hotel"
            @close="isModalActive = false"
        />

        <app-hotel-room-export
            v-if="exportConfirmationModal"
            v-model="exportConfirmationModal"
            :hotel="tab.hotel"
            @close="exportConfirmationModal = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('room-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelRoomMixins from "../../../Mixins/HotelRoomMixins";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "HotelRoom",
    mixins: [FormHelperMixins, HotelRoomMixins],
    props : {
        back_url: {
            type   : String,
            default: ''
        },
        id      : {
            type: String
        }
    },
    mounted() {
        this.getRange();
    },
    data() {
        return {
            tab                    : this.$parent.$props.tabs.find(e => e.component === 'app-hotel-room'),
            isModalActive          : false,
            exportConfirmationModal: false,
            room                   : 0,
            roomUrl                : '',
            queryObject            : {}
        }
    },
    methods: {
        getRange() {
            const floorUrl = `${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${this.tab.hotel.id}&group=floor&format=floor`
            const Error    = error => this.$toastr.e(error.response.data.message)

            axiosGet(`${floorUrl}`).then(({data}) => this.options.filters[0].option = data).catch(Error)
        },
        triggerActions(row, action, active) {
            if (action.name === 'edit') {
                this.roomUrl       = `${TENANT_BASE_URL}app/hotel/room/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.roomUrl       = ''
        },
    },
}
</script>