import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import { TEMPLATE } from "../../Config/ApiUrl";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            options: {
                name: this.$t('tenant_groups'),
                url: TEMPLATE,
                showHeader: true,
                showCount: true,
                columns: [
                    {
                        title: this.$t('name'),
                        type: 'text',
                        key: 'name',
                        isVisible: true,
                    },
                    {
                        title: this.$t('number_type'),
                        type: 'custom-html',
                        key: 'number_type',
                        isVisible: true,
                        modifier: (number_type) => {
                            return number_type === 1 ? this.$t('Auto') : this.$t('manual');
                        }
                    },
                    {
                        title: this.$t('prefix'),
                        type: 'text',
                        key: 'prefix',
                        isVisible: true,
                    },
                    {
                        title: this.$t('remark'),
                        type: 'text',
                        key: 'remark',
                        isVisible: true,
                    },
                    {
                        title: this.$t('content'),
                        type: 'custom-html',
                        key: 'content',
                        isVisible: true,
                        modifier: (content) => {
                            const contentNoTag = content.replace(/<[^>]*>/g, '');
                            return contentNoTag.length > 100 ? contentNoTag.slice(0, 100).concat('...') : contentNoTag;
                        }
                    },
                    {
                        title: this.$t('actions'),
                        type: 'action',
                        key: 'invoice',
                        isVisible: true
                    },
                ],
                paginationType: "pagination",
                responsive: true,
                rowLimit: 10,
                showAction: true,
                orderBy: 'desc',
                actionType: "default",
                actions: [
                    {
                        title: this.$t('copy'),
                        icon: 'copy',
                        name: 'copy'
                    },
                    {
                        title: this.$t('edit'),
                        icon: 'edit',
                        type: 'modal',
                        modifier: () => this.$can('update_template'),
                    },
                    {
                        title: this.$t('setting'),
                        icon: 'settings',
                        name: 'setting',
                        modifier: () => this.$can('update_template'),
                    },
                    {
                        title: this.$t('delete'),
                        icon: 'trash-2',
                        type: 'modal',
                        component: 'app-confirmation-modal',
                        modalId: 'app-confirmation-modal',
                        url: TEMPLATE,
                        name: 'delete',
                        modifier:( row) => this.$can('delete_template') && !parseInt(row.is_default)
                    }
                ],
            },
        }
    }
}
