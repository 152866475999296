<template>
    <div>
        <app-page-top-section :title="$t('type')">
            <app-default-button
                :title="$fieldTitle('add', 'type', true)"
                v-if="$can('create_hotel_type')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="type-table"
            :options="options"
            @action="triggerActions"
        />

        <app-hotel-type-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="typeUrl"
            :hotel="tab.hotel"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('type-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelTypeMixins from "../../../Mixins/HotelTypeMixins";

export default {
    name  : "HotelRoomType",
    mixins: [FormHelperMixins, HotelTypeMixins],
    props : {
        id: {
            type: String
        }
    },
    data() {
        return {
            tab          : this.$parent.$props.tabs.find(e => e.component === 'app-hotel-type'),
            isModalActive: false,
            typeUrl      : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.typeUrl       = `${TENANT_BASE_URL}app/hotel/type/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.typeUrl       = ''
        },
    },
}
</script>