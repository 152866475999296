<template>
    <modal id="ticket-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('ticket')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/ticket/list`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group-selectable
                        list-value-field="name"
                        type="select"
                        :label="$t('status')"
                        v-model="formData.status"
                        :chooseLabel="$t('status')"
                        :error-message="$errorMessage(errors, 'status')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/ticket/status`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        list-value-field="name"
                        type="select"
                        :label="$t('priority')"
                        v-model="formData.priority"
                        :chooseLabel="$t('priority')"
                        :error-message="$errorMessage(errors, 'priority')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/ticket/priority`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        list-value-field="name"
                        type="select"
                        :label="$t('category')"
                        v-model="formData.category_id"
                        :chooseLabel="$t('category')"
                        :error-message="$errorMessage(errors, 'category_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/ticket/category`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="text"
                        v-model="formData.subject"
                        :label="$t('subject')"
                        :placeholder="$placeholder('subject', '')"
                        :error-message="$errorMessage(errors, 'subject')"
                    />
                </div>
                <div class="col-md-12">
                    <app-form-group-selectable
                        type="multi-select"
                        :label="$t('assigned_to')"
                        list-value-field="full_name"
                        :chooseLabel="$t('assigned_to')"
                        v-model="formData.assigned_to"
                        :error-message="$errorMessage(errors, 'assigned_to')"
                        :fetch-url="`${apiUrl.TENANT_SELECTABLE_DEPARTMENT_USERS}?without=admin&member=only&with_auth=yes`"
                    />
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('description') }}</label>
                        <app-input
                            row="3"
                            type="text-editor"
                            id="text-editor-for-template"
                            v-model="formData.description"
                            :placeholder="$textAreaPlaceHolder('description')"
                            :error-message="$errorMessage(errors, 'description')"
                        />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('attachments') }}</label>
                        <app-input
                            type="dropzone"
                            v-model="files"
                            :error-message="$errorMessage(errors, 'files.0')"
                        />
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../common/Config/UrlHelper";

export default {
    name  : "TicketCreateModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            TENANT_BASE_URL,
            files   : [],
            formData: {},
        }
    },
    methods: {
        submitData() {
            this.loading = true

            let url      = this.$refs.form.dataset['url'];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.formData.assigned_to) {
                formData.set('assigned_to', JSON.stringify(this.formData.assigned_to))
            }

            this.files.forEach(file => formData.append('files[]', file))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#ticket-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'ticket-table');
            this.$hub.$emit('reload-data-ticket', data.ticket);
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

