<template>
    <div>
        <form
            ref="form"
            :data-url='`${TENANT_BASE_URL}/interview-form/${id}`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('name_romaji')"
                        type="text"
                        :placeholder="$placeholder('name_romaji', '')"
                        v-model="formData.name_romaji"
                        :error-message="$errorMessage(errors, 'name_romaji')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('name_katakana')"
                        type="text"
                        :placeholder="$placeholder('name_katakana', '')"
                        v-model="formData.name_katakana"
                        :error-message="$errorMessage(errors, 'name_katakana')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('email')"
                        type="email"
                        :placeholder="$placeholder('email', '')"
                        v-model="formData.email"
                        :error-message="$errorMessage(errors, 'email')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$fieldTitle('contact', 'number')"
                        type="tel-input"
                        id="input-text-contact"
                        :placeholder="formData.phone ? $placeholder('contact', 'number') : this.$t('not_added_yet')"
                        v-model="formData.phone"
                        :error-message="$errorMessage(errors, 'phone')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('gender')"
                        type="radio"
                        :list="[
                                {id:'male',value: $t('male')},
                                {id:'female', value:  $t('female')},
                                {id:'other', value:  $t('other')}
                            ]"
                        v-model="formData.gender"
                        :error-message="$errorMessage(errors, 'gender')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('residence')"
                        list-value-field="name"
                        v-model="formData.residence"
                        :required="false"
                        :chooseLabel="$t('residence')"
                        :error-message="$errorMessage(errors, 'residence')"
                        :fetch-url="`${TENANT_BASE_URL}api/candidate/residence`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('clothing_size')"
                        list-value-field="name"
                        v-model="formData.clothing_size"
                        :required="false"
                        :chooseLabel="$t('clothing_size')"
                        :error-message="$errorMessage(errors, 'clothing_size')"
                        :fetch-url="`${TENANT_BASE_URL}api/candidate/size`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="select"
                        :label="$t('experience')"
                        list-value-field="name"
                        v-model="formData.experience"
                        :required="false"
                        :chooseLabel="$t('experience')"
                        :error-message="$errorMessage(errors, 'experience')"
                        radio-checkbox-name="0"
                        :list="[
                                { id: 0, name: $t('No') },
                                { id: 1, name: $t('Yes') },
                            ]"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="select"
                        :label="$t('years_in_japan')"
                        list-value-field="name"
                        v-model="formData.in_japan"
                        :required="false"
                        :chooseLabel="$t('years_in_japan')"
                        :error-message="$errorMessage(errors, 'years_in_japan')"
                        :list="[
                                { id: 1, name: 1 },
                                { id: 2, name: 2 },
                                { id: 3, name: 3 },
                                { id: 4, name: 4 },
                                { id: 5, name: 5 },
                                { id: 6, name: 6 },
                                { id: 7, name: 7 },
                                { id: 8, name: 8 },
                                { id: 9, name: 9 },
                                { id: 10, name: 10 },
                            ]"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('start_date')"
                        v-model="formData.start"
                        :placeholder="formData.start ? $placeholder('start_date') : this.$t('not_added_yet')"
                        :error-message="$errorMessage(errors, 'start')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('station')"
                        type="text"
                        :placeholder="$placeholder('station', '')"
                        v-model="formData.station"
                        :error-message="$errorMessage(errors, 'station')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('commuting_time')"
                        type="number"
                        :placeholder="$placeholder('commuting_time', '')"
                        v-model="formData.how_long"
                        :error-message="$errorMessage(errors, 'how_long')"
                        :required="false"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('job_broker')"
                        type="text"
                        :placeholder="$placeholder('job_broker', '')"
                        v-model="formData.job_broker"
                        :error-message="$errorMessage(errors, 'job_broker')"
                    />
                </div>
                <div class="col-md-6">
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('monday')"
                        list-value-field="name"
                        v-model="formData.monday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'monday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('tuesday')"
                        list-value-field="name"
                        v-model="formData.tuesday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'tuesday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('wednesday')"
                        list-value-field="name"
                        v-model="formData.wednesday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'wednesday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('thursday')"
                        list-value-field="name"
                        v-model="formData.thursday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'thursday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('friday')"
                        list-value-field="name"
                        v-model="formData.friday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'friday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('saturday')"
                        list-value-field="name"
                        v-model="formData.saturday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'saturday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-4">
                    <app-form-group
                        type="select"
                        :label="$t('sunday')"
                        list-value-field="name"
                        v-model="formData.sunday"
                        :required="false"
                        :chooseLabel="$t('time')"
                        :error-message="$errorMessage(errors, 'sunday')"
                        :list="timeList"
                    />
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('attachments') }}</label>
                        <app-input
                            type="dropzone"
                            v-model="files"
                            :error-message="$errorMessage(errors, 'files.0')"
                        />
                    </div>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        type="textarea"
                        label-alignment="top"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :error-message="$errorMessage(errors, 'remark')"
                        :required="false">
                    </app-form-group>
                </div>
                <div class="col-md-12 text-center">
                    <app-submit-button
                        btn-class="mt-3 btn-primary"
                        :label="$t('submit')"
                        :loading="preloader"
                        @click="submitData()"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {formatDateForServer} from "../../../../../common/Helper/Support/DateTimeHelper";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "CandidateForm",
    mixins: [FormHelperMixins],
    props : {
        id: {
            type: String
        }
    },
    data() {
        return {
            TENANT_BASE_URL,
            files   : [],
            timeList: [],
            formData: {},
        }
    },
    mounted() {
        axiosGet(`${TENANT_BASE_URL}api/candidate/time`).then(({data}) => {
            this.timeList = data
        }).catch((error) => {
            this.$toastr.e(error.response.data.message)
        }).finally(() => {
            this.errors = {};
        })
    },
    methods: {
        submitData() {
            this.preloader      = true;
            this.formData.start = this.formData.start ? formatDateForServer(this.formData.start) : '';

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            this.files.forEach(file => formData.append('files[]', file))

            console.log(url)

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.preloader = false;
            this.formData  = {};
            this.$emit('input', false);
            this.$toastr.s(this.$t('Candidate created successfully!'))
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
        afterFinalResponse() {
            this.preloader = false;
        },
    },
}
</script>

