import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal, onlyTime} from "../../../common/Helper/Support/DateTimeHelper";
import {HotelFilterMixin} from "./FilterMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
    data() {
        return {
            options: {
                name             : this.$t('shortcoming'),
                url              : `${TENANT_BASE_URL}app/hotel/shortcoming`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('hotel'),
                        type     : 'custom-html',
                        key      : 'hotels',
                        isVisible: true,
                        modifier : (data, row) => {
                            const html = []

                            for (const hotel of data) {
                                html.push(hotel.name)
                            }

                            return html.join('<br>');
                        }
                    },
                    {
                        title    : this.$t('title'),
                        type     : 'text',
                        key      : 'title',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('penalty_points'),
                        type     : 'text',
                        key      : 'penalty_points',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('description'),
                        type     : 'text',
                        key      : 'description',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'text',
                        key      : 'remark',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('created'),
                        type     : 'custom-html',
                        key      : 'created_at',
                        isVisible: true,
                        modifier : date => formatDateToLocal(date)
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('hotel'),
                        type          : "multi-select-filter",
                        key           : "hotels",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_list')
                    },
                    {
                        title : this.$t('date'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_shortcoming')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/shortcoming/`,
                        name     : 'delete',
                        modifier : row => this.$can('update_hotel_shortcoming')
                    }
                ],
            }
        }
    },
}