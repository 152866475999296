import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('ticket'),
                url              : `${TENANT_BASE_URL}app/ticket/list`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('TicketID'),
                        type     : 'custom-html',
                        key      : 'ticket_no',
                        isVisible: true,
                        modifier : (data) => {
                            return '#' + data;
                        }
                    },
                    {
                        title    : this.$t('subject'),
                        type     : 'custom-html',
                        key      : 'subject',
                        isVisible: true,
                        modifier : (data, row) => {
                            return data + ' (' + row.comments_count + ')';
                        }
                    },
                    {
                        title    : this.$t('created_by'),
                        type     : 'custom-html',
                        key      : 'created_by',
                        isVisible: true,
                        modifier : (data) => {
                            return data.full_name;
                        }
                    },
                    {
                        title    : this.$t('assigned_to'),
                        type     : 'custom-html',
                        key      : 'assigned_names',
                        isVisible: true,
                        modifier : (data, row) => {
                            if (data.length > 0){
                                return data.join('<br>');
                            }
                            return '-';
                        }
                    },
                    {
                        title    : this.$t('category'),
                        type     : 'text',
                        key      : 'category_name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('status'),
                        type     : 'custom-html',
                        key      : 'status_name',
                        isVisible: true,
                        modifier : (data, row) => {
                            if (row.status == 'closed') {
                                return '<div class="badge badge-pill badge-secondary">' + data + '</div>';
                            }
                            if (row.status == 'resolved') {
                                return '<div class="badge badge-pill badge-success">' + data + '</div>';
                            }
                            if (row.status == 'in_progress') {
                                return '<div class="badge badge-pill badge-warning">' + data + '</div>';
                            }
                            return '<div class="badge badge-pill badge-primary">' + data + '</div>';
                        }
                    },
                    {
                        title    : this.$t('priority'),
                        type     : 'custom-html',
                        key      : 'priority_name',
                        isVisible: true,
                        modifier : (data, row) => {
                            if (row.priority == 'urgent') {
                                return '<div class="badge badge-pill badge-danger">' + data + '</div>';
                            }
                            if (row.priority == 'high') {
                                return '<div class="badge badge-pill badge-warning">' + data + '</div>';
                            }
                            if (row.priority == 'medium') {
                                return '<div class="badge badge-pill badge-primary">' + data + '</div>';
                            }
                            return '<div class="badge badge-pill badge-info">' + data + '</div>';
                        }
                    },
                    {
                        title    : this.$t('created'),
                        type     : 'text',
                        key      : 'created_ago',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title : this.$t('created'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                    {
                        title         : this.$t('category'),
                        type          : "multi-select-filter",
                        key           : "category_id",
                        option        : [],
                        listValueField: 'name',
                    },
                    {
                        title         : this.$t('status'),
                        type          : "multi-select-filter",
                        key           : "status",
                        option        : [],
                        listValueField: 'name',
                    },
                    {
                        title         : this.$t('priority'),
                        type          : "multi-select-filter",
                        key           : "priority",
                        option        : [],
                        listValueField: 'name',
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('detail'),
                        icon    : 'eye',
                        type    : 'modal',
                        modifier: row => this.$can('update_ticket_list')
                    },
                    {
                        title   : this.$t('history'),
                        icon    : 'list',
                        type    : 'modal',
                        modifier: row => this.$can('view_ticket_list')
                    },
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_ticket_list')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/ticket/list`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_ticket_list')
                    }
                ],
            }
        }
    },
    mounted() {
        const categoryFilter = this.options.filters.find(item => item.key === 'category_id');
        const statusFilter   = this.options.filters.find(item => item.key === 'status');
        const priorityFilter = this.options.filters.find(item => item.key === 'priority');

        axiosGet(`${TENANT_BASE_URL}selectable/ticket/category`)
        .then(({data}) => categoryFilter.option = data)
        .catch(error => this.$toastr.e(error.response.data.message))

        axiosGet(`${TENANT_BASE_URL}selectable/ticket/status`)
        .then(({data}) => statusFilter.option = data)
        .catch(error => this.$toastr.e(error.response.data.message))

        axiosGet(`${TENANT_BASE_URL}selectable/ticket/priority`)
        .then(({data}) => priorityFilter.option = data)
        .catch(error => this.$toastr.e(error.response.data.message))
    },
}