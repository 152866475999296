<template>
    <div class="card card-with-shadow border-0 h-100 employee-preview-card">
        <div class="card-body position-relative d-flex flex-column justify-content-between">
            <div v-if="showAction" class="dropdown options-dropdown position-absolute">
                <button type="button"
                        class="btn-option btn d-flex align-items-center justify-content-center"
                        data-toggle="dropdown">
                    <app-icon name="more-horizontal"/>
                </button>
                <div class="dropdown-menu dropdown-menu-right py-2 mt-1">
                    <a class="dropdown-item px-4 py-2" href="#" v-for="(action,index) in actions" :key="index"
                       v-if="checkIfVisible(action, room)" @click.prevent="callAction(action)">
                        {{ action.title }}
                    </a>
                </div>
            </div>
            <div class="text-center">
                <div class="avatars-w-50 mb-2 mx-auto d-inline-block">
                    <div class="no-img rounded-circle">{{ room.number }}</div>
                </div>

                <p class="mb-2">{{ room.hotel.name }}</p>

                <p class="mb-2 font-size-80 text-muted">{{ room.floor + ' ' + $t('floor') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import CoreLibrary from "../../../../../core/helpers/CoreLibrary";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";

export default {
    name   : "RoomPreviewCard",
    extends: CoreLibrary,
    mixins : [FormHelperMixins],
    props  : {
        room      : {
            type    : Object,
            required: true
        },
        actions   : {
            type   : Array,
            require: true
        },
        showAction: {
            type   : Boolean,
            default: true
        },
        id        : {
            type   : String,
            require: true
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        callAction(action) {
            this.$emit('action-' + this.id, this.room, action, true)
        },
        checkIfVisible(action, room) {
            if (this.isFunction(action.modifier)) {
                return action.modifier(room);
            }
            return true;
        },
    },
}
</script>