import { render, staticRenderFns } from "./PayrunAndBadge.vue?vue&type=template&id=6923811c&scoped=true"
import script from "./PayrunAndBadge.vue?vue&type=script&lang=js"
export * from "./PayrunAndBadge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6923811c",
  null
  
)

export default component.exports