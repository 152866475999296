<template>
    <modal id="hotel-shortcoming-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('shortcoming')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/shortcoming`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        v-model="formData.title"
                        :label="$t('title')"
                        :error-message="$errorMessage(errors, 'title')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        v-model="formData.penalty_points"
                        :label="$t('penalty_points')"
                        :error-message="$errorMessage(errors, 'penalty_points')"
                    />
                </div>
            </div>
            <app-form-group-selectable
                type="multi-select"
                :label="$t('hotel')"
                list-value-field="name"
                v-model="formData.hotel_ids"
                :required="true"
                :chooseLabel="$t('hotel')"
                :error-message="$errorMessage(errors, 'hotel_ids')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
            />
            <app-form-group
                type="textarea"
                v-model="formData.description"
                :label="$t('description')"
                :placeholder="$textAreaPlaceHolder('description')"
                :required="false"
                :error-message="$errorMessage(errors, 'description')">
            </app-form-group>
            <app-form-group
                type="textarea"
                v-model="formData.remark"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                :required="false"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "HotelShortcomingCreateModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            TENANT_BASE_URL,
            formData: {},
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            if (this.formData.hotel_ids) {
                formData.set('hotel_ids', JSON.stringify(this.formData.hotel_ids))
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#hotel-shortcoming-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'hotel-shortcoming-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>