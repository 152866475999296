<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('ticket')">
            <app-default-button
                :title="$fieldTitle('add', 'ticket', true)"
                v-if="$can('create_ticket')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="ticket-table"
            :options="options"
            @action="triggerActions"
        />

        <app-ticket-create
            v-if="isModalActive"
            v-model="isModalActive"
            @close="isModalActive = false"
            :selected-url="selectedUrl"
        />

        <app-ticket-history
            v-if="isModalHistoryActive"
            v-model="isModalHistoryActive"
            @close="isModalHistoryActive = false"
            :ticket-id="ticketId"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('ticket-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import {TENANT_BASE_URL} from "../../../../common/Config/UrlHelper";
import TicketMixins from "../../Mixins/TicketMixins";
import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";

export default {
    name  : "AppTicketList",
    mixins: [HelperMixin, TicketMixins],
    data() {
        return {
            TENANT_BASE_URL,
            isModalActive       : false,
            isModalHistoryActive: false,
            selectedUrl         : '',
            ticketId            : null,
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('detail')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}ticket/list/${row.id}/edit`))
            } else if (action.title === this.$t('edit')) {
                this.isModalActive = true;
                this.selectedUrl   = `${TENANT_BASE_URL}app/ticket/list/${row.id}`;
            } else if (action.title === this.$t('history')) {
                this.isModalHistoryActive = true;
                this.ticketId             = row.id;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>