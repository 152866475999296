import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import {urlGenerator} from "../../../common/Helper/AxiosHelper";

export default {
    mixins: [HelperMixin, DatatableHelperMixin],
    data() {
        return {
            options: {
                name             : this.$t('card'),
                url              : `${TENANT_BASE_URL}app/sale-card`,
                showHeader       : true,
                showCount        : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('position'),
                        type     : 'text',
                        key      : 'position',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('company_name'),
                        type     : 'text',
                        key      : 'company_name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('phone'),
                        type     : 'text',
                        key      : 'phone',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('tel'),
                        type     : 'text',
                        key      : 'tel',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('email'),
                        type     : 'text',
                        key      : 'email',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('fax'),
                        type     : 'text',
                        key      : 'fax',
                        isVisible: true,
                    },
                    {
                        title        : this.$t('history'),
                        type         : 'component',
                        key          : 'visits',
                        isVisible    : true,
                        componentName: 'app-sale-card-history',
                    },
                    {
                        title    : this.$t('document'),
                        type     : 'custom-html',
                        key      : 'files',
                        isVisible: true,
                        modifier : (files) => {
                            let html = '';

                            if (files && files.length > 0) {
                                for (const file_url of files) {
                                    html += `<a href="${urlGenerator(file_url)}" class="d-block">${this.$t('download')}</a>`
                                }
                            }

                            return html
                        }
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('add_visit'),
                        icon    : 'plus',
                        type    : 'modal',
                        modifier: row => this.$can('create_sale_visit')
                    },
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_sale_card')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/sale-card/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_sale_card')
                    }
                ],
            }
        }
    },
}