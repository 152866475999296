<template>
    <modal id="room-modal"
           size="large"
           v-model="showModal"
           :btn-label="selectedUrl ? $t('save') : $t('add')"
           :title="generateModalTitle('room')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/room`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div v-if="selectedUrl === ''" class="col-md-12">
                    <app-form-group
                        :label="$t('prefix')"
                        :placeholder="$placeholder('prefix')"
                        v-model="formData.prefix"
                        :error-message="$errorMessage(errors, 'prefix')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('floor')"
                        :placeholder="$placeholder('floor')"
                        v-model="formData.floor"
                        :required="true"
                        :error-message="$errorMessage(errors, 'floor')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('type_name')"
                        list-value-field="name"
                        v-model="formData.type_id"
                        :required="true"
                        :chooseLabel="$t('type_name')"
                        :error-message="$errorMessage(errors, 'type_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/type?hotel_id=${hotel.id}`"
                    />
                </div>
                <div v-if="selectedUrl" class="col-md-12">
                    <app-form-group
                        :label="$t('room_number')"
                        :placeholder="$placeholder('number')"
                        v-model="formData.number"
                        :required="true"
                        :error-message="$errorMessage(errors, 'number')">
                    </app-form-group>
                </div>
                <div v-if="selectedUrl === ''" class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('from')"
                        :placeholder="$placeholder('from')"
                        v-model="formData.from"
                        :required="true"
                        :error-message="$errorMessage(errors, 'from')">
                    </app-form-group>
                </div>
                <div v-if="selectedUrl === ''" class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('end')"
                        :placeholder="$placeholder('end')"
                        v-model="formData.end"
                        :required="true"
                        :error-message="$errorMessage(errors, 'end')">
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        type="textarea"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :required="false"
                        :error-message="$errorMessage(errors, 'remark')">
                    </app-form-group>
                </div>
            </div>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "RoomCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type   : Object,
            default: {},
        },
    },
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true;
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#room-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'room-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.loading   = false;
            this.formData  = response.data;
        },
    },
}
</script>

