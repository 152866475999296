<template>
    <div class="content-wrapper">
        <app-tab :tabs="tabs" :icon="tabIcon"/>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "AppHotelListEdit",
    data() {
        return {
            tabIcon: 'settings',
            tabs   : [
                {
                    'name'      : this.$t('claim_type'),
                    'title'     : this.$t('claim_type'),
                    'component' : 'app-hotel-claim-type',
                    'permission': 'hotel_module_settings',
                },
            ]
        }
    },
    computed: {
        ...mapState({
            loader: state => state.loading,
        }),
    },
}
</script>