<template>
    <modal
        id="employee-password-modal"
        v-model="showModal"
        :title="$t('reset_password')"
        @submit="submit"
        :loading="loading"
        :btn-label="$t('save')"
        :preloader="preloader">

        <form ref="form" :data-url="EMPLOYEES + '/' + id + '/reset-password'" @submit.prevent="submitData">
            <app-form-group
                :label="$t('password')"
                type="password"
                id="input-text-password"
                :placeholder="$t('password')"
                v-model="formData.password"
                :error-message="$errorMessage(errors, 'password')"
                :show-password="true"
                :required="true"
            />
            <app-form-group
                :label="$fieldTitle('confirm', 'password')"
                type="password"
                id="input-text-confirm-password"
                :placeholder="$placeholder('confirm', 'password')"
                v-model="formData.password_confirmation"
                :show-password="true"
                :required="true"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";
import {EMPLOYEES} from "../../../Config/ApiUrl";

export default {
    name  : "EmployeePasswordModel",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        id: null
    },
    data() {
        return {
            formData: {},
            EMPLOYEES,
        }
    },
    methods: {
        submit() {
            this.loading = true;
            this.message = '';
            this.errors  = {};
            this.submitData(this.formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#employee-password-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, this.from === 'employee' ? 'employee-table' : 'user-table');
        },
        afterSuccessFromGetEditData({data}) {
            this.preloader = false;
        },
    },
}
</script>