<template>
    <div>
        <app-default-button :title="$t('history') + ` (${count})`" @click="showModal = true"/>
        <modal size="large" v-model="showModal" :title="$t('history')" :hideFooter="true">
            <app-table id="sale-visit-table" :options="options"/>
        </modal>
    </div>
</template>

<script>
import SaleVisitMixin from "../../../Mixins/SaleVisitMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formatDateToLocal} from "../../../../../common/Helper/Support/DateTimeHelper";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "CardHistory",
    mixins: [SaleVisitMixin],
    props : {
        value  : null,
        tableId: null,
        rowData: {},
    },
    data() {
        return {
            showModal: false,
            count    : this.value.length,
            options  : {
                name             : this.$t('visit'),
                url              : `${TENANT_BASE_URL}app/sale-visit?card_id=${this.rowData.id}`,
                showHeader       : false,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('card'),
                        type     : 'custom-html',
                        key      : 'card',
                        isVisible: true,
                        modifier : (value) => value.name || ''
                    },
                    {
                        title    : this.$t('company_name'),
                        type     : 'custom-html',
                        key      : 'card',
                        isVisible: true,
                        modifier : (value) => value.company_name || ''
                    },
                    {
                        title    : this.$t('date'),
                        type     : 'custom-html',
                        key      : 'date',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title    : this.$t('image'),
                        type     : 'custom-html',
                        key      : 'image',
                        isVisible: true,
                        modifier : (image) => {
                            if (image) {
                                return `<a href="${urlGenerator(image)}" target="_blank" class="d-block">${this.$t('view')}</a>`
                            }
                            return '--'
                        }
                    },
                ],
                filters          : [],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : false,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [],
            }
        }
    },
}
</script>