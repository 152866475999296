<template>
    <div>
        <app-page-top-section :title="$t('status')">
            <app-default-button
                :title="$fieldTitle('bulk', 'update', true)"
                btnClass="btn btn-warning mr-2"
                v-if="$can('create_hotel_status')"
                @click="openBuckModal()"
            />
            <app-default-button
                :title="$fieldTitle('add', 'status', true)"
                v-if="$can('create_hotel_status')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="status-table"
            :options="options"
            :card-view="true"
            @action="triggerActions"
            @getFilteredValues="getFilterValues"
        />

        <app-hotel-status-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="statusUrl"
            :hotel_id="hotel.id"
            :room_id="room_id"
            @close="isModalActive = false"
        />

        <app-hotel-status-bulk-create
            v-if="isBuckModalActive"
            v-model="isBuckModalActive"
            :selected-url="statusUrl"
            :hotel_id="hotel.id"
            :rooms="checked"
            @close="isBuckModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('status-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelStatusMixins from "../../../Mixins/HotelStatusMixins";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "HotelStatus",
    mixins: [FormHelperMixins, HotelStatusMixins],
    props : {
        hotel: null,
    },
    data() {
        return {
            isModalActive    : false,
            isBuckModalActive: false,
            room_id          : null,
            statusUrl        : '',
            checked          : [],
        }
    },
    mounted() {
        this.$hub.$on('checked-values-rooms', (checked) => {
            this.checked = checked
        })
        axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${this.hotel.id}&group=floor&format=floor`)
            .then(({data}) => this.options.filters[1].option = data)
            .catch(error => this.$toastr.e(error.response.data.message))
        axiosGet(`${TENANT_BASE_URL}selectable/hotel/cleaning`)
            .then(({data}) => this.options.filters[3].option = data)
            .catch(error => this.$toastr.e(error.response.data.message))
        axiosGet(`${TENANT_BASE_URL}selectable/hotel/staying`)
            .then(({data}) => this.options.filters[4].option = data)
            .catch(error => this.$toastr.e(error.response.data.message))
        axiosGet(`${TENANT_BASE_URL}selectable/hotel/house-keeping-status`)
            .then(({data}) => this.options.filters[5].option = data)
            .catch(error => this.$toastr.e(error.response.data.message))
    },
    methods: {
        triggerActions(row, action, active) {
            this.room_id = null

            if (action.title === this.$t('edit')) {
                this.statusUrl     = `${TENANT_BASE_URL}app/hotel/status/${row.status.id}`;
                this.isModalActive = true;
            } else if (action.title === this.$t('create')) {
                this.room_id = row.id
                this.openModal()
            } else {
                this.getAction(row.status, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.statusUrl     = ''
        },
        openBuckModal() {
            this.isBuckModalActive = true;
            this.statusUrl         = ''
        },
        getRoom(floor) {
            axiosGet(`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${this.hotel.id}&floor=${floor}&format=number`)
                .then(({data}) => this.options.filters[2].option = data)
                .catch(error => this.$toastr.e(error.response.data.message))
        },
        getFilterValues(filters) {
            if (filters.floor) {
                this.getRoom(filters.floor)
            }
        },
    },
}
</script>