<template>
    <div class="date-value-wrapper">
        <div class="date">
            01
        </div>
        <div class="date-hour-wrapper">
            <div class="date-hour">04:22</div>
            <div class="date-hour">04:22</div>
            <div class="date-hour">04:22</div>
            <div class="date-hour">04:22</div>
        </div>
        <div class="date-hour-wrapper">
            <div class="date-hour">04:22</div>
            <div class="date-hour">04:22</div>
            <div class="date-hour">04:22</div>
            <div class="date-hour">04:22</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppEmployeeWorkingDate"
}
</script>