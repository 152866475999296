<template>
    <div class="card-wrapper text-center">
        <div class="card border-0">
            <div class="icon-wrapper">
                <i class="checkmark">✓</i>
            </div>
            <h1>Success</h1>
            <p>Your contract is created !<br/> We'll be in touch shortly!</p>
        </div>
        <div class="action-btn-group">
            <button class="export-pdf btn btn-primary" @click="exportPdf">{{ $t('export_pdf') }}</button>
<!--            <button class="send_email btn btn-primary ml-2">{{ $t('send_email') }}</button>-->
        </div>
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";

export default {
    name: "CompleteContract",
    mixins: [FormHelperMixins, ModalMixin],
    props: {
        pdfUrl: {
            required: true,
        },
    },
    data() {
        return {
            //
        }
    },
    methods: {
        exportPdf() {
            window.location.href = `${this.pdfUrl}`;
        }
    },
    mounted() {
        //
    }
}
</script>

<style scoped>
    body {
        text-align: center;
        padding: 40px 0;
        background: #EBF0F5;
    }

    h1 {
        color: #88B04B;
        font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
        font-weight: 900;
        font-size: 40px;
        margin-bottom: 10px;
    }

    p {
        color: #404F5E;
        font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
        font-size: 20px;
        margin: 0;
    }

    i {
        color: #9ABC66;
        font-size: 100px;
        line-height: 200px;
        margin-left: -15px;
    }

    .card {
        background: white;
        padding: 80px 60px 40px;
        //border-radius: 4px;
        //box-shadow: 0 2px 3px #C8D0D8;
        display: inline-block;
        margin: 0 auto;
    }

    .icon-wrapper {
        border-radius: 200px;
        height: 200px;
        width: 200px;
        background: #F8FAF5;
        margin: 0 auto;
    }
</style>