import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('candidate'),
                url              : `${TENANT_BASE_URL}app/candidate`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('work_place'),
                        type     : 'text',
                        key      : 'work_place',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('name_romaji'),
                        type     : 'text',
                        key      : 'name_romaji',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('name_katakana'),
                        type     : 'text',
                        key      : 'name_katakana',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('email'),
                        type     : 'text',
                        key      : 'email',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('phone'),
                        type     : 'text',
                        key      : 'phone',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('residence'),
                        type     : 'text',
                        key      : 'residence_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('clothing_size'),
                        type     : 'text',
                        key      : 'clothing_size_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('years_in_japan'),
                        type     : 'text',
                        key      : 'in_japan',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('experience'),
                        type     : 'custom-html',
                        key      : 'experience',
                        isVisible: true,
                        modifier : (value) => {
                            return value === 1 ? this.$t('Yes') : this.$t('No');
                        }
                    },
                    {
                        title    : this.$t('start_date'),
                        type     : 'custom-html',
                        key      : 'start',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title    : this.$t('station'),
                        type     : 'text',
                        key      : 'station',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('commuting_time'),
                        type     : 'text',
                        key      : 'how_long',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('monday'),
                        type     : 'text',
                        key      : 'monday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('tuesday'),
                        type     : 'text',
                        key      : 'tuesday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('wednesday'),
                        type     : 'text',
                        key      : 'wednesday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('thursday'),
                        type     : 'text',
                        key      : 'thursday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('friday'),
                        type     : 'text',
                        key      : 'friday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('saturday'),
                        type     : 'text',
                        key      : 'saturday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('sunday'),
                        type     : 'text',
                        key      : 'sunday_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('recruitment'),
                        type     : 'text',
                        key      : 'recruitment_text',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('job_broker'),
                        type     : 'text',
                        key      : 'job_broker',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'textarea',
                        key      : 'remark',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('work_place'),
                        type          : "multi-select-filter",
                        key           : "work_place_id",
                        option        : [],
                        listValueField: 'title',
                    },
                    {
                        title         : this.$t('residence'),
                        type          : "multi-select-filter",
                        key           : "residence",
                        option        : [],
                        listValueField: 'name',
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('convert'),
                        icon    : 'corner-up-right',
                        type    : 'modal',
                        modifier: row => this.$can('update_candidate')
                    },
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_candidate')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/candidate`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_candidate')
                    }
                ],
            }
        }
    },
    mounted() {
        this.getFilterOptions();
    },
    methods: {
        getFilterOptions() {
            const Error           = error => this.$toastr.e(error.response.data.message)
            const workPlaceFilter = this.options.filters.find(item => item.key === 'work_place_id');
            const residenceFilter = this.options.filters.find(item => item.key === 'residence');

            axiosGet(`${TENANT_BASE_URL}api/candidate/residence`).then(({data}) => residenceFilter.option = data).catch(Error)
            axiosGet(`${TENANT_BASE_URL}api/work-place?per_page=0`).then(({data}) => workPlaceFilter.option = data).catch(Error)
        },
    },
}