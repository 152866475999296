<template>
    <modal id="claim-type-modal"
           v-model="showModal"
           :title="generateModalTitle('claim_type')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/claim-type`'
            @submit.prevent="submitData"
        >
            <app-form-group
                :label="$t('title')"
                :placeholder="$placeholder('title')"
                v-model="formData.title"
                :required="true"
                :error-message="$errorMessage(errors, 'title')">
            </app-form-group>
            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "ClaimTypeCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#claim-type-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'hotel-claim-type-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

