<template>
    <div class="content-wrapper">
        <app-page-top-section :title="ticketData.subject + ' ~ ' + formatDateToLocal(ticketData.created_at, true)">
            <app-default-button
                :title="$t('edit')"
                v-if="$can('update_ticket_list')"
                @click="isModalActive = true"
            />
            <app-default-button
                :title="$t('history')"
                v-if="$can('view_ticket_list')"
                btn-class="btn btn-success"
                @click="isModalHistoryActive = true"
            />
        </app-page-top-section>

        <div class="card mb-3">
            <div class="card-body">
                <div class="content" v-html="ticketData.description"></div>

                <template v-if="ticketData.assigned_names && ticketData.assigned_names.length > 0">
                    <hr>
                    <div class="assigned-list">
                        <h6>{{ $t('assigned_to') }}</h6>
                        <ul class="mb-0">
                            <li v-for="name in ticketData.assigned_names">
                                <span v-text="name"></span>
                            </li>
                        </ul>
                    </div>
                </template>

                <hr>
                <div class="row">
                    <div class="col-md-4 mb-3 mb-md-0">
                        <h6>{{ $t('category') }}</h6>
                        <div class="badge badge-pill badge-secondary">{{ ticketData.category_name }}</div>
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                        <h6>{{ $t('status') }}</h6>
                        <div v-if="ticketData.status == 'closed'" class="badge badge-pill badge-secondary">
                            {{ ticketData.status_name }}
                        </div>
                        <div v-else-if="ticketData.status == 'resolved'" class="badge badge-pill badge-success">
                            {{ ticketData.status_name }}
                        </div>
                        <div v-else-if="ticketData.status == 'in_progress'" class="badge badge-pill badge-warning">
                            {{ ticketData.status_name }}
                        </div>
                        <div v-else class="badge badge-pill badge-primary">{{ ticketData.status_name }}</div>
                    </div>
                    <div class="col-md-4 mb-3 mb-md-0">
                        <h6>{{ $t('priority') }}</h6>
                        <div v-if="ticketData.priority == 'urgent'" class="badge badge-pill badge-danger">
                            {{ ticketData.priority_name }}
                        </div>
                        <div v-else-if="ticketData.priority == 'high'" class="badge badge-pill badge-warning">
                            {{ ticketData.priority_name }}
                        </div>
                        <div v-else-if="ticketData.priority == 'medium'" class="badge badge-pill badge-primary">
                            {{ ticketData.priority_name }}
                        </div>
                        <div v-else class="badge badge-pill badge-info">{{ ticketData.priority_name }}</div>
                    </div>
                </div>

                <template v-if="ticketData.files && ticketData.files.length > 0">
                    <hr>
                    <h6>{{ $t('attachments') }}</h6>
                    <ul class="mb-0">
                        <li v-for="file in ticketData.files">
                            <a :href="file" target="_blank" v-text="file.split('/').pop()"></a>
                        </li>
                    </ul>
                </template>
            </div>
        </div>

        <div class="card">
            <app-overlay-loader v-if="loading"/>
            <div class="card-body">
                <form ref="form" :data-url='`${TENANT_BASE_URL}app/ticket/comment`' @submit.prevent="submitData">
                    <div class="form-group">
                        <app-input
                            :height="100"
                            type="text-editor"
                            v-model="formData.comment"
                            :placeholder="$textAreaPlaceHolder('comment')"
                        />
                    </div>
                    <app-submit-button
                        :loading="loading"
                        :label="$t('send')"
                        @click="submitData"
                    />
                </form>

                <template v-if="commentsData.length > 0">
                    <ul class="comments mb-0 mt-5">
                        <li v-for="item in commentsData" class="comment-item">
                            <div class="avatar">
                                <img class="rounded-circle" :src="profile_picture_link(item.user)" :alt="item.user.full_name">
                            </div>
                            <div class="comment-content">
                                <div class="author">
                                    <b class="name mr-2" v-text="item.user.full_name"></b>
                                    <time v-text="formatDateToLocal(item.created_at, true)"></time>
                                </div>
                                <div class="content" v-html="item.comment"></div>
                            </div>
                        </li>
                    </ul>
                </template>
            </div>
        </div>

        <app-ticket-create
            v-if="isModalActive"
            v-model="isModalActive"
            @close="isModalActive = false"
            :selected-url="`${TENANT_BASE_URL}app/ticket/list/${ticket.id}`"
        />

        <app-ticket-history
            v-if="isModalHistoryActive"
            v-model="isModalHistoryActive"
            @close="isModalHistoryActive = false"
            :ticket-id="ticket.id"
        />
    </div>
</template>

<style scoped>
h6 {
    font-weight : bold;
}

.comments {
    list-style : none;
    padding    : 0;
}

.comments li {
    padding : 10px 0;
    display : flex;
}

.comments .avatar img {
    width        : 65px;
    margin-right : 20px;
}

.comments li:not(:last-child) {
    border-bottom : 1px solid var(--default-border-color);
}

.assigned-list {
    display   : flex;
    flex-wrap : wrap;
}

.assigned-list ul {
    display    : flex;
    gap        : 15px;
    list-style : none;
    padding    : 0;
}

.assigned-list > h6 {
    padding-right : 35px;
    width         : 130px;
}
</style>

<script>
import FormHelperMixins from "../../../../common/Mixin/Global/FormHelperMixins";
import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import {TENANT_BASE_URL} from "../../../../common/Config/UrlHelper";
import {formatDateToLocal} from "../../../../common/Helper/Support/DateTimeHelper";
import {formDataAssigner} from "../../../../common/Helper/Support/FormHelper";
import {urlGenerator} from "../../../../common/Helper/AxiosHelper";

export default {
    name  : "AppTicketEdit",
    mixins: [HelperMixin, FormHelperMixins],
    props : {
        ticket  : {},
        comments: [],
    },
    data() {
        return {
            TENANT_BASE_URL,
            isModalActive       : false,
            isModalHistoryActive: false,
            loading             : false,
            ticketData          : this.ticket,
            commentsData        : this.comments,
            formData            : {},
        }
    },
    mounted() {
        this.$hub.$on('reload-data-ticket', (data) => this.ticketData = data);
    },
    methods: {
        formatDateToLocal,
        submitData() {
            this.loading = true

            let url      = this.$refs.form.dataset['url'];
            let formData = formDataAssigner(new FormData, this.formData);

            formData.set('ticket_id', this.ticket.id)

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            this.$emit('input', false);
            this.commentsData = [data.comment].concat(this.commentsData)
        },
        afterSuccessFromGetEditData(response) {
            this.loading = false;
        },
        profile_picture_link(user) {
            return this.$optional(user, 'profile_picture', 'full_url') || urlGenerator('/images/avatar.png')
        }
    }
}
</script>