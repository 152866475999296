import { render, staticRenderFns } from "./MailTemplate.vue?vue&type=template&id=112dc0e8&scoped=true"
import script from "./MailTemplate.vue?vue&type=script&lang=js"
export * from "./MailTemplate.vue?vue&type=script&lang=js"
import style0 from "./MailTemplate.vue?vue&type=style&index=0&id=112dc0e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112dc0e8",
  null
  
)

export default component.exports