<template>
    <modal id="type-modal"
           v-model="showModal"
           :title="generateModalTitle('type')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/type`'
            @submit.prevent="submitData"
        >
            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>
            <app-form-group
                type="number"
                :label="$t('cleaning_time')"
                :placeholder="$placeholder('cleaning_time')"
                v-model="formData.cleaning_time"
                :error-message="$errorMessage(errors, 'cleaning_time')">
            </app-form-group>
            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "TypeCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#type-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'type-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
        listBed() {
            const list = [];

            for (let i = 1; i <= 5; i++) {
                list.push({
                    id  : i,
                    name: i,
                })
            }

            return list
        },
    },
}
</script>

