<template>
    <div>
        <form class="mt-5"
            ref="form"
            :data-url='selectedUrl ? selectedUrl : CONTRACT'
            @submit.prevent="submitData"
        >
            <div class="form-group">
                <label for="employee_image">{{ $t('employee_image') }}<br>
                    <small class="text-muted font-italic">
                        {{ $t('recommended_employee_image_size') }}
                    </small>
                </label>

                <app-input
                    id="employee_image"
                    type="custom-file-upload"
                    :generate-file-url="false"
                    v-model="formData.employee_image"
                    :label="$t('employee_image')"
                    :error-message="$errorMessage(errors, 'employee_image')"
                />
            </div>

<!--            <app-form-group-selectable-->
<!--                :label="$t('template')"-->
<!--                type="search-select"-->
<!--                v-model="formData.template_id"-->
<!--                list-value-field="name"-->
<!--                :placeholder="$t('template')"-->
<!--                :error-message="$errorMessage(errors, 'template_id')"-->
<!--                :fetch-url="`${apiUrl.TEMPLATE}?get-all=1`"-->
<!--                disabled-->
<!--            />-->

<!--            <app-form-group-->
<!--                :label="$t('contract_no')"-->
<!--                :placeholder="$placeholder('contract_no')"-->
<!--                v-model="formData.contract_no"-->
<!--                :required="true"-->
<!--                :error-message="$errorMessage(errors, 'contract_no')">-->
<!--            </app-form-group>-->

<!--            <app-form-group-->
<!--                type="date"-->
<!--                :label="$t('date')"-->
<!--                :placeholder="$placeholder('date')"-->
<!--                v-model="formData.date"-->
<!--                :required="true"-->
<!--                :error-message="$errorMessage(errors, 'date')">-->
<!--            </app-form-group>-->

            <div class="row">
                <div class="col-12 col-md-6">
                    <app-form-group
                        id="contract_no"
                        :label="$t('contract_no')"
                        :placeholder="$placeholder('contract_no')"
                        v-model="formData.contract_no"
                        :required="true"
                        :error-message="$errorMessage(errors, 'contract_no')">
                    </app-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-group
                        :label="$t('email')"
                        :placeholder="$placeholder('email')"
                        v-model="formData.email"
                        :required="true"
                        :error-message="$errorMessage(errors, 'email')">
                    </app-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-group
                        :label="$t('first_name')"
                        :placeholder="$placeholder('first_name')"
                        v-model="formData.first_name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'first_name')">
                    </app-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-group
                        :label="$t('last_name')"
                        :placeholder="$placeholder('last_name')"
                        v-model="formData.last_name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'last_name')">
                    </app-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('birthday')"
                        :placeholder="$placeholder('birthday')"
                        v-model="formData.birthday"
                        :required="true"
                        :error-message="$errorMessage(errors, 'birthday')">
                    </app-form-group>
                </div>
                <div class="col-12 col-md-6">
                    <app-form-group
                        :label="$t('mobile')"
                        :placeholder="$placeholder('mobile')"
                        v-model="formData.mobile"
                        :required="true"
                        :error-message="$errorMessage(errors, 'mobile')">
                    </app-form-group>
                </div>
            </div>

            <app-form-group
                :label="$t('address')"
                :placeholder="$placeholder('address')"
                v-model="formData.address"
                :required="true"
                :error-message="$errorMessage(errors, 'address')">
            </app-form-group>

            <div class="term_box">
                <div class="term_title h6 font-weight-bold pl-2 position-relative border-left-3 border-primary">
                    利用規約と個人情報の取り扱いについて
                    <span class="inline-block badge-danger px-1">必須</span>
                </div>
                <div class="term_note pt-3">
                    「「委託契約」「個人情報の取り扱いについて」に同意する］をクリックすると、
                    <a href="#" @click="previewContract" class="text-primary border-bottom">委託契約</a>
                    、 個人情報の取り扱いについて  を確認・同意したものとみなします。
                </div>
                <div class="term_btn form-check p-3 text-center mt-3">
                    <input type="checkbox" v-model="isTermChecked" class="form-check-input cursor-pointer" value="" id="term_check" />
                    <label class="form-check-label cursor-pointer" for="term_check">
                        「利用規約」「個人情報の取り扱いについて」に同意する
                    </label>
                </div>
            </div>

<!--            <app-form-group-->
<!--                type="textarea"-->
<!--                :label="$t('remark')"-->
<!--                :placeholder="$textAreaPlaceHolder('remark')"-->
<!--                v-model="formData.remark"-->
<!--                :required="false"-->
<!--                :text-area-rows="3"-->
<!--                :error-message="$errorMessage(errors, 'remark')">-->
<!--            </app-form-group>-->

            <div class="form-group mt-3">
                <label>{{ $t('signature') }}</label>
                <div id="signature-pad" class="signature-pad">
                    <div class="signature-pad--body" style="border: solid 1px #f0f2f5; border-radius: 5px">
                        <canvas></canvas>
                    </div>
                    <app-input
                        type="hidden"
                        v-model="formData.signature"
                        :error-message="$errorMessage(errors, 'signature')"
                    />
                    <div class="signature-pad--footer">
                        <div class="signature-pad--actions mt-2">
                            <button type="button" class="btn btn-primary btn-sm clear" data-action="clear" @click="this.signaturePadClear">{{ $t('clear') }}</button>
                            <button type="button" class="btn btn-primary btn-sm" data-action="undo" @click="this.signaturePadUndo">{{ $t('undo') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group mt-4 text-right">
<!--                <app-default-button @click="previewContract" :title="$t('preview')"/>-->
                <app-submit-button @click="submitData" :disabled="!isTermChecked" :loading="loading" :label="$t('sign')"/>
            </div>

        </form>

        <app-contract-preview-modal
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            :contract-data="formData"
            @close="isModalActive = false"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { CONTRACT, TEMPLATE } from "../../../../Config/ApiUrl";
import SignaturePad from "signature_pad";
import moment from "moment";
import { axiosGet } from "../../../../../common/Helper/AxiosHelper";

export default {
    name: "InputContract",
    mixins: [FormHelperMixins, ModalMixin],
    props     : {
        templateId: {
            required: true,
        },
    },
    data() {
        return {
            formData: {
                template_id: this.templateId,
            },
            CONTRACT,
            detailTemplate: {},
            signaturePad: null,
            isModalActive: false,
            isTermChecked: false,
        }
    },
    methods: {
        previewContract() {
            if (!this.signaturePad.isEmpty()) {
                this.formData.signature = this.signaturePad.toDataURL();
            }
            this.isModalActive = true;

            // for validate before preview
            // this.formData.is_preview = true;
            // this.setBasicFormActionData().save(this.formData);
        },
        submitData() {
            this.formData.is_preview = false;
            this.setBasicFormActionData().save(this.formData);
        },
        afterSuccess({data}) {
            if (this.formData.is_preview) {
                this.isModalActive = true;
                return;
            }

            this.$toastr.s('', this.$t(data.message));

            setTimeout(() => {
                window.location.href = data.contract_complete_route;
            }, 1000);
        },
        signaturePadClear() {
            this.signaturePad.clear();
        },
        signaturePadUndo() {
            const data = this.signaturePad.toData();
            if (data) {
                data.pop(); // remove the last dot or line
                this.signaturePad.fromData(data);
            }
        },
        beforeSubmit() {
            this.formData.birthday = this.formData.birthday ? moment(this.formData.birthday).format('YYYY-MM-DD') : null;
            this.formData.date = this.formData.date ? moment(this.formData.date).format('YYYY-MM-DD') : null;
            this.formData.signature = !this.signaturePad.isEmpty() ? this.signaturePad.toDataURL() : null;
        },
        initSignaturePad(signature = null) {
            const wrapper = document.getElementById("signature-pad");
            if (!wrapper) {
                return;
            }

            const canvas = wrapper.querySelector("canvas");
            this.signaturePad = new SignaturePad(canvas, {});
            if (signature) {
                this.signaturePad.fromDataURL(signature);
            }
        },
        getDetailTemplate() {
            const templateId = this.templateId;
            axiosGet(`${TEMPLATE}/${templateId}`).then(({data}) => {
                this.detailTemplate = data || {};
                // if contract number is auto, then disable this input
                if (this.detailTemplate.number_type === 1) {
                    $('#contract_no').prop('disabled', true).val(this.$t('auto'));
                    this.formData.contract_no = this.$t('auto');
                }
            })
        },
    },
    mounted() {
        this.getDetailTemplate();
        this.initSignaturePad();
    }
}
</script>

<style scoped>
     .term_title {
         line-height: 2;
         border-left: 4px solid;
     }

     .term_btn {
         border: 1px solid var(--default-border-color);
         &:has(.form-check-input:checked) {
             border-color: #0b9eff;
             background: rgba(11, 158, 255, 0.3) ;
         }
     }
</style>
