<template>
    <modal id="sale-card-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('card')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/sale-card`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('name')"
                        :placeholder="$placeholder('name')"
                        v-model="formData.name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'name')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('position')"
                        :placeholder="$placeholder('position')"
                        v-model="formData.position"
                        :required="true"
                        :error-message="$errorMessage(errors, 'position')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('company_name')"
                        :placeholder="$placeholder('company_name')"
                        v-model="formData.company_name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'company_name')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('phone')"
                        :placeholder="$placeholder('phone')"
                        v-model="formData.phone"
                        :required="false"
                        :error-message="$errorMessage(errors, 'phone')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('tel')"
                        :placeholder="$placeholder('tel')"
                        v-model="formData.tel"
                        :required="false"
                        :error-message="$errorMessage(errors, 'tel')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="email"
                        :label="$t('email')"
                        :placeholder="$placeholder('email')"
                        v-model="formData.email"
                        :required="false"
                        :error-message="$errorMessage(errors, 'email')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('fax')"
                        :placeholder="$placeholder('fax')"
                        v-model="formData.fax"
                        :required="false"
                        :error-message="$errorMessage(errors, 'fax')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('website')"
                        :placeholder="$placeholder('website')"
                        v-model="formData.website"
                        :required="false"
                        :error-message="$errorMessage(errors, 'website')">
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        :label="$t('address')"
                        :placeholder="$placeholder('address')"
                        v-model="formData.address"
                        :required="false"
                        :error-message="$errorMessage(errors, 'address')">
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ $t('document') }}</label>
                        <app-input
                            type="dropzone"
                            v-model="files"
                            :error-message="$errorMessage(errors, 'files.0')"
                        />
                    </div>
                    <app-form-group
                        type="textarea"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :text-area-rows="4"
                        :error-message="$errorMessage(errors, 'remark')">
                    </app-form-group>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "CardCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            TENANT_BASE_URL,
            files   : [],
            formData: {},
        }
    },
    methods: {
        submitData() {
            this.loading   = true
            const url      = this.$refs.form.dataset["url"];
            const formData = formDataAssigner(new FormData, this.formData);

            this.files.forEach(file => formData.append('files[]', file))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#sale-card-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'sale-card-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

