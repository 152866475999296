import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";
import {DepartmentFilterMixin, UserFilterMixin} from "./FilterMixin";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import moment from "moment";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, DepartmentFilterMixin, UserFilterMixin],
    data() {
        const months = []
        const years  = []

        for (const i in moment.months()) {
            months.push({
                id   : parseInt(i) + 1,
                label: moment.months()[i],
            })
        }

        for (let i = moment().year(); i > moment().year() - 50; i--) {
            years.push({
                id   : i,
                label: i,
            })
        }

        return {
            options: {
                name             : this.$t('commute_details'),
                url              : `${TENANT_BASE_URL}app/commute-details`,
                showHeader       : true,
                showCount        : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('date'),
                        type     : 'custom-html',
                        key      : 'date',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                    {
                        title    : this.$t('employee'),
                        type     : 'custom-html',
                        key      : 'user',
                        isVisible: true,
                        modifier : (user) => {
                            return user ? user.full_name : '';
                        }
                    },
                    {
                        title    : this.$t('type'),
                        type     : 'text',
                        key      : 'type',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('departure_station'),
                        type     : 'text',
                        key      : 'departure_station',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('arrival_station'),
                        type     : 'text',
                        key      : 'arrival_station',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('cost'),
                        type     : 'text',
                        key      : 'cost',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('purpose'),
                        type     : 'text',
                        key      : 'purpose',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('file'),
                        type     : 'custom-html',
                        key      : 'file',
                        isVisible: true,
                        modifier : (file) => {
                            if (file) {
                                return `<a href="${urlGenerator(file)}" target="_blank" class="d-block">${this.$t('view')}</a>`
                            }
                            return '--'
                        }
                    },
                    {
                        title        : this.$t('confirmed'),
                        type         : 'component',
                        key          : 'confirmed',
                        isVisible    : true,
                        componentName: 'app-commute-details-confirmed',
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('department'),
                        type          : "multi-select-filter",
                        key           : "departments",
                        manager       : true,
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_departments')
                    },
                    {
                        title         : this.$t('users'),
                        type          : "multi-select-filter",
                        key           : "users",
                        option        : [],
                        listValueField: 'full_name',
                        permission    : !!this.$can('view_users')
                    },
                    {
                        title         : this.$t('month'),
                        type          : "multi-select-filter",
                        key           : "month",
                        listValueField: 'label',
                        option        : months,
                    },
                    {
                        title         : this.$t('year'),
                        type          : "multi-select-filter",
                        key           : "year",
                        listValueField: 'label',
                        option        : years,
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_commute_details')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/commute-details/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_commute_details')
                    }
                ],
            }
        }
    },
}