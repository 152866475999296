<template>
    <div :class="!props.is_in_tab ? 'content-wrapper' : ''">
        <app-page-top-section v-if="!props.is_in_tab" :title="$t('agreement_item')">
            <app-default-button
                :title="$fieldTitle('add', 'agreement_item', true)"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="agreement-item-table"
            :options="options"
            @action="triggerActions"
        />

        <app-agreement-item-modal
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            :template-id="props.template_id"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('agreement-item-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
    import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
    import { AGREEMENT_ITEM } from "../../../../Config/ApiUrl";
    import AgreementItemMixin from "../../../Mixins/AgreementItemMixin";

    export default {
        name: "AppAgreementItem",
        mixins: [HelperMixin, AgreementItemMixin],
        props: {
            id: {
                type: String,
                default: '',
            },
            props: {
                default: {}
            }
        },
        data() {
            return {
                isModalActive: false,
                selectedUrl: '',
            }
        },
        methods: {
            triggerActions(row, action, active) {
                if (action.title === this.$t('edit')) {
                    this.selectedUrl = `${AGREEMENT_ITEM}/${row.id}`;
                    this.isModalActive = true;
                } else {
                    this.getAction(row, action, active)
                }
            },
            openModal() {
                this.isModalActive = true;
                this.selectedUrl = ''
            },
        },
        mounted() {
            this.$hub.$on('headerButtonClicked-' + this.id, (component) => {
                this.isModalActive = true;
                this.selectedUrl = '';
            })
        },
    }
</script>
