<template>
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-primary" v-if="data.length > 0"
             v-for="(hotel, rowIndex) in data" :key="'row' + rowIndex">
            <app-hotel-preview-card
                :id="'hotel'"
                :hotel="hotel"
                :status="status"
                :actions="actions"
                @action-hotel="triggerActions"
            />
        </div>
    </div>
</template>

<style>
.employee-preview-card img {
    object-fit : cover;
}
</style>

<script>
export default {
    name : "HotelCardView",
    props: {
        data   : {
            type    : Array,
            required: true
        },
        actions: {
            type: Array
        }
    },
    data() {
        return {
            loading: false,
            status : [],
        }
    },
    methods: {
        triggerActions(row, action, active) {
            this.$emit('getCardAction', row, action, active);
        }
    }
}
</script>
