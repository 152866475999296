<template>
    <div v-if="data.length > 0" class="card card-with-shadow border-0" style="min-height: 400px;">
        <div class="card-body">
            <div class="btn-group text-center mb-4" role="group">
                <button
                    type="button"
                    class="btn btn-outline-light"
                    v-for="item in cleaning_status"
                    v-text="item.name + ': ' + item.count"
                    :style="{color: item.color || '#000'}"
                />
            </div>
            <div class="btn-group text-center mb-4 ml-4" role="group">
                <button
                    type="button"
                    class="btn btn-outline-light"
                    v-for="item in staying_status"
                    v-text="item.name + ': ' + item.count"
                    :style="{color: item.color || '#000'}"
                />
            </div>
            <div class="summery-calendar">
                <div class="d-flex">
                    <div class="profile-column">
                        <template v-for="room in data">
                            <div class="profile-box">
                                <h6 class="text-reset m-0" v-text="room.floor + 'F'"/>
                            </div>
                        </template>
                    </div>
                    <div class="date-column custom-scrollbar custom-scrollbar-with-mouse-move"
                         style="margin-bottom: -17px">
                        <div class="d-flex" v-for="room in data">
                            <div class="date-hour-wrapper" v-for="item in room.rooms">
                                <div class="date-hour-wrapper border-0">
                                    <app-hotel-status-preview-card
                                        :id="'room'"
                                        :room="item"
                                        :actions="actions"
                                        :checked="checked"
                                        @action-room="triggerActions"
                                        @checked="check"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.summery-calendar .profile-column .profile-box {
    height        : 106px;
    min-width     : 150px;
    border-top    : 1px solid var(--base-color);
    background    : #f5f5f5;
    margin-bottom : 2px;
    width         : auto;
}

.summery-calendar .date-column .date-hour-wrapper {
    height        : 106px;
    min-width     : 100px;
    font-size     : 12px;
    line-height   : 21px;
    margin-bottom : 2px;
    border        : 0;
    padding-left  : 2px;
}

.btn-outline-light {
    border-color : #ccc;
}
</style>

<script>
export default {
    name : "HotelStatusCardView",
    props: {
        data        : {
            type    : Array,
            required: true
        },
        filteredData: {
            type: Object,
        },
        actions     : {
            type: Array
        }
    },
    data() {
        return {
            loading        : false,
            cleaning_status: {},
            staying_status : {},
            checked        : [],
        }
    },
    mounted() {
        this.$hub.$on('checked-values-rooms', (checked) => {
            this.checked = checked
        })
    },
    watch  : {
        'data'   : {
            handler  : function () {
                this.summaryStatus('cleaning')
                this.summaryStatus('staying')
            },
            immediate: true
        },
        'checked': {
            handler  : function (checked) {
                this.$hub.$emit('checked-values-rooms', checked);
            },
            immediate: true
        }
    },
    methods: {
        check(id) {
            if (this.checked.find(e => id === e)) {
                this.checked = this.checked.filter(e => id !== e)
            } else {
                this.checked.push(id)
            }
        },
        triggerActions(row, action, active) {
            this.$emit('getCardAction', row, action, active);
        },
        summaryStatus(key) {
            this.loading          = true;
            this[key + '_status'] = {}

            for (const floor of this.data) {
                if (floor.rooms) {
                    for (const room of floor.rooms) {
                        if (room.status && room.status[key]) {
                            if (this[key + '_status'][room.status[key].id]) {
                                this[key + '_status'][room.status[key].id].count++;
                                if (room.status.color) {
                                    this[key + '_status'][room.status[key].id].color = room.status.color;
                                }
                            } else {
                                this[key + '_status'][room.status[key].id] = {
                                    id   : room.status[key].id,
                                    name : room.status[key].name,
                                    color: room.status.color || '#000',
                                    count: 1,
                                }
                            }
                        }
                    }
                }
            }

            this.loading = false;
        },
    }
}
</script>
