import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        const tab = this.$parent.$props.tabs.find(e => e.component === 'app-hotel-house-keeping-status')
        return {
            urlGenerator,
            options: {
                name             : this.$t('status'),
                url              : `${TENANT_BASE_URL}app/hotel/house-keeping-status?hotel_id=${this.hotel ? this.hotel.id : tab.hotel.id}`,
                showHeader       : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                columns          : [
                    {
                        title    : this.$t('order'),
                        type     : 'text',
                        key      : 'order',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('color'),
                        type     : 'custom-html',
                        key      : 'color',
                        isVisible: true,
                        modifier : (color) => {
                            return '<span class="badge" style="background-color: ' + color + '">' + color + '</span>';
                        }
                    },
                    {
                        title    : this.$t('created'),
                        type     : 'custom-html',
                        key      : 'created_at',
                        isVisible: true,
                        modifier : date => formatDateToLocal(date)
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title : this.$t('created'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'default',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_house_keeping_status')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/house-keeping-status/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_hotel_house_keeping_status')
                    }
                ],
            }
        }
    },
}