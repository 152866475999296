<template>
    <modal id="agreement-item-modal"
           v-model="showModal"
           :title="generateModalTitle('agreement_item')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : AGREEMENT_ITEM'
            @submit.prevent="submitData"
        >

            <app-form-group-selectable
                :label="$t('template')"
                type="search-select"
                v-model="formData.template_id || templateId"
                list-value-field="name"
                :placeholder="$t('template')"
                :error-message="$errorMessage(errors, 'template_id')"
                :fetch-url="templateId ? `${apiUrl.TEMPLATE}?template_id=${templateId}&get-all=1` : `${apiUrl.TEMPLATE}?get-all=1`"
                disabled
            />

<!--            <app-form-group-->
<!--                type="number"-->
<!--                :label="$t('order')"-->
<!--                :placeholder="$placeholder('order')"-->
<!--                v-model="formData.order"-->
<!--                :required="true"-->
<!--                :error-message="$errorMessage(errors, 'order')">-->
<!--            </app-form-group>-->

            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>

            <app-form-group
                type="textarea"
                :label="$t('content')"
                :placeholder="$textAreaPlaceHolder('content')"
                v-model="formData.content"
                :required="true"
                :text-area-rows="4"
                :error-message="$errorMessage(errors, 'content')">
            </app-form-group>

            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :text-area-rows="4"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { AGREEMENT_ITEM } from "../../../../Config/ApiUrl";

export default {
        name: "AgreementItemCreateEditModal",
        mixins: [FormHelperMixins, ModalMixin],
        props: {
            templateId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                formData: {},
                AGREEMENT_ITEM
            }
        },
        methods: {
            beforeSubmit() {
                this.formData.template_id = this.templateId;
            },
            afterSuccess({data}) {
                this.formData = {};
                $('#agreement-item-modal').modal('hide');
                this.$emit('input', false);
                this.toastAndReload(data.message, 'agreement-item-table');
            },
            afterSuccessFromGetEditData(response) {
                this.preloader = false;
                this.formData = response.data;
            },
        },
    }
</script>

