<template>
    <div class="content-wrapper">
        <app-tab :tabs="tabs" :icon="tabIcon"/>
    </div>
</template>

<script>
import TemplateEditTabMixin from "../../../Mixins/TemplateEditTabMixin";

export default {
    name      : "DetailTemplate",
    mixins    : [TemplateEditTabMixin],
    props     : {
        templateId: {},
    },
    data() {
        return {
            tabs    : [],
            tabIcon : 'edit',
        }
    },
    created() {
        this.tabs = this.listTabs;
    },
}
</script>
