<template>
    <div class="card card-with-shadow border-0 h-100 employee-preview-card">
        <div class="card-body position-relative d-flex flex-column justify-content-between">
            <div v-if="showAction" class="dropdown options-dropdown position-absolute">
                <button type="button"
                        class="btn-option btn d-flex align-items-center justify-content-center"
                        data-toggle="dropdown">
                    <app-icon name="more-horizontal"/>
                </button>
                <div class="dropdown-menu dropdown-menu-right py-2 mt-1">
                    <a class="dropdown-item px-4 py-2" href="#" v-for="(action,index) in actions" :key="index"
                       v-if="checkIfVisible(action, manual)" @click.prevent="callAction(action)">
                        {{ action.title }}
                    </a>
                </div>
            </div>
            <div class="text-center">
                <app-avatar
                    v-if="manual.files.length > 0"
                    avatar-class="avatars-w-100 mb-3 mx-auto d-inline-block"
                    :title="manual.title"
                    :img="manual.files[0]"
                />

                <p class="mb-2">{{ manual.title }}</p>

                <p v-for="hotel in manual.hotels" class="mb-2 font-size-80 text-muted" v-text="hotel.name"/>
            </div>
        </div>
    </div>
</template>

<script>
import CoreLibrary from "../../../../../core/helpers/CoreLibrary";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name    : "ManualPreviewCard",
    extends : CoreLibrary,
    mixins  : [FormHelperMixins],
    props   : {
        manual    : {
            type    : Object,
            required: true
        },
        actions   : {
            type   : Array,
            require: true
        },
        showAction: {
            type   : Boolean,
            default: true
        },
        id        : {
            type   : String,
            require: true
        }
    },
    methods : {
        callAction(action) {
            this.$emit('action-' + this.id, this.manual, action, true)
        },
        checkIfVisible(action, manual) {
            if (this.isFunction(action.modifier)) {
                return action.modifier(manual);
            }
            return true;
        },
    },
    computed: {
        profileUrl() {
            return urlGenerator(`/profile`)
        }
    }
}
</script>