<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('employee_day_shift')">
            <app-default-button
                v-if="this.$can('update_attendance_status')"
                :title="$t('add_employee_schedule')"
                @click="openModalAddMultiple"
            />
        </app-page-top-section>

        <app-filter-with-search
            :options="options"
            @filteredValue="setFilterValues"
            @searchValue="setSearchValue"
        />

<!--        <app-attendance-create-edit-modal-->
<!--            v-if="isAttendanceModalActive"-->
<!--            v-model="isAttendanceModalActive"-->
<!--        />-->

        <employee-day-shift-create-multiple-modal
            v-if="isModalAddMultipleActive"
            v-model="isModalAddMultipleActive"
            @createMultipleSuccess="updateEmployeeDayShiftSuccess"
        />

        <app-employee-day-shift-create-edit-modal
            v-if="isAttendanceModalActive"
            v-model="isAttendanceModalActive"
            :selected-column="selectedColumn"
            :selected-url="selectedUrl"
            @updateSuccess="updateEmployeeDayShiftSuccess"
        />

        <app-confirmation-modal
            v-if="isApprovalModalActive"
            icon="settings"
            :message="$t('approve_this_work_shift')"
            modal-id="app-approval-modal"
            @confirmed="confirmApproval"
            @cancelled="cancelConfirmApproval"
        />

        <app-confirmation-modal
            v-if="isConfirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmDelete"
            @cancelled="cancelled"
        />

        <div class="card card-with-shadow border-0" style="min-height: 400px;" id="employee-day-shift-table">
            <div class="card-body">
                <div class="summery-calendar">
                    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between mb-primary">
                        <app-month-calendar :show-date-range-calendar="true"/>
<!--                        <app-period-calendar :filters="filters"/>-->
                        <app-period-calendar />
                    </div>

                    <app-overlay-loader v-if="loading"/>

                    <div class="d-flex" v-else-if="$optional(attendances, 'data', 'length')">
                        <div class="profile-column">
                            <div class="column-header">{{ $t('profile') }}</div>
                            <template v-for="employee in attendances.data">
                                <app-employee-profile-card :employee="employee" :employee-action-btn="getEmployeeActionBtn(employee)" style="height: 180px"/>
                            </template>
                        </div>
                        <div class="date-column custom-scrollbar custom-scrollbar-with-mouse-move">
                            <div class="d-flex">
                                <div class="date-value-wrapper" v-for="range in ranges">
                                    <div class="date">
                                        {{ formatTitleRangeDate(range) }}
                                    </div>
                                    <div
                                        class="date-hour-wrapper justify-content-start" style="height: 180px"
                                        v-for="(employee, index) in attendances.data"
                                    >
                                        <p class="text-center flex-grow-1 mb-0" v-html="formatEmployeeAttendances(employee, range)"></p>
                                        <dropdown-action class="text-center flex-grow-0" style="padding: 5px"
                                            :key="'option_employee_'+index"
                                            :actions="getDropdownAction(employee, range)"
                                            :unique-key="employee.id"
                                            :row-data="employee"
                                            @action="actionButton"/>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-data-block v-else :message="$t('nothing_to_show_here')"/>
                    <app-pagination
                        v-if="attendances.total && attendances.last_page > 1"
                        :rowLimit="attendances.per_page"
                        :totalRow="attendances.total"
                        @submit="setPagination"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppEmployeeProfileCard from "../../Attendance/Component/AppEmployeeProfileCard";
import AppAttendanceTopButtons from "../../Attendance/Component/AppAttendanceTopButtons";
import {
  DepartmentFilterMixin,
  DesignationFilterMixin,
  EmploymentStatusFilterMixin,
  WorkingShiftFilterMixin
} from "../../../Mixins/FilterMixin";
import MemoizeMixins from "../../../../../common/Helper/Support/MemoizeMixins";
import AttendanceHelperMixin from "../../../Mixins/AttendanceHelperMixin";
import { ATTENDANCES, EMPLOYEE_DAY_SHIFT } from "../../../../Config/ApiUrl";
import { serverDateFormat, serverDateTimeFormat } from "../../../../../common/Helper/Support/DateTimeHelper";
import moment from "moment";
import TemplateHelper from "../../../../../core/helpers/template-helper/TemplateHelper";
import { axiosDelete, axiosGet, axiosPost } from "../../../../../common/Helper/AxiosHelper";
import DropdownAction from "../../../../../core/components/datatable/DropdownAction.vue";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import DayShiftUserMixin from "../../../Mixins/DayShiftUserMixin";
import EmployeeDayShiftCreateMultipleModal from "../../Attendance/Component/EmployeeDayShiftCreateMultipleModal.vue";

export default {
    name      : "AttendanceDetails",
    components: {
        EmployeeDayShiftCreateMultipleModal,
        DropdownAction,
        AppEmployeeProfileCard,
        AppAttendanceTopButtons
    },
    mixins    : [
        DepartmentFilterMixin, DesignationFilterMixin, EmploymentStatusFilterMixin, HelperMixin,
        WorkingShiftFilterMixin, MemoizeMixins, AttendanceHelperMixin, DayShiftUserMixin,
    ],
    data() {
        return {
            filters: [{
                id   : 'thisMonth',
                value: this.$t('this_month')
            }],
            isAttendanceModalActive: false,
            isConfirmationModalActive: false,
            isModalAddMultipleActive: false,
            isApprovalModalActive: false,
            selectedRow: null,
            selectedColumn: {},
            selectedUrl: null,
        }
    },
    methods   : {
        actionButton(rowData, action, flag) {
            this.selectedRow = rowData;
            switch (action.action_type) {
                case 'add':
                    this.selectedColumn = action.column_data;
                    this.selectedUrl = null;
                    this.openModalAdd();
                    break;
                case 'edit':
                    this.selectedColumn = {};
                    this.selectedRow = rowData;
                    const user_id = action.column_data.pivot.user_id;
                    const day_shift_id = action.column_data.pivot.day_shift_id;
                    const date = action.column_data.pivot.date;
                    this.selectedUrl = `${EMPLOYEE_DAY_SHIFT}?user_id=${user_id}&day_shift_id=${day_shift_id}&date=${date}`
                    this.openModalEdit();
                    break;
                case 'delete':
                    this.selectedColumn = action.column_data;
                    this.openModalDelete();
                    break;
            }
        },
        openModalDelete() {
            this.isConfirmationModalActive = true;
        },
        openModalEdit() {
            this.isAttendanceModalActive = true;
        },
        openModalAdd() {
            this.isAttendanceModalActive = true;
        },
        openModalAddMultiple() {
            this.isModalAddMultipleActive = true;
        },
        getAttendances(queryString) {
            this.loading = true;
            axiosGet(`${ATTENDANCES}/details?${queryString}`).then(response => {
                this.attendances         = response.data.attendances;
                this.ranges              = response.data.range.map(range => moment(range, serverDateTimeFormat));
                this.defaultWorkingShift = response.data.default;
            }).finally(() => {
                this.loading = false;
                TemplateHelper.moveHorizontalScrollbarWithMouse();
            })
        },
        formatEmployeeAttendances(employee, date) {
            const employeeDayShift = this.getEmployeeDayShift(employee, date);
                return `<div class="date-hour flex-column">
                            <span class="hour">${employeeDayShift?.start_at || ''}</span>
                             -
                            <span class="hour">${employeeDayShift?.end_at || ''}</span>
                        </div>`;
        },
        getEmployeeDayShift(employee, date) {
            // return this.memoize(`attendance-${employee.id}-${date.format(serverDateFormat)}`, () => {
                return employee.day_shifts.find(day_shift => {
                    return day_shift.pivot.date === date.format(serverDateFormat);
                });
            // });
        },
        formatTitleRangeDate(range) {
            let date = range.locale(window.appLanguage)
            return date.format('MMM D')
        },
        cancelled() {
            this.isConfirmationModalActive = false;
        },
        confirmDelete() {
            return axiosDelete(`${EMPLOYEE_DAY_SHIFT}?id=${this.selectedColumn.pivot.id}`).then(response => {
                this.$toastr.s('', response.data.message);
                this.isConfirmationModalActive = false;
                this.getAttendances(this.queryString);
            }).catch((error) => {
                if (error.message)
                    this.toastException(error);
            });
        },
        confirmApproval() {
            const urlParams = new URLSearchParams(this.queryString);
            const data = {
                user_id: this.selectedRow?.profile?.user_id,
                month_number: urlParams.get('month_number'),
                year: urlParams.get('year'),
            };

            return axiosPost(`${EMPLOYEE_DAY_SHIFT}/create-work-shift-from-day-shift`, data).then(response => {
                this.$toastr.s('', response.data.message);
                this.isApprovalModalActive = false;
                this.getAttendances(this.queryString);
            }).catch((error) => {
                if (error.message)
                  this.toastException(error);
            });
        },
        cancelConfirmApproval() {
            this.isApprovalModalActive = false;
        },
        updateEmployeeDayShiftSuccess() {
            this.getAttendances(this.queryString);
        },
        getEmployeeActionBtn(employeeData) {
            const isApproved = employeeData.day_shifts.some(shift => Number(shift.pivot.status) === 1);
            return {
                show: true,
                title: this.$t('status_approve'),
                class: isApproved ? 'btn-secondary disabled' : 'btn-success',
                action: () => {
                    this.isApprovalModalActive = true;
                    this.selectedRow = employeeData;
                }
            };
        },
        getDropdownAction(employee, range) {
            const employeeDayShift = this.getEmployeeDayShift(employee, range);
            if (!employeeDayShift) {
                return [
                    {
                        title: 'Add',
                        type: 'modal',
                        name: 'add',
                        icon: 'edit',
                        modalId: 'employee-day-shift-modal',
                        action_type: 'add',
                        column_data: {
                            user_id: employee.id,
                            date: range.format(serverDateFormat),
                        },
                        className: '',
                    },
                ];
            }

            return [
                {
                    title: 'Edit',
                    type: 'modal',
                    name: 'edit',
                    icon: 'edit',
                    modalId: 'employee-day-shift-modal',
                    action_type: 'edit',
                    column_data: employeeDayShift,
                    className: employeeDayShift.pivot.status ? 'disabled' : '',
                },
                {
                    title: 'Delete',
                    type: 'modal',
                    name: 'edit',
                    icon: 'edit',
                    action_type: 'delete',
                    column_data: employeeDayShift,
                    className: employeeDayShift.pivot.status ? 'disabled' : '',
                }
            ];
        },
    },
    watch     : {
        queryString: {
            handler  : function (queryString) {
                if (!queryString) {
                    return;
                }
                this.getAttendances(queryString)
            },
            immediate: true
        }
    }
}
</script>

<style>
    .date-hour .hour {
        min-height: calc(0.935rem * 1.5);
    }
</style>
