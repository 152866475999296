<template>
    <modal id="type-modal"
           v-model="showModal"
           :title="generateModalTitle('type')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : DOCUMENT_TYPE'
            @submit.prevent="submitData"
        >
            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>

            <app-form-group
                type="textarea"
                :label="$t('description')"
                :placeholder="$textAreaPlaceHolder('description')"
                v-model="formData.description"
                :required="false"
                :error-message="$errorMessage(errors, 'description')">
            </app-form-group>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {DOCUMENT_TYPE} from "../../../../Config/ApiUrl";

export default {
    name  : "DocumentTypeCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            formData: {},
            DOCUMENT_TYPE
        }
    },
    methods: {
        afterSuccess({data}) {
            this.formData = {};
            $('#type-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'document-type-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

