<template>
    <div class="row my-5 align-items-center">
        <div class="col-lg-4 mb-4 mb-lg-0" v-if="room_type.length > 0">
            <div class="chart-data-list">
                <div v-for="type in room_type" class="data-group-item" style="color: #df4736">
                    <span class="square" style="background-color: #df4736"/>
                    {{ type.name }}
                    <span class="value">{{ type.rooms_count || 0 }} {{ $t('rooms') }}</span>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <div class="row">
                <div class="col-md-6 col-lg-4 mb-3">
                    <div class="default-base-color text-center rounded p-3">
                        <h5 title="HH:MM:SS">{{ summaries.scheduled || '00:00:00' }}</h5>
                        <p class="text-muted mb-0">{{ $t('total_schedule_hour') }}</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-3">
                    <div class="default-base-color text-center rounded p-3">
                        <h5 title="HH:MM:SS">{{ summaries.paid_leave || '00:00:00' }}</h5>
                        <p class="text-muted mb-0">Leave hour (paid)</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-3">
                    <div class="default-base-color text-center rounded p-3">
                        <h5 :class="`text-${summaries.availability_behavior_class || 'text-danger'}`">
                            {{ summaries.percentage || 0 }}%</h5>
                        <p class="text-muted mb-0">{{ $t('total_work_availability') }}</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-3 mb-lg-0">
                    <div class="default-base-color text-center rounded p-3">
                        <h5 title="HH:MM:SS">{{ summaries.worked || '00:00:00' }}</h5>
                        <h6 :title="$t('in_hours')">({{ workedMinutePercentage(summaries.worked) }}
                            {{ $t('hour_short_form') }})</h6>
                        <p class="text-muted mb-0">
                            {{ $t('total_active_hour') }}
                        </p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-3 mb-lg-0">
                    <div class="default-base-color text-center rounded p-3">
                        <h5 title="HH:MM:SS" :class="balanceClass">{{ summaries.balance || '00:00:00' }}</h5>
                        <h6 :title="$t('in_hours')" :class="balanceClass">
                            ({{ workedMinutePercentage(summaries.balance) }} {{ $t('hour_short_form') }})</h6>
                        <p class="mb-0">{{ $t('balance') }} ({{ summaries.balance_behavior || 'Lack' }})</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="default-base-color text-center rounded p-4">
                        <h5 class="text-danger">{{ summaries.total_work || 0 }}</h5>
                        <p class="text-muted mb-0">
                            {{ $t('total_work_days') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getSecondFromTime} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name : "AppHotelSummary",
    props: {
        room_type: Array,
        summaries: {
            default: function () {
                return {};
            }
        },
    },
    computed: {
        balanceClass() {
            if (!this.summaries.balance) {
                return 'text-danger';
            }
            return this.summaries.balance.includes('-') ? 'text-danger' : 'text-success';
        }
    },
    methods : {
        workedMinutePercentage(time) {
            // console.log(time,getSecondFromTime(time))
            const sec = getSecondFromTime(time);
            return (sec / 3600).toFixed(2);
        },
    }
}
</script>
