<template>
    <modal id="hotel-achievement-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('achievement')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/achievement`'
            @submit.prevent="submitData"
        >
            <div class="form-group">
                <label>{{ $t('document') }}</label>
                <app-input
                    type="dropzone"
                    v-model="files"
                    :error-message="$errorMessage(errors, 'files.0')"
                />
            </div>
            <app-form-group-selectable
                v-if="!hotel_id"
                type="select"
                :label="$t('hotel')"
                list-value-field="name"
                v-model="formData.hotel_id"
                :required="true"
                :chooseLabel="$t('hotel')"
                :error-message="$errorMessage(errors, 'hotel_id')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
            />
            <app-form-group-selectable
                type="select"
                :label="$t('floor')"
                list-value-field="floor"
                list-value-id="floor"
                v-model="formData.floor"
                :required="true"
                :chooseLabel="$t('floor')"
                :error-message="$errorMessage(errors, 'floor')"
                :fetch-url="room_url"
            />
            <app-form-group
                type="date"
                :label="$t('date')"
                v-model="formData.date"
                :placeholder="formData.date ? $placeholder('date') : this.$t('not_added_yet')"
                :error-message="$errorMessage(errors, 'date')"
            />
            <app-form-group
                type="textarea"
                v-model="formData.remark"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                :required="false"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formatDateForServer} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "HotelAchievementCreateModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel_id: {
            default: null
        }
    },
    data() {
        const form = {}

        if (this.hotel_id) {
            form.hotel_id = this.hotel_id
        }

        return {
            TENANT_BASE_URL,
            files   : [],
            room_url: null,
            formData: form,
        }
    },
    watch  : {
        'formData.hotel_id': {
            handler  : function (hotel_id) {
                this.room_url = `${TENANT_BASE_URL}selectable/hotel/room?group=floor&hotel_id=${typeof hotel_id !== "undefined" ? hotel_id : 0}`
            },
            immediate: true
        }
    },
    methods: {
        submitData() {
            this.loading       = true
            this.formData.date = this.formData.date ? formatDateForServer(this.formData.date) : '';

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            this.files.forEach(file => formData.append('files[]', file))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            if (this.hotel_id) {
                formData.append('hotel_id', this.hotel_id);
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#hotel-achievement-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'hotel-achievement-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>