<template>
    <modal id="member-modal"
        v-model="showModal"
        :title="$t('move_member')"
        @submit="submitData" :loading="loading"
        :preloader="preloader">

        <form ref="form" :data-url='`${TENANT_BASE_URL}app/hotel/member/${member_id}/move`' @submit.prevent="submitData">
            <app-form-group-selectable
                type="select"
                :label="$t('hotel')"
                list-value-field="name"
                :chooseLabel="$t('hotel')"
                v-model="formData.new_hotel"
                :required="true"
                :error-message="$errorMessage(errors, 'new_hotel')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "MemberCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel    : Object,
        member_id: null,
    },
    data() {
        return {
            TENANT_BASE_URL,
            formData: {},
        }
    },
    methods: {
        submitData() {
            this.loading = true

            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#member-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'member-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

