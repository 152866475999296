<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('claim') + (hotel.name ? ' - ' + hotel.name : '')">
            <a :href="urlGenerator('/hotel/claim-type')" v-if="$can('view_hotel_claim_type')"
               class="btn btn-success btn-with-shadow mr-1">
                <app-icon name="list" class="size-20 mr-2"/>
                {{ $t('claim_type') }}
            </a>
            <app-default-button
                :title="$fieldTitle('add', 'type', true)"
                v-if="$can('create_hotel_claim_type')"
                @click="openModalType()"
            />
            <app-default-button
                :title="$fieldTitle('add', 'claim', true)"
                v-if="$can('create_hotel_claim')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="hotel-claim-table"
            :options="options"
            @action="triggerActions"
            @getFilteredValues="getFilterValues"
        />

        <app-hotel-claim-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            :hotel_id="hotel ? hotel.id : null"
            @close="isModalActive = false"
        />

        <app-hotel-claim-type-modal
            v-if="isModalTypeActive"
            v-model="isModalTypeActive"
            :selected-url="selectedUrl"
            @close="isModalTypeActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('hotel-claim-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import HotelClaimMixins from "../../../Mixins/HotelClaimMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "AppHotelClaim",
    mixins: [HelperMixin, HotelClaimMixins, TENANT_BASE_URL],
    props : {
        hotel: {},
    },
    data() {
        return {
            isModalTypeActive: false,
            isModalActive    : false,
            selectedUrl      : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/hotel/claim/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
        openModalType() {
            this.isModalTypeActive = true;
            this.selectedUrl       = ''
        },
    }
}
</script>