import {axiosGet, urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";
import HelperMixin from "../../../common/Mixin/Global/HelperMixin";
import {HotelFilterMixin} from "./FilterMixin";

export default {
    mixins: [HelperMixin, DatatableHelperMixin, HotelFilterMixin],
    data() {
        return {
            urlGenerator,
            options: {
                name             : this.$t('manual'),
                url              : `${TENANT_BASE_URL}app/hotel/manual`,
                showHeader       : true,
                showCount        : true,
                showClearFilter  : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                cardViewComponent: 'app-hotel-manual-card-view',
                columns          : [
                    {
                        title    : this.$t('manual'),
                        type     : 'custom-html',
                        key      : 'files',
                        width    : 100,
                        isVisible: true,
                        modifier : files => files.length > 0 ? `<img src="${urlGenerator(files[0])}" height="80px" alt="">` : ''
                    },
                    {
                        title    : this.$t('title'),
                        type     : 'text',
                        key      : 'title',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('publish_date'),
                        type     : 'custom-html',
                        key      : 'publish_date',
                        isVisible: true,
                        modifier : publish_date => formatDateToLocal(publish_date)
                    },
                    {
                        title    : this.$t('end_date'),
                        type     : 'custom-html',
                        key      : 'end_date',
                        isVisible: true,
                        modifier : end_date => formatDateToLocal(end_date)
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('hotel'),
                        type          : "multi-select-filter",
                        key           : "hotels",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_hotel_list')
                    },
                    {
                        title : this.$t('created'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : 'dropdown',
                actions          : [
                    {
                        title   : this.$t('edit'),
                        icon    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_manual')
                    },
                    {
                        title    : this.$t('delete'),
                        icon     : 'trash-2',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/manual/`,
                        name     : 'delete',
                        modifier : row => this.$can('delete_hotel_manual')
                    }
                ],
            }
        }
    },
    methods: {
        getFilterValues(value) {

        },
    },
}