<template>
    <modal id="day-shift-modal"
           v-model="showModal"
           :title="generateModalTitle('day_shift')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : DAY_SHIFT'
            @submit.prevent="submitData"
        >
            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>

            <div class="row">
                <div class="col-md-6">
                    <app-form-group-selectable
                        :label="$t('break_time')"
                        type="search-select"
                        v-model="formData.break_time_id"
                        list-value-field="name"
                        :placeholder="$t('break_time')"
                        :error-message="$errorMessage(errors, 'break_time_id')"
                        :fetch-url="`${apiUrl.BREAK_TIME}?get-all=1`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('color')"
                        type="color"
                        :placeholder="$placeholder('color')"
                        v-model="formData.color"
                        :required="true"
                        :error-message="$errorMessage(errors, 'color')">
                    </app-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $fieldTitle('start_at', '') }}</label>
                        <app-input
                            :id="'start_at'"
                            type="time"
                            v-model="formData.start_at"
                            :placeholder="$placeholder('start_at', '')"
                            name="start_at"
                            :error-message="$errorMessage(errors, 'start_at')"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $fieldTitle('end_at', '') }}</label>
                        <app-input
                            :id="'end-at'"
                            type="time"
                            v-model="formData.end_at"
                            :placeholder="$placeholder('end_at', '')"
                            :error-message="$errorMessage(errors, 'end_at')"
                        />
                    </div>
                </div>
            </div>

            <app-form-group
                type="textarea"
                :label="$t('description')"
                :placeholder="$textAreaPlaceHolder('description')"
                v-model="formData.description"
                :required="false"
                :text-area-rows="4"
                :error-message="$errorMessage(errors, 'description')">
            </app-form-group>

        </form>
    </modal>
</template>

<script>
    import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
    import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
    import {DAY_SHIFT, SELECTABLE_DEPARTMENT} from "../../../../Config/ApiUrl";
    import { formatTime, formatUtcToLocal } from "../../../../../common/Helper/Support/DateTimeHelper";

    export default {
        name: "DayShiftCreateEditModal",
        mixins: [FormHelperMixins, ModalMixin],
        data() {
            return {
                formData: {},
                DAY_SHIFT,
                SELECTABLE_DEPARTMENT
            }
        },
        methods: {
            afterSuccess({data}) {
                this.formData = {};
                $('#day-shift-modal').modal('hide');
                this.$emit('input', false);
                this.toastAndReload(data.message, 'day-shift-table');
            },
            afterSuccessFromGetEditData(response) {
                this.preloader = false;
                this.formData = response.data;
                // this.formData.start_at = formatUtcToLocal(this.formData.start_at);
                this.formData.start_at = formatTime(this.formData.start_at);
                // this.formData.end_at = formatUtcToLocal(this.formData.end_at);
                this.formData.end_at = formatTime(this.formData.end_at);
            },
        },
    }
</script>

