<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('work_shift') + ' - ' + hotel.name"/>

        <app-filter-with-search
            :options="options"
            :hotel="hotel"
            @filteredValue="setFilterValues"
            @searchValue="setSearchValue"
        />

        <div class="card card-with-shadow border-0" style="min-height: 400px;">
            <div class="card-body">
                <div class="summery-calendar">
                    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between mb-primary">
                        <app-month-calendar :show-date-range-calendar="true"/>
                        <app-period-calendar/>
                    </div>

                    <app-overlay-loader v-if="loading"/>

                    <div class="d-flex" v-else-if="$optional(attendances, 'data', 'length')">
                        <div class="profile-column">
                            <div class="column-header">{{ $t('profile') }}</div>
                            <template v-for="employee in attendances.data">
                                <div class="profile-box" style="padding: 10px;height: 65px">
                                    <h6 class="primary-text-color">{{ employee.full_name }}</h6>
                                    <p class="text-muted mb-0 font-size-90">
                                        {{ $optional(employee, 'department', 'name') }}</p>
                                </div>
                            </template>
                        </div>
                        <div class="total-hour-column">
                            <div class="column-header">ID</div>
                            <template v-for="employee in attendances.data">
                                <div class="column-content" style="height: 65px">
                                    <span style="margin: auto;padding: 10px 15px;"
                                          v-text="employee.profile.employee_id"/>
                                </div>
                            </template>
                        </div>
                        <div class="date-column custom-scrollbar custom-scrollbar-with-mouse-move"
                             style="margin-bottom: -17px">
                            <div class="d-flex">
                                <div class="date-hour-wrapper border-0" v-for="range in ranges">
                                    <div class="date" style="min-height: 50px">{{ formatTitleRangeDate(range) }}</div>
                                    <div
                                        class="date-hour-wrapper border-0"
                                        v-for="employee in attendances.data"
                                        v-html="formatEmployeeAttendances(employee, range)"
                                        style="min-height: 65px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-data-block v-else :message="$t('nothing_to_show_here')"/>
                    <app-pagination
                        v-if="attendances.total && attendances.last_page > 1"
                        :rowLimit="attendances.per_page"
                        :totalRow="attendances.total"
                        @submit="setPagination"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppEmployeeProfileCard from "../../Attendance/Component/AppEmployeeProfileCard";
import AppEmployeeWorkHourType from "../../Attendance/Component/AppEmployeeWorkHourType";
import AppEmployeeWorkingDate from "../../Attendance/Component/AppEmployeeWorkingDate";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";
import {ATTENDANCES} from "../../../../Config/ApiUrl";
import {
    DepartmentFilterMixin,
    DesignationFilterMixin,
    EmploymentStatusFilterMixin,
    WorkingShiftFilterMixin
} from '../../../Mixins/FilterMixin';
import AppAttendanceTopButtons from "../../Attendance/Component/AppAttendanceTopButtons";
import moment from 'moment'
import {
    convertSecondToHourMinutes,
    differentInTime,
    serverDateFormat,
    serverDateTimeFormat
} from "../../../../../common/Helper/Support/DateTimeHelper";
import optional from "../../../../../common/Helper/Support/Optional";
import MemoizeMixins from "../../../../../common/Helper/Support/MemoizeMixins";
import AttendanceHelperMixin from "../../../Mixins/AttendanceHelperMixin";
import TemplateHelper from "../../../../../core/helpers/template-helper/TemplateHelper";
import AttendanceSummaryDetailsMixin from "../../../Mixins/AttendanceSummaryDetailsMixin";

export default {
    name      : "HotelWorkShift",
    props     : {
        hotel: {
            type    : Object,
            required: true
        },
    },
    components: {
        AppEmployeeWorkingDate,
        AppEmployeeWorkHourType,
        AppEmployeeProfileCard,
        AppAttendanceTopButtons
    },
    mixins    : [
        DepartmentFilterMixin,
        DesignationFilterMixin,
        EmploymentStatusFilterMixin,
        WorkingShiftFilterMixin,
        MemoizeMixins,
        AttendanceHelperMixin,
        AttendanceSummaryDetailsMixin
    ],
    methods   : {
        getAttendances(queryString) {
            this.loading = true;
            axiosGet(`${ATTENDANCES}/details?hotel_id=${this.hotel.id}&${queryString}`).then(response => {
                this.attendances         = response.data.attendances;
                this.ranges              = response.data.range.map(range => moment(range, serverDateTimeFormat));
                this.defaultWorkingShift = response.data.default;
            }).finally(() => {
                this.loading = false;
                TemplateHelper.moveHorizontalScrollbarWithMouse();
            })
        },
        formatEmployeeAttendances(employee, date) {
            const attendance       = this.getAttendance(employee, date);
            const workShiftDetails = this.getDetails(
                this.getEmployeeWorkingShiftFromDate(
                    employee.working_shifts,
                    date,
                    this.defaultWorkingShift
                ),
                date,
            );

            const holiday   = this.isHoliday(date, employee.holidays);
            const scheduled = holiday ? false : this.getScheduled(workShiftDetails);

            if (!attendance) {
                return `<div class="date-hour" style="white-space: nowrap;padding: 0 15px;">${scheduled || 'X'}</div>`
            }

            if (holiday) {
                return `<div class="date-hour" style="white-space: nowrap;padding: 0 15px;">X</div>`
            }

            return `<div class="date-hour" style="white-space: nowrap;padding: 0 15px;">${scheduled || 'X'}</div>`
        },
        getAttendance(employee, date) {
            return this.memoize(`attendance-${employee.id}-${date.format(serverDateFormat)}`, () => {
                return employee.attendances.find(attendance => {
                    return attendance.in_date === date.format(serverDateFormat);
                });
            });
        },
        getScheduled(details) {
            if (!details) {
                return false;
            }
            return this.memoize(`work-shift-details-${details.id}`, () => {
                if (parseInt(optional(details, 'is_weekend'))) {
                    return false;
                }
                return details.start_at + ' - ' + details.end_at;
            });
        },
        isHoliday(date, holidays) {
            return holidays.find(holiday => date.isSame(holiday, 'day'))
        },
        formatTitleRangeDate(range) {
            let date = range.locale(window.appLanguage)
            return date.format('MMM D')
        }
    },
    watch     : {
        queryString: {
            handler  : function (queryString) {
                if (!queryString) {
                    return;
                }
                this.getAttendances(queryString)
            },
            immediate: true
        }
    }
}
</script>