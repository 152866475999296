<template>
    <div class="card border-0 h-100 employee-preview-card dropdown">
        <div class="bg" :style="{'background': status_bg}"></div>
        <div class="card-body position-relative p-0" data-toggle="dropdown"
             :style="{'border-top': '3px solid ' + status_bg}">
            <div class="content p-2">
                <div class="d-flex align-items-center justify-content-between">
                    <span>{{ room.number }}</span>
                </div>
                <div class="text-center text-nowrap">{{ staying_text }}</div>
                <div class="text-center text-nowrap">{{ cleaning_text }}</div>
            </div>
        </div>
        <div class="text-center position-relative mb-1" style="z-index: 6;">
            <input
                type="checkbox"
                :value="room.id"
                :checked="checked && checked.includes(room.id)"
                @change="check(room.id)"
            >
        </div>
        <div class="dropdown-menu dropdown-menu-right py-2 mt-1">
            <a class="dropdown-item px-4 py-2" href="#" v-for="(action,index) in actions" :key="index"
               v-if="checkIfVisible(action, room)" @click.prevent="callAction(action)">
                {{ action.title }}
            </a>
        </div>
    </div>
</template>

<style scoped>
.bg {
    filter       : alpha(opacity=3);
    /* IE */
    -moz-opacity : 0.03;
    /* Mozilla */
    opacity      : 0.03;
    /* CSS3 */
    position     : absolute;
    top          : 0;
    left         : 0;
    height       : 100%;
    width        : 100%;
    z-index      : 1;
}

.content {
    position : relative;
    z-index  : 8;

    span {
        font-size : 0.835rem;
    }
}

.dropdown .dropdown-menu {
    transform  : none !important;
    overflow-x : hidden;
}
</style>

<script>
import CoreLibrary from "../../../../../core/helpers/CoreLibrary";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";

export default {
    name   : "StatusPreviewCard",
    extends: CoreLibrary,
    mixins : [FormHelperMixins],
    props  : {
        room      : {
            type    : Object,
            required: true
        },
        checked   : {
            type   : Array,
            default: null,
        },
        actions   : {
            type   : Array,
            require: true
        },
        showAction: {
            type   : Boolean,
            default: true
        },
        id        : {
            type   : String,
            require: true
        }
    },
    data() {
        let staying_text  = '-'
        let cleaning_text = '-'
        let status_bg     = '#000'

        if (this.room.status) {
            staying_text  = this.room.status.staying?.name || '-'
            cleaning_text = this.room.status.cleaning?.name || '-'
            status_bg     = this.room.status.color || '#000'
        }

        return {
            loading      : false,
            staying_text : staying_text,
            cleaning_text: cleaning_text,
            status_bg    : status_bg,
        }
    },
    methods: {
        callAction(action) {
            this.$emit('action-' + this.id, this.room, action, true)
        },
        checkIfVisible(action, room) {
            if (this.isFunction(action.modifier)) {
                return action.modifier(room);
            }
            return true;
        },
        check(id) {
            this.$emit('checked', id)
        },
    },
}
</script>