<template>
    <div class="dropdown">
        <input :type="data.type"
               :name="name"
               :id="inputFieldId"
               :required="data.required"
               :class="'form-control ' + data.inputClass"
               :maxlength="data.maxLength"
               :minlength="data.minlength"
               :alphanumeric="data.alphanumeric"
               :placeholder="data.placeholder"
               :readonly="data.readOnly"
               :disabled="data.disabled"
               v-bind:value="value"
               v-on="listeners"
               :autocomplete="data.autocomplete"
               @change="search"
               @click="show = true"
               @focusin="show = true"
               @keydown="show = false"
        />
        <div v-if="data.options && data.options.length > 0" :class="show ? 'show' : ''" class="dropdown-menu w-100">
            <a v-for="text in data.options" class="dropdown-item" href="#"
               @click.prevent="updateValue(text)"
               v-text="text"/>
        </div>
    </div>
</template>

<script>
import {InputMixin} from './mixin/InputMixin.js';

export default {
    name  : "TextInput",
    mixins: [InputMixin],
    data() {
        return {
            show   : false,
            current: null,
        }
    },
    methods: {
        search(event) {
            this.current = event.target.value;
        },
        updateValue(value) {
            this.show       = false;
            this.fieldValue = value;
            this.$emit('input', this.fieldValue);
        },
    }
}
</script>

