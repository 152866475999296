<template>
    <div>
        <app-input
            type="switch"
            v-model="switchValue"
            @change="updateConfirmed($event.target.value)"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {axiosPatch} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "HouseKeepingConfirmed",
    mixins: [FormHelperMixins],
    props : {
        value  : {},
        tableId: {},
        rowData: {},
    },
    data() {
        return {
            switchValue: parseInt(this.value),
        }
    },
    methods: {
        updateConfirmed(value) {
            this.rowData.confirmed = value ? 1 : 0;

            axiosPatch(`${TENANT_BASE_URL}app/hotel/house-keeping/${this.rowData.id}`, this.rowData).then((res) => {
                this.$toastr.s(res.data.message)
            }).catch((error) => {
                this.$toastr.e(error.response.data.message)
                this.switchValue = parseInt(this.value);
            })
        },
    }
}
</script>