<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('all_day_shift')">
            <app-default-button
                :title="$fieldTitle('add', 'day_shift', true)"
                v-if="$can('create_designations')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="day-shift-table"
            :options="options"
            @action="triggerActions"
        />

        <app-day-shift-modal
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('day-shift-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
    import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
    import DayShiftMixin from "../../../Mixins/DayShiftMixin";
    import {DAY_SHIFT} from "../../../../Config/ApiUrl";

    export default {
        name: "AppDayShift",
        mixins: [HelperMixin, DayShiftMixin],
        data() {
            return {
                isModalActive: false,
                selectedUrl: '',
            }
        },

        methods: {
            triggerActions(row, action, active) {
                if (action.title === this.$t('edit')) {
                    this.selectedUrl = `${DAY_SHIFT}/${row.id}`;
                    this.isModalActive = true;
                } else {
                    this.getAction(row, action, active)
                }
            },

            openModal() {
                this.isModalActive = true;
                this.selectedUrl = ''
            },
        },
    }
</script>
