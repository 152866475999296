<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('hotel')">
            <app-default-button
                :title="$fieldTitle('add', 'hotel', true)"
                v-if="$can('create_hotel')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="hotel-table"
            :options="options"
            :card-view="true"
            @action="triggerActions"
        />

        <app-hotel-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-hotel-achievement-create
            v-if="isModalAchievementActive"
            v-model="isModalAchievementActive"
            :hotel_id="hotel_id"
            @close="isModalAchievementActive = false"
        />

        <app-hotel-lost-found-create
            v-if="isModalLostAndFoundActive"
            v-model="isModalLostAndFoundActive"
            :hotel_id="hotel_id"
            @close="isModalLostAndFoundActive = false"
        />

        <app-hotel-checking-create
            v-if="isModalCheckingActive"
            v-model="isModalCheckingActive"
            :hotel_id="hotel_id"
            @close="isModalCheckingActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('hotel-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelMixins from "../../../Mixins/HotelMixins";
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "AppHotelHotel",
    mixins: [HelperMixin, HotelMixins],
    data() {
        return {
            TENANT_BASE_URL,
            isModalActive            : false,
            isModalAchievementActive : false,
            isModalLostAndFoundActive: false,
            isModalCheckingActive    : false,
            selectedUrl              : '',
            hotel_id                 : null,
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}hotel/list/${row.id}/edit`))
            } else if (action.title === this.$t('house_keeping')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}hotel/house-keeping?hotel_id=${row.id}`))
            } else if (action.title === this.$t('status')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}hotel/list/${row.id}/status`))
            } else if (action.title === this.$t('work_shift')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}hotel/list/${row.id}/work-shift`))
            } else if (action.title === this.$t('report')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}hotel/list/${row.id}/report`))
            } else if (action.title === this.$t('claim')) {
                window.location.replace(urlGenerator(`/${TENANT_BASE_URL}hotel/claim?hotel_id=${row.id}`))
            } else if (action.title === this.$t('share')) {
                window.open(urlGenerator(`/${TENANT_BASE_URL}hotel-share/${row.id}`), '_blank')
            } else if (action.title === this.$t('achievement')) {
                this.isModalAchievementActive = true
                this.hotel_id                 = row.id
            } else if (action.title === this.$t('lost_and_found')) {
                this.isModalLostAndFoundActive = true
                this.hotel_id                  = row.id
            } else if (action.title === this.$t('checking')) {
                this.isModalCheckingActive = true
                this.hotel_id              = row.id
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>