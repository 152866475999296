<template>
    <div>
        <app-overlay-loader v-if="preloader"/>
        <form v-else
              ref="form"
              method="post"
              :data-url="`${TENANT_BASE_URL}general/settings/firebase`"
              @submit.prevent="submitData"
              enctype="multipart/form-data"
              class="mb-0"
              :class="{'loading-opacity': preloader}">

            <!-- Firebase Settings -->
            <fieldset class="form-group mb-5">
                <div class="row">
                    <legend class="col-12 col-form-label text-primary pt-0 mb-3">
                        {{ $t('firebase_setting') }}
                    </legend>
                    <div class="col-md-12">
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('auth_domain') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_auth_domain"
                                    :error-message="$errorMessage(errors, 'firebase_auth_domain')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('database_url') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_database_url"
                                    :error-message="$errorMessage(errors, 'firebase_database_url')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('project_id') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_project_id"
                                    :error-message="$errorMessage(errors, 'firebase_project_id')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('storage_bucket') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_storage_bucket"
                                    :error-message="$errorMessage(errors, 'firebase_storage_bucket')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('sender_id') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_messaging_sender_id"
                                    :error-message="$errorMessage(errors, 'firebase_messaging_sender_id')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label for="appSettingsFirebaseService" class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('firebase_service') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-form-group
                                    id="appSettingsFirebaseService"
                                    type="file"
                                    v-model="settings.firebase_service"
                                    :file-label="$placeholder('firebase_service', '')"
                                    :error-message="$errorMessage(errors, 'firebase_service')"
                                    accept="application/json"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="form-group mb-5">
                <div class="row">
                    <legend class="col-12 col-form-label text-primary pt-0 mb-3">
                        {{ $t('ios') }}
                    </legend>
                    <div class="col-md-12">
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('app_id') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_ios_app_id"
                                    :error-message="$errorMessage(errors, 'firebase_ios_app_id')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('api_key') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_ios_api_key"
                                    :error-message="$errorMessage(errors, 'firebase_ios_api_key')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('bundle_id') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_ios_bundle_id"
                                    :error-message="$errorMessage(errors, 'firebase_ios_bundle_id')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="form-group mb-5">
                <div class="row">
                    <legend class="col-12 col-form-label text-primary pt-0 mb-3">
                        {{ $t('android') }}
                    </legend>
                    <div class="col-md-12">
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('app_id') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_android_app_id"
                                    :error-message="$errorMessage(errors, 'firebase_android_app_id')"
                                />
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <label class="col-lg-3 col-xl-3 mb-lg-0">
                                {{ $t('api_key') }}
                            </label>
                            <div class="col-lg-8 col-xl-8">
                                <app-input
                                    type="text"
                                    v-model="settings.firebase_android_api_key"
                                    :error-message="$errorMessage(errors, 'firebase_android_api_key')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <div class="mt-5">
                <app-submit-button
                    :loading="loading"
                    @click="submitData"
                />
            </div>
        </form>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {formDataAssigner} from '../../../../../common/Helper/Support/FormHelper'
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins"

export default {
    name  : "TenantFirebaseSetting",
    mixins: [FormHelperMixins],
    data() {
        return {
            TENANT_BASE_URL,
            settings: {},
        }
    },
    methods : {
        beforeSubmit() {
            this.preloader = true;
            this.loading   = true;
            this.message   = '';
            this.errors    = {};
        },
        submitData() {
            let formData = formDataAssigner(new FormData, this.settings);
            return this.save(formData);
        },
        afterSuccess({data}) {
            this.toastAndReload(data.message);
            location.reload();
        },
        ...mapActions([
            'getConfig',
            'getTenantSettings'
        ]),
    },
    computed: {
        ...mapState({
            languages      : state => state.support.languages,
            generalSettings: state => state.tenant_settings.tenantSettings
        }),
        tenant() {
            return window.tenant;
        },
    },
    created() {
        this.getConfig();
        this.getTenantSettings();
        this.preloader = true;
    },
    watch: {
        generalSettings: {
            handler: function (settings) {
                if (!Object.keys(this.settings).length) {
                    for (const key in settings) {
                        if (![
                            'firebase_ios_app_id',
                            'firebase_ios_api_key',
                            'firebase_ios_bundle_id',
                            'firebase_android_app_id',
                            'firebase_android_api_key',
                            'firebase_auth_domain',
                            'firebase_database_url',
                            'firebase_project_id',
                            'firebase_storage_bucket',
                            'firebase_messaging_sender_id',
                        ].includes(key)) {
                            continue
                        }
                        this.settings[key] = settings[key];
                    }
                    this.preloader = false;
                }
            },
            deep   : true
        }
    },

}
</script>
