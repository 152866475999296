import {axiosGet} from "../../../common/Helper/AxiosHelper";
import {TENANT_HOTELS_URL} from "../../../common/Config/apiUrl";

const state = {
    selectable: []
}

const actions = {
    getSelectableHotels({commit}) {
        axiosGet(TENANT_HOTELS_URL).then(({data}) => {
            commit('HOTEL_LIST', data)
        });
    }
}

const mutations = {
    HOTEL_LIST(state, data) {
        state.selectable = data;
    }
}

export default {
    state,
    actions,
    mutations
}
