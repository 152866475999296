<template>
    <modal id="contract-modal"
           v-model="showModal"
           :title="generateModalTitle('contract')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : CONTRACT'
            @submit.prevent="submitData"
        >
            <app-form-group-selectable
                :label="$t('template')"
                type="search-select"
                v-model="formData.template_id"
                list-value-field="name"
                :placeholder="$t('template')"
                :error-message="$errorMessage(errors, 'template_id')"
                :fetch-url="`${apiUrl.TEMPLATE}?get-all=1`"
            />

            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('contract_no')"
                        :placeholder="$placeholder('contract_no')"
                        v-model="formData.contract_no"
                        :required="true"
                        :error-message="$errorMessage(errors, 'contract_no')">
                    </app-form-group>
                </div>

                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('date')"
                        :placeholder="$placeholder('date')"
                        v-model="formData.date"
                        :required="true"
                        :error-message="$errorMessage(errors, 'date')">
                    </app-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('first_name')"
                        :placeholder="$placeholder('first_name')"
                        v-model="formData.first_name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'first_name')">
                    </app-form-group>
                </div>

                <div class="col-md-6">
                    <app-form-group
                        :label="$t('last_name')"
                        :placeholder="$placeholder('last_name')"
                        v-model="formData.last_name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'last_name')">
                    </app-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('birthday')"
                        :placeholder="$placeholder('birthday')"
                        v-model="formData.birthday"
                        :required="true"
                        :error-message="$errorMessage(errors, 'birthday')">
                    </app-form-group>
               </div>

                <div class="col-md-6">
                    <app-form-group
                        :label="$t('email')"
                        :placeholder="$placeholder('email')"
                        v-model="formData.email"
                        :required="true"
                        :error-message="$errorMessage(errors, 'email')">
                    </app-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('mobile')"
                        :placeholder="$placeholder('mobile')"
                        v-model="formData.mobile"
                        :required="true"
                        :error-message="$errorMessage(errors, 'mobile')">
                    </app-form-group>
                </div>

                <div class="col-md-6">
                    <app-form-group
                        :label="$t('address')"
                        :placeholder="$placeholder('address')"
                        v-model="formData.address"
                        :required="true"
                        :error-message="$errorMessage(errors, 'address')">
                    </app-form-group>
                </div>
            </div>

            <div class="form-group">
                <label for="employee_image">{{ $t('employee_image') }}<br>
                    <small class="text-muted font-italic">
                        {{ $t('recommended_employee_image_size') }}
                    </small>
                </label>

                <app-input
                    id="employee_image"
                    type="custom-file-upload"
                    :generate-file-url="false"
                    v-model="formData.employee_image"
                    :label="$t('employee_image')"
                    :error-message="$errorMessage(errors, 'employee_image')"
                />
            </div>

            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :text-area-rows="3"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>

            <div class="form-group">
                <label>{{ $t('signature') }}</label>
                <div id="signature-pad" class="signature-pad">
                    <div class="signature-pad--body" style="border: solid 1px #f0f2f5; border-radius: 5px">
                        <canvas></canvas>
                    </div>
                    <div class="signature-pad--footer">
                        <div class="signature-pad--actions mt-2">
                            <button type="button" class="btn btn-primary btn-sm clear" data-action="clear" @click="this.signaturePadClear">{{ $t('clear') }}</button>
                            <button type="button" class="btn btn-primary btn-sm" data-action="undo" @click="this.signaturePadUndo">{{ $t('undo') }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import { CONTRACT } from "../../../../Config/ApiUrl";
import SignaturePad from "signature_pad";
import moment from "moment";

export default {
        name: "ContractCreateEditModal",
        mixins: [FormHelperMixins, ModalMixin],
        data() {
            return {
                formData: {},
                CONTRACT,
                signaturePad: null,
            }
        },
        methods: {
            afterSuccess({data}) {
                this.formData = {};
                $('#contract-modal').modal('hide');
                this.$emit('input', false);
                this.toastAndReload(data.message, 'contract-table');
            },
            afterSuccessFromGetEditData(response) {
                this.preloader = false;
                this.formData = response.data;
                setTimeout(() => {
                    this.initSignaturePad(this.formData.signature);
                }, 200);
            },
            signaturePadClear() {
                this.signaturePad.clear();
            },
            signaturePadUndo() {
                const data = this.signaturePad.toData();
                if (data) {
                    data.pop(); // remove the last dot or line
                    this.signaturePad.fromData(data);
                }
            },
            beforeSubmit() {
                this.formData.birthday = this.formData.birthday ? moment(this.formData.birthday).format('YYYY-MM-DD') : null;
                this.formData.date = this.formData.date ? moment(this.formData.date).format('YYYY-MM-DD') : null;
                this.formData.signature = this.signaturePad.toDataURL();
            },
            initSignaturePad(signature = null) {
                const wrapper = document.getElementById("signature-pad");
                if (!wrapper) {
                    return;
                }

                const canvas = wrapper.querySelector("canvas");
                this.signaturePad = new SignaturePad(canvas, {});
                if (signature) {
                    this.signaturePad.fromDataURL(signature);
                }
            }
        },
        mounted() {
            this.initSignaturePad();
        }
}
</script>

