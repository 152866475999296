<template>
    <modal id="support-modal"
        v-model="showModal"
        :title="generateModalTitle('support')"
        @submit="submitData" :loading="loading"
        :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/support`'
            @submit.prevent="submitData"
        >
            <app-form-group-selectable
                type="select"
                :label="$t('type')"
                list-value-field="name"
                v-model="formData.type_id"
                :required="true"
                :chooseLabel="$t('type')"
                :error-message="$errorMessage(errors, 'type_id')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/support`"
            />
            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>
            <app-form-group
                :label="$t('value')"
                :placeholder="$placeholder('value')"
                v-model="formData.value"
                :required="true"
                :error-message="$errorMessage(errors, 'value')">
            </app-form-group>
            <app-form-group
                :label="$t('qr_code')"
                type="file"
                v-model="formData.qr_code"
                :placeholder="$placeholder('qr_code', '')"
                :file-label="$placeholder('qr_code', '')"
                :error-message="$errorMessage(errors, 'qr_code')"
            >
                <template slot="suggestion">
                    <small class="text-muted font-italic mt-3 d-inline-block">
                        {{ $t('document_recommendation') }}
                    </small>
                </template>
            </app-form-group>
            <app-form-group
                type="textarea"
                label-alignment="top"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "SupportCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            support: Object,
        },
    },
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#support-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'support-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

