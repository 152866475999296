import SelectAbleUserFilterMixin from "./SelectAbleUserFilterMixin";
import NavFilterMixin from "./NavFilterMixin";
import {UserFilterMixin} from "./FilterMixin";
import {axiosGet} from "../../../common/Helper/AxiosHelper";

export default {
    mixins: [SelectAbleUserFilterMixin, NavFilterMixin, UserFilterMixin],
    data() {
        return {
            loading            : false,
            options            : {
                showSearch: false,
                showFilter: true,
                filters   : [
                    {
                        title         : this.$t('department'),
                        type          : 'multi-select-filter',
                        key           : 'departments',
                        listValueField: 'name',
                        option        : [],
                        permission    : !!this.$can('view_departments')
                    },
                    {
                        title         : this.$t('users'),
                        type          : "multi-select-filter",
                        key           : "users",
                        option        : [],
                        listValueField: 'full_name',
                        permission    : !!this.$can('view_all_attendance') && !!this.$can('view_users')
                    },
                    {
                        title         : this.$t('employment_status'),
                        type          : "multi-select-filter",
                        key           : "employment_statuses",
                        option        : [],
                        listValueField: 'name',
                        permission    : !!this.$can('view_employment_statuses')
                    },
                ]

            },
            attendances        : [],
            ranges             : [],
            defaultWorkingShift: {},
            exportable         : true,
        }
    },
    computed: {
        queryString() {
            return this.$store.getters.getFilterUrls;
        },
        filterQuery() {
            return this.$store.getters.getFilters;
        },
    },
    methods : {
        getUsers() {
            const url = this.hotel ? this.apiUrl.TENANT_SELECTABLE_USER + '?hotel_id=' + this.hotel.id : this.apiUrl.TENANT_SELECTABLE_USER
            axiosGet(url).then(({data}) => {
                this.options.filters.find(fl => fl.key === 'users' && fl.type === 'multi-select-filter').option = data;
            })
        }
    },
}