<template>
    <div class="container-fluid p-0">
        <form @submit.prevent="save">

            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>

            <div class="row">
                <div class="col-md-6">
                    <app-form-group-selectable
                        :label="$t('number_type')"
                        type="search-select"
                        v-model="formData.number_type"
                        list-value-field="name"
                        :placeholder="$t('number_type')"
                        :error-message="$errorMessage(errors, 'number_type')"
                        :options="[
                            {name: $t('auto'), id: 1},
                            {name: $t('manual'), id: 2}
                        ]"
                    />
                </div>

                <div class="col-md-6">
                    <app-form-group
                        :label="$t('prefix')"
                        :placeholder="$placeholder('prefix')"
                        v-model="formData.prefix"
                        :required="true"
                        :error-message="$errorMessage(errors, 'prefix')">
                    </app-form-group>
                </div>
            </div>

            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :text-area-rows="3"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>

            <div class="form-group">
                <label>{{ $t('content') }}</label>
                <app-input
                    type="text-editor"
                    v-model="formData.content"
                    id="text-editor-for-template"
                    :placeholder="$textAreaPlaceHolder('content')"
                    row="5"
                    :error-message="$errorMessage(errors, 'content')"
                />
            </div>

            <div class="form-group text-center">
                <button
                    type="button"
                    class="btn btn-sm btn-primary px-3 py-1 ml-2 mt-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    v-for="item in markItems"
                    :title="item"
                    @click="addMark(item)"
                >
                    {{ item }}
                </button>
            </div>

            <div class="form-group mt-3">
                <app-submit-button @click="submitData" :loading="loading"/>
            </div>

        </form>
    </div>
</template>

<script>
import {FormMixin} from "../../../../../core/mixins/form/FormMixin";
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";
import {TEMPLATE} from "../../../../Config/ApiUrl";

export default {
    name: "AppDetailTemplateCardView",
    mixins: [FormMixin, FormHelperMixins],
    data() {
        return {
            formData: {},
            TEMPLATE,
            submit_url: `${TEMPLATE}/${this.props.template_id}`,
            markItems: [
                '{date}', '{first_name}', '{last_name}', '{birthday}',
                '{mobile}', '{email}', '{address}',
            ],
        }
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        props: {
            default: {}
        }
    },
    methods: {
        afterSuccess({data}) {
            this.scrollToTop();
            this.toastAndReload(data.message);
        },
        getDetailTemplate() {
            const url = `${TEMPLATE}/${this.props.template_id}`;
            axiosGet(url).then(({data}) => {
                this.formData = data;
            });
        },
        save() {
            this.submitFromFixin('patch', this.submit_url, this.formData);
        },
        addMark(item) {
            const editor = $("#text-editor-for-template");
            editor.summernote('editor.saveRange');
            editor.summernote('editor.restoreRange');
            editor.summernote('editor.focus');
            editor.summernote('editor.insertText', item);
        },
    },
    mounted() {
        this.getDetailTemplate();
    }
}
</script>
