<template>
    <modal id="file-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('file')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : DOCUMENT_FILE'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group
                        :label="$t('name')"
                        :placeholder="$placeholder('name')"
                        v-model="formData.name"
                        :required="true"
                        :error-message="$errorMessage(errors, 'name')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('type')"
                        list-value-field="name"
                        v-model="formData.type_id"
                        :required="true"
                        :chooseLabel="$t('type')"
                        :error-message="$errorMessage(errors, 'type_id')"
                        :fetch-url="SELECTABLE_DOCUMENT_TYPE"
                    />
                </div>
                <div class="col-md-12">
                    <app-form-group
                        :label="$t('document')"
                        type="file"
                        v-model="formData.file"
                        :required="true"
                        :placeholder="$placeholder('document', '')"
                        :file-label="$placeholder('document', '')"
                        :error-message="$errorMessage(errors, 'file')"
                    >
                        <template slot="suggestion">
                            <small class="text-muted font-italic mt-3 d-inline-block">
                                {{ $t('document_recommendation') }}
                            </small>
                        </template>
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <app-form-group-selectable
                        type="multi-select"
                        :label="$t('department')"
                        list-value-field="name"
                        v-model="formData.department_ids"
                        :chooseLabel="$t('department')"
                        :error-message="$errorMessage(errors, 'department_ids')"
                        :fetch-url="apiUrl.SELECTABLE_DEPARTMENT"
                    />
                </div>
                <div class="col-md-12">
                    <app-form-group-selectable
                        type="multi-select"
                        :label="$t('employee')"
                        v-model="formData.user_ids"
                        list-value-field="full_name"
                        :error-message="$errorMessage(errors, 'user_ids')"
                        :fetch-url="`${apiUrl.TENANT_SELECTABLE_DEPARTMENT_USERS}?without=admin&employee=only&with_auth=yes&departments=${departments}`"
                    />
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="text-editor-for-remark">{{ $t('short_description') }}</label>
                        <app-input
                            type="text-editor"
                            v-model="formData.description"
                            id="text-editor-for-description"
                            :placeholder="$textAreaPlaceHolder('short_description')"
                            :error-message="$errorMessage(errors, 'description')"
                            row="3"
                        />
                    </div>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        type="textarea"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :required="false"
                        :error-message="$errorMessage(errors, 'remark')"
                    />
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {DOCUMENT_FILE, SELECTABLE_DOCUMENT_TYPE} from "../../../../Config/ApiUrl";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "DocumentFileCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    data() {
        return {
            formData   : {},
            departments: 0,
            DOCUMENT_FILE,
            SELECTABLE_DOCUMENT_TYPE
        }
    },
    watch  : {
        'formData.department_ids': {
            handler  : function (ids) {
                this.departments = ids || 0
            },
            immediate: true
        }
    },
    methods: {
        submitData() {
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            formData.set('department_ids', JSON.stringify(this.formData.department_ids))
            formData.set('user_ids', JSON.stringify(this.formData.user_ids))

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#file-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'document-file-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

