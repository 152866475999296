<template>
    <div>
        <div class="mb-3 mt-5">
            <div class="row align-items-end">
                <div :class="is_dashboard ? 'col-md-10' : 'col-md-7'">
                    <div class="d-flex flex-wrap flex-md-nowrap">
                        <app-form-group-selectable
                            v-if="is_dashboard"
                            type="search-select"
                            :label="$t('hotel')"
                            list-value-field="name"
                            form-group-class="pr-2 w-100"
                            v-model="formData.hotel_id"
                            :chooseLabel="$t('hotel')"
                            :fetch-url="`${TENANT_BASE_URL}selectable/hotel/list`"
                        />
                        <app-form-group-selectable
                            v-if="is_dashboard"
                            type="search-select"
                            form-group-class="pr-2 w-100"
                            :label="$t('maid')"
                            list-value-field="employee_name"
                            v-model="formData.maid_id"
                            :chooseLabel="$t('maid')"
                            :fetch-url="memberUrl"
                        />
                        <app-form-group
                            type="select"
                            v-model="range"
                            list-value-field="name"
                            :label="$t('range')"
                            form-group-class="pr-2 w-100"
                            :list="[
                                {id: 'today', name: $t('today')},
                                {id: 'this_week', name: $t('this_week')},
                                {id: 'last_week', name: $t('last_week')},
                                {id: 'this_month', name: $t('this_month')},
                                {id: 'last_month', name: $t('last_month')},
                            ]"
                            :style="{width: '200px'}"
                        />
                        <app-form-group
                            type="date"
                            :label="$t('from_date')"
                            v-model="formData.from_date"
                            form-group-class="pr-2 w-100"
                        />
                        <app-form-group
                            type="date"
                            :label="$t('to_date')"
                            v-model="formData.to_date"
                            form-group-class="w-100"
                        />
                    </div>
                </div>
                <div v-if="!is_dashboard" class="col-md-3">
                    <app-form-group
                        type="password"
                        :label="$t('passcode')"
                        :placeholder="$t('passcode')"
                        v-model="formData.passcode"
                        :show-password="true"
                    />
                </div>
                <div class="col-md-2">
                    <div class="text-right form-group">
                        <button @click="submitClick" class="btn btn-primary">
                            {{ $t('search') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <h2 v-if="hotelName" class="text-center mb-3">{{ hotelName }}</h2>

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{'active': tab === 'summary'}" @click.prevent="tab = 'summary'">
                    {{ $t('summary') }}
                </a>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link" :class="{'active': tab === 'detail'}" @click.prevent="tab = 'detail'">
                    {{ $t('detail') }}
                </a>
            </li>
        </ul>

        <div v-if="tab === 'detail'" class="card border-0 bg-transparent">
            <div class="card-body p-0">
                <div class="datatable">
                    <app-overlay-loader v-if="reportLoading"/>
                    <div v-if="reportData && reportData.length > 0"
                         class="table-responsive custom-scrollbar table-view-responsive pt-3 mt-3">
                        <table class="table mb-0">
                            <thead>
                            <tr>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('date') }}</span></span>
                                </th>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('room') }}</span></span>
                                </th>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('type') }}</span></span>
                                </th>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('option_name') }}</span></span>
                                </th>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('quantity') }}</span></span>
                                </th>
                                <!--
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('contract_price') }}</span></span>
                                </th>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('selling_price') }}</span></span>
                                </th>
                                -->
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="row in reportData">
                                <template v-if="row.options && row.options.length > 1">
                                    <template v-for="(option, index) in row.options">
                                        <template v-if="index === 0">
                                            <tr>
                                                <td class="datatable-td">{{ formatDateToLocal(row.date) }}</td>
                                                <td class="datatable-td">{{ row.room ? row.room.number : '' }}</td>
                                                <td class="datatable-td">{{ row.room ? row.room.type.name : '' }}</td>
                                                <td class="datatable-td">{{ option.name || '' }}</td>
                                                <td class="datatable-td">{{ option.quantity || 0 }}</td>
                                                <!--
                                                <td class="datatable-td">{{ option.contract_price || '' }}</td>
                                                <td class="datatable-td">{{ option.selling_price || '' }}</td>
                                                -->
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td class="datatable-td"></td>
                                                <td class="datatable-td"></td>
                                                <td class="datatable-td"></td>
                                                <td class="datatable-td">{{ option.name || '' }}</td>
                                                <td class="datatable-td">{{ option.quantity || 0 }}</td>
                                                <!--
                                                <td class="datatable-td">{{ option.contract_price || '' }}</td>
                                                <td class="datatable-td">{{ option.selling_price || '' }}</td>
                                                -->
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td class="datatable-td">{{ formatDateToLocal(row.date) }}</td>
                                        <td class="datatable-td">{{ row.room ? row.room.number : '' }}</td>
                                        <td class="datatable-td">{{ row.room ? row.room.type.name : '' }}</td>
                                        <td class="datatable-td">{{ getOption(row, 'name') }}</td>
                                        <td class="datatable-td">{{ getOption(row, 'quantity') }}</td>
                                        <!--
                                        <td class="datatable-td">{{ getOption(row, 'contract_price') }}</td>
                                        <td class="datatable-td">{{ getOption(row, 'selling_price') }}</td>
                                        -->
                                    </tr>
                                </template>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div v-else style="min-height: 400px;" class="position-relative"
                         :class="{'loading-opacity': reportLoading}">
                        <app-empty-data-block :message="$t('empty_data_block_dummy_message')"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tab === 'summary'" class="card border-0 bg-transparent">
            <div v-if="typeLoading" class="card-body">
                <app-overlay-loader v-if="typeLoading"/>
            </div>
            <div v-if="!typeLoading" class="row pt-primary">
                <div class="col-md-7">
                    <app-chart
                        v-if="lineChart.labels.length > 0"
                        type="line-chart"
                        :height="380"
                        :maximum-range="20"
                        :labels="lineChart.labels"
                        :data-sets="lineChart.dataSet"
                    />
                </div>
                <div class="col-md-5">
                    <app-chart
                        v-if="doughChart.labels.length > 0"
                        class="mb-primary"
                        type="dough-chart"
                        :height="230"
                        :labels="doughChart.labels"
                        :data-sets="doughChart.dataSet"
                    />
                    <div v-if="doughChart.labels.length > 0" class="chart-data-list">
                        <div class="row">
                            <div class="col-12" v-for="(item, index) in doughChart.dataList" :key="index">
                                <div class="data-group-item px-0">
                                    <span class="square" :style="`background-color: ${item.color}`"/>
                                    <span class="value">{{ item.name }} - {{ item.value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!typeLoading" class="card-body p-0">
                <div class="datatable">
                    <div v-if="typeData && typeData.thead && typeData.thead.length > 0"
                         class="table-responsive custom-scrollbar table-view-responsive pt-3 mt-3">
                        <table class="table mb-0">
                            <thead>
                            <tr>
                                <th class="datatable-th pt-0">
                                    <span class="font-size-default"><span>{{ $t('date') }}</span></span>
                                </th>
                                <th class="datatable-th pt-0" v-for="name in typeData.thead">
                                    <span class="font-size-default"><span>{{ name }}</span></span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(type, date) in typeData.tbody">
                                <td class="datatable-td">{{ date }}</td>
                                <td v-for="item in type" class="datatable-td">{{ item }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else style="min-height: 400px;" class="position-relative"
                         :class="{'loading-opacity': typeLoading}">
                        <app-empty-data-block :message="$t('empty_data_block_dummy_message')"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {
    formatDateForServer,
    formatDateToLocal,
    lastMonth,
    lastWeek,
    thisMonth,
    thisWeek,
    today
} from "../../../../../common/Helper/Support/DateTimeHelper";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";

export default {
    name  : "AppHotelShare",
    mixins: [HelperMixin],
    props : {
        is_dashboard: false,
        hotel_id    : null,
        from_date   : today(),
        to_date     : today(),
        passcode    : null,
    },
    data() {
        return {
            TENANT_BASE_URL,
            reportLoading: false,
            typeLoading  : false,
            tab          : 'summary',
            range        : 'this_month',
            hotelName    : null,
            memberUrl    : null,
            typeData     : [],
            reportData   : [],
            formData     : {
                from_date: formatDateForServer(this.from_date),
                to_date  : formatDateForServer(this.to_date),
                passcode : this.passcode,
                hotel_id : this.hotel_id,
                maid_id  : 0,
            },
            lineChart    : {
                labels : [],
                dataSet: [],
            },
            doughChart   : {
                labels  : [],
                dataList: [],
                dataSet : [
                    {
                        borderWidth    : 0,
                        backgroundColor: [],
                        data           : []
                    }
                ],
            },
            colors       : [
                '#FF5733',
                '#FF8D1A',
                '#FFC300',
                '#DAF7A6',
                '#33FF57',
                '#1AFF8D',
                '#33FFC3',
                '#1ADAF7',
                '#3398FF',
                '#5733FF',
                '#8D1AFF',
                '#C300FF',
                '#F7A6DA',
                '#FF33C3',
                '#FF1A8D',
                '#DA1A1A',
                '#F7811A',
                '#F4D03F',
                '#82E0AA',
                '#58D68D'
            ]
        }
    },
    watch: {
        'formData.hotel_id': {
            handler  : function (value) {
                const hotel_id = value && typeof value !== "undefined" ? value : 0
                this.memberUrl = `${TENANT_BASE_URL}selectable/hotel/member?hotel_id=${hotel_id}`
            },
            immediate: true
        },
        range              : {
            handler  : function (value) {
                if (value === 'today') {
                    this.formData.from_date = today().format('YYYY-MM-DD')
                    this.formData.to_date   = today().format('YYYY-MM-DD')
                }
                if (value === 'this_week') {
                    this.formData.from_date = thisWeek()[0].format('YYYY-MM-DD')
                    this.formData.to_date   = thisWeek()[1].format('YYYY-MM-DD')
                }
                if (value === 'last_week') {
                    this.formData.from_date = lastWeek()[0].format('YYYY-MM-DD')
                    this.formData.to_date   = lastWeek()[1].format('YYYY-MM-DD')
                }
                if (value === 'this_month') {
                    this.formData.from_date = thisMonth()[0].format('YYYY-MM-DD')
                    this.formData.to_date   = thisMonth()[1].format('YYYY-MM-DD')
                }
                if (value === 'last_month') {
                    this.formData.from_date = lastMonth()[0].format('YYYY-MM-DD')
                    this.formData.to_date   = lastMonth()[1].format('YYYY-MM-DD')
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.queryUrl()
    },
    methods: {
        formatDateToLocal,
        queryUrl() {
            if (!this.formData.hotel_id) {
                return
            }

            const date         = []
            const colors       = []
            this.typeLoading   = true
            this.reportLoading = true

            if (this.formData.from_date) {
                date.push(formatDateForServer(this.formData.from_date))
            }
            if (this.formData.to_date) {
                date.push(formatDateForServer(this.formData.to_date))
            }

            const query     = `date=${date.join(',')}&passcode=${this.formData.passcode}&maid_id=${this.formData.maid_id}`
            const typeUrl   = `${TENANT_BASE_URL}api/hotel/type-report/share?hotel_id=${this.formData.hotel_id}&${query}`
            const reportUrl = `${TENANT_BASE_URL}api/hotel/house-keeping/share?hotel_id=${this.formData.hotel_id}&${query}`
            const hotelUrl  = `${TENANT_BASE_URL}api/hotel/${this.formData.hotel_id}/show?passcode=${this.formData.passcode}`

            axiosGet(reportUrl).then((res) => {
                this.reportData = res.data
            }).catch((error) => {
                this.$toastr.e(error.response.data.message)
            }).finally(() => {
                this.reportLoading = false
            })

            this.lineChart.labels                      = []
            this.lineChart.dataSet                     = []
            this.doughChart.labels                     = []
            this.doughChart.dataSet[0].backgroundColor = []
            this.doughChart.dataSet[0].data            = []
            this.doughChart.dataList                   = []

            axiosGet(typeUrl).then((res) => {
                this.typeData = res.data
                if (res.data.tbody) {
                    for (const date in res.data.tbody) {
                        if (date !== 'total') {
                            this.lineChart.labels.push(date)
                        }
                    }
                    for (const index in res.data.thead) {
                        const color   = this.getColors(colors)
                        const dataSet = []

                        this.doughChart.dataSet[0].backgroundColor.push(color)
                        this.doughChart.dataSet[0].data.push(res.data.tbody.total[index] || 0)
                        this.doughChart.labels.push(res.data.thead[index])
                        this.doughChart.dataList.push({
                            name : res.data.thead[index],
                            value: res.data.tbody.total[index] || 0,
                            color: color
                        })

                        for (const date in res.data.tbody) {
                            if (date !== 'total') {
                                dataSet.push(res.data.tbody[date][index] || 0)
                            }
                        }

                        this.lineChart.dataSet.push({
                            label          : res.data.thead[index],
                            borderColor    : color,
                            backgroundColor: 'transparent',
                            data           : dataSet,
                        })

                        colors.push(color)
                    }
                }
            }).catch((error) => {
                this.$toastr.e(error.response.data.message)
            }).finally(() => {
                this.typeLoading = false
            })

            axiosGet(hotelUrl).then((res) => {
                this.hotelName = res.data.name || null
            }).catch((error) => {
                this.$toastr.e(error.response.data.message)
                this.hotelName = null
            })
        },
        getOption(row, key) {
            if (!row.options) {
                return ''
            }
            if (row.options[0] && row.options[0][key]) {
                return row.options[0][key] || ''
            }
            return ''
        },
        randColors() {
            const r = Math.floor(Math.random() * 255);
            const g = Math.floor(Math.random() * 255);
            const b = Math.floor(Math.random() * 255);

            return "rgb(" + r + "," + g + "," + b + ")";
        },
        getColors(saved) {
            const randKey = Math.floor(Math.random() * this.colors.length);
            const color   = this.colors[randKey];

            if (saved.includes(color)) {
                return this.getColors(saved)
            }

            return color
        },
        submitClick() {
            this.queryUrl()
            this.$hub.$emit(`reload-hotel-share-table`)
        }
    }
}
</script>