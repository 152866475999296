<template>
    <modal id="ticket-history-modal"
           size="large"
           v-model="showModal"
           :hideSubmitButton="true"
           :title="$t('history')"
           :preloader="preloader">

        <app-table
            id="ticket-history-table"
            :options="options"
        />

    </modal>
</template>

<script>
import HelperMixin from "../../../../common/Mixin/Global/HelperMixin";
import TicketHistoryMixins from "../../Mixins/TicketHistoryMixins";
import ModalMixin from "../../../../common/Mixin/Global/ModalMixin";

export default {
    name  : "TicketHistoryModal",
    mixins: [HelperMixin, TicketHistoryMixins, ModalMixin],
    props : {
        ticketId: null
    }
}
</script>

