<template>
    <modal id="bulk-status-modal"
           size="large"
           v-model="showModal"
           :title="$fieldTitle('update', 'status', true)"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/status`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-12">
                    <app-form-group-selectable
                        type="multi-select"
                        :label="$t('room')"
                        list-value-field="name"
                        v-model="formData.rooms"
                        :chooseLabel="$t('room')"
                        :error-message="$errorMessage(errors, 'rooms')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/room?hotel_id=${hotel_id}`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="date"
                        :label="$t('date')"
                        v-model="formData.date"
                        :placeholder="formData.date ? $placeholder('date') : this.$t('not_added_yet')"
                        :error-message="$errorMessage(errors, 'date')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="time"
                        :label="$t('arrived_time')"
                        v-model="formData.arrived_time"
                        :error-message="$errorMessage(errors, 'arrived_time')"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('staying')"
                        list-value-field="name"
                        v-model="formData.staying_id"
                        :chooseLabel="$t('staying')"
                        :error-message="$errorMessage(errors, 'staying_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/staying`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('cleaning')"
                        list-value-field="name"
                        v-model="formData.cleaning_id"
                        :chooseLabel="$t('cleaning')"
                        :error-message="$errorMessage(errors, 'cleaning_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/cleaning`"
                        @change="updatePrice()"
                    />
                </div>
            </div>
        </form>
    </modal>
</template>

<style>
.form-group table td > .form-group > label {
    display : none;
}

.form-group table td > .form-group {
    margin-bottom : 0;
}
</style>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";
import {
    formatDateForServer,
    formatTimeForServer,
    today
} from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    name  : "StatusBulkCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel_id: null,
        rooms   : null,
    },
    data() {
        return {
            TENANT_BASE_URL,
            formData: {
                hotel_id: this.hotel_id,
                rooms   : this.rooms,
                date    : formatDateForServer(today()),
            },
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            formData.set('hotel_id', formatDateForServer(this.formData.hotel_id))

            formData.set('date', formatDateForServer(this.formData.date))

            formData.set('arrived_time', formatTimeForServer(this.formData.arrived_time))

            if (this.formData.rooms.length > 0) {
                formData.set('rooms', JSON.stringify(this.formData.rooms))
            }

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {
                hotel_id: this.hotel_id ? this.hotel_id : null,
                date    : formatDateForServer(today()),
            };
            $('#bulk-status-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'status-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

