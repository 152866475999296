<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('shortcoming')">
            <app-default-button
                :title="$fieldTitle('add', 'shortcoming', true)"
                v-if="$can('create_hotel_shortcoming')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="hotel-shortcoming-table"
            :options="options"
            @action="triggerActions"
        />

        <app-hotel-shortcoming-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('hotel-shortcoming-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import HotelShortcomingMixins from "../../../Mixins/HotelShortcomingMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "AppHotelShortcoming",
    mixins: [HelperMixin, HotelShortcomingMixins, TENANT_BASE_URL],
    data() {
        return {
            isModalActive: false,
            selectedUrl  : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/hotel/shortcoming/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>