import {DOCUMENTS} from '../../Config/ApiUrl'
import {ucFirst} from "../../../common/Helper/Support/TextHelper";
import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {formatDateToLocal} from "../../../common/Helper/Support/DateTimeHelper";

export default {
    data() {
        return {
            urlGenerator,
            options: {
                name            : this.$t('document'),
                url             : DOCUMENTS + `?is_expired=1`,
                datatableWrapper: false,
                tableShadow     : false,
                showHeader      : true,
                responsive      : true,
                showSearch      : false,
                showFilter      : false,
                showCount       : false,
                showClearFilter : false,
                columns         : [
                    {
                        title    : this.$t('employee_id'),
                        type     : 'custom-html',
                        key      : 'user',
                        isVisible: true,
                        modifier : (value) => {
                            return value.profile?.employee_id;
                        }
                    },
                    {
                        title    : this.$t('name'),
                        type     : 'custom-html',
                        key      : 'user',
                        isVisible: true,
                        modifier : (value) => {
                            return value.full_name;
                        }
                    },
                    {
                        title    : this.$t('designation'),
                        type     : 'custom-html',
                        key      : 'user',
                        isVisible: true,
                        modifier : (value) => {
                            return value.designation?.name;
                        }
                    },
                    {
                        title    : this.$t('type'),
                        type     : 'text',
                        key      : 'name',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('expired'),
                        type     : 'custom-html',
                        key      : 'expired',
                        isVisible: true,
                        modifier : (value) => {
                            return formatDateToLocal(value);
                        }
                    },
                ],
                paginationType  : "pagination",
                rowLimit        : 5,
                showAction      : false,
                orderBy         : 'desc',
                actionType      : "default",
            }
        }
    }
}