<template>
    <div class="datatable">
        <div class="table-responsive custom-scrollbar table-view-responsive">
            <table class="table text-center">
                <thead>
                <tr>
                    <th class="datatable-th pt-0">{{ $t('option') }}</th>
                    <th class="datatable-th pt-0">{{ $t('quantity') }}</th>
                    <th class="datatable-th pt-0">{{ $t('contract_price') }}</th>
                    <th class="datatable-th pt-0">{{ $t('selling_price') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data.options">
                    <td class="datatable-td">{{ item.name }}</td>
                    <td class="datatable-td">{{ item.quantity }}</td>
                    <td class="datatable-td">{{ item.contract_price }}</td>
                    <td class="datatable-td">{{ item.selling_price }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";

export default {
    name  : "HouseKeepingOption",
    mixins: [FormHelperMixins],
    props : {
        data  : {},
        tableId: {},
    },
}
</script>