<template>
    <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-primary" v-if="data.length > 0"
             v-for="(manual, rowIndex) in data" :key="'row' + rowIndex">
            <app-hotel-manual-preview-card
                :id="'manual'"
                :manual="manual"
                :actions="actions"
                @action-manual="triggerActions"
            />
        </div>
    </div>
</template>

<style>
.employee-preview-card img {
    object-fit : cover;
}
</style>

<script>
export default {
    name   : "ManualCardView",
    props  : {
        data   : {
            type    : Array,
            required: true
        },
        actions: {
            type: Array
        }
    },
    methods: {
        triggerActions(row, action, active) {
            this.$emit('getCardAction', row, action, active);
        }
    }
}
</script>
