<template>
    <div>
        <a href="#" @click.prevent="openModal">View</a>

        <app-hotel-lost-found-preview-modal
            v-if="isModalActive"
            v-model="isModalActive"
            :files="value"
            @close="isModalActive = false"
        />
    </div>
</template>

<script>
export default {
    name : "LostFoundGallery",
    props: {
        value  : {},
        tableId: {},
    },
    data() {
        return {
            isModalActive: false,
        }
    },
    methods: {
        openModal() {
            this.isModalActive = true;
        },
    }
}
</script>