import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";

export default {
	mixins: [DatatableHelperMixin],
	data() {
		return {
			urlGenerator,
			options: {
				name             : this.$t('status'),
				url              : `${TENANT_BASE_URL}app/hotel/status?hotel_id=${this.hotel.id}`,
				showHeader       : true,
				showCount        : true,
				showClearFilter  : true,
				tableShadow      : false,
				hotel            : this.hotel,
				tablePaddingClass: 'pt-primary',
				cardViewComponent: 'app-hotel-status-card',
				columns          : [
					{
						title    : this.$t('floor'),
						type     : 'text',
						key      : 'floor',
						isVisible: true,
					},
					{
						title    : this.$t('room_number'),
						type     : 'text',
						key      : 'number',
						isVisible: true,
					},
					{
						title    : this.$t('type'),
						type     : 'custom-html',
						key      : 'type',
						isVisible: true,
						modifier : (type) => {
							return type ? type.name : '';
						}
					},
					{
						title    : this.$t('remark'),
						type     : 'text',
						key      : 'remark',
						isVisible: true,
					},
					{
						title    : this.$t('actions'),
						type     : 'action',
						key      : 'id',
						isVisible: true
					},
				],
				filters          : [
					{
						title : this.$t('created'),
						type  : "date",
						key   : "date",
						option: ["today", "thisMonth", "last7Days", "thisYear"]
					},
					{
						title         : this.$t('floor'),
						type          : "multi-select-filter",
						key           : "floor",
						option        : [],
						listValueField: 'name',
					},
					{
						title         : this.$t('room_number'),
						type          : "multi-select-filter",
						key           : "number",
						option        : [],
						listValueField: 'name',
					},
					{
						title         : this.$t('cleaning'),
						type          : "multi-select-filter",
						key           : "cleaning_id",
						option        : [],
						listValueField: 'name',
					},
					{
						title         : this.$t('staying'),
						type          : "multi-select-filter",
						key           : "staying_id",
						option        : [],
						listValueField: 'name',
					},
					{
						title         : this.$t('status'),
						type          : "multi-select-filter",
						key           : "status_id",
						option        : [],
						listValueField: 'name',
					},
				],
				paginationType   : "pagination",
				responsive       : true,
				rowLimit         : 10,
				showAction       : true,
				orderBy          : 'asc',
				actionType       : "dropdown",
				actions          : [
					{
						title   : this.$t('create'),
						name    : 'create',
						type    : 'modal',
						modifier: row => this.$can('create_hotel_status') && row.status?.id <= 0
					},
					{
						title   : this.$t('edit'),
						name    : 'edit',
						type    : 'modal',
						modifier: row => this.$can('update_hotel_status') && row.status?.id > 0
					},
					{
						title    : this.$t('delete'),
						name     : 'delete',
						type     : 'modal',
						component: 'app-confirmation-modal',
						modalId  : 'app-confirmation-modal',
						url      : `${TENANT_BASE_URL}app/hotel/status/`,
						modifier : row => this.$can('delete_hotel_status') && row.status?.id > 0
					}
				],
			}
		}
	},
}