<template>
    <modal id="pricing-modal"
           size="large"
           v-model="showModal"
           :title="generateModalTitle('pricing')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : `${TENANT_BASE_URL}app/hotel/pricing`'
            @submit.prevent="submitData"
        >
            <div class="row">
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('type')"
                        list-value-field="name"
                        v-model="formData.type_id"
                        :chooseLabel="$t('type')"
                        :error-message="$errorMessage(errors, 'type_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/type?hotel_id=${hotel.id}`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group-selectable
                        type="select"
                        :label="$t('cleaning')"
                        list-value-field="name"
                        v-model="formData.cleaning_id"
                        :chooseLabel="$t('cleaning')"
                        :error-message="$errorMessage(errors, 'cleaning_id')"
                        :fetch-url="`${TENANT_BASE_URL}selectable/hotel/cleaning`"
                    />
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('contract_price')"
                        :placeholder="$placeholder('contract_price')"
                        v-model="formData.contract_price"
                        :error-message="$errorMessage(errors, 'contract_price')">
                    </app-form-group>
                </div>
                <div class="col-md-6">
                    <app-form-group
                        type="number"
                        :label="$t('selling_price')"
                        :placeholder="$placeholder('selling_price')"
                        v-model="formData.selling_price"
                        :error-message="$errorMessage(errors, 'selling_price')">
                    </app-form-group>
                </div>
                <div class="col-md-12">
                    <app-form-group
                        type="textarea"
                        label-alignment="top"
                        :label="$t('remark')"
                        :placeholder="$textAreaPlaceHolder('remark')"
                        v-model="formData.remark"
                        :error-message="$errorMessage(errors, 'remark')"
                        :required="false">
                    </app-form-group>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formDataAssigner} from "../../../../../common/Helper/Support/FormHelper";

export default {
    name  : "PricingCreateEditModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            TENANT_BASE_URL,
        }
    },
    methods: {
        submitData() {
            this.loading = true
            let url      = this.$refs.form.dataset["url"];
            let formData = formDataAssigner(new FormData, this.formData);

            if (this.selectedUrl) {
                // for file update need to send by post.
                formData.append('_method', 'PATCH');
            }

            formData.append('hotel_id', this.hotel.id);

            this.submitFromFixin('post', url, formData);
        },
        afterSuccess({data}) {
            this.formData = {};
            $('#pricing-modal').modal('hide');
            this.$emit('input', false);
            this.toastAndReload(data.message, 'pricing-table');
        },
        afterSuccessFromGetEditData(response) {
            this.preloader = false;
            this.formData  = response.data;
        },
    },
}
</script>

