<template>
    <div class="content-wrapper">
        <div class="position-relative">
            <app-overlay-loader v-if="preloader"/>
            <app-hotel-share :is_dashboard="true"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppHotelDashboard",
    data() {
        return {
            preloader: false,
        }
    },
    created() {
    },
    methods: {},
}
</script>