<template>
    <input :type="'color'"
           :name="name"
           :id="inputFieldId"
           :required="data.required"
           :class="'form-control '+data.inputClass"
           :placeholder="data.placeholder"
           :readonly="data.readOnly"
           :disabled="data.disabled"
           v-bind:value="value"
           v-on="listeners"
           :autocomplete="data.autocomplete"
    />
</template>

<style>
.form-control[type="color"] {
    height : 44px !important;
}
</style>

<script>
import {InputMixin} from './mixin/InputMixin.js';

export default {
    name  : "TextInput",
    mixins: [InputMixin]
}
</script>

