<template>
    <modal id="room-export-modal"
           v-model="showModal"
           :btn-label="$t('export')"
           :title="$t('export_room')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">
        <form ref="form" @submit.prevent="submitData">
            <app-form-group-selectable
                page="page"
                :required="true"
                type="select"
                :label="$t('floor')"
                list-value-field="floor"
                list-value-id="floor"
                v-model="queryObject.floor"
                :chooseLabel="$t('floor')"
                :fetch-url="`${TENANT_BASE_URL}selectable/hotel/room?group=floor&hotel_id=${hotel.id}`"
            />
            <app-form-group
                page="page"
                type="date"
                :label="$t('date')"
                v-model="queryObject.date"
                :placeholder="queryObject.date ? $placeholder('date') : this.$t('not_added_yet')"
            />
        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import {formatDateForServer, localTimeZone} from "../../../../../common/Helper/Support/DateTimeHelper";
import {urlGenerator} from "../../../../../common/Helper/AxiosHelper";
import {objectToQueryString} from "../../../../../common/Helper/Support/TextHelper";

export default {
    name  : "RoomExportModal",
    mixins: [FormHelperMixins, ModalMixin],
    props : {
        hotel: {
            type   : Object,
            default: {},
        },
    },
    data() {
        return {
            TENANT_BASE_URL,
            queryObject: {
                floor: null,
                date : formatDateForServer(new Date()),
            },
        }
    },
    methods: {
        submitData() {
            this.loading          = true;
            this.queryObject.date = formatDateForServer(this.queryObject.date);
            const query           = objectToQueryString(this.queryObject);
            window.location       = urlGenerator(`${this.apiUrl.EXPORT}/hotel/${this.hotel.id}?${query}&timeZone=${localTimeZone}`);
            this.queryObject      = {};
            $('#room-export-modal').modal('hide');
            this.$emit('input', false);
        },
        afterSuccessFromGetEditData() {
        },
    },
}
</script>

