<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('visit')">
            <app-default-button
                :title="$fieldTitle('add', 'visit', true)"
                v-if="$can('create_sale_visit')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="sale-visit-table"
            :options="options"
            @action="triggerActions"
        />

        <app-sale-visit-create
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('sale-visit-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import SaleVisitMixin from "../../../Mixins/SaleVisitMixin";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";

export default {
    name  : "SaleVisit",
    mixins: [HelperMixin, SaleVisitMixin, TENANT_BASE_URL],
    data() {
        return {
            isModalTypeActive: false,
            isModalActive    : false,
            selectedUrl      : '',
        }
    },
    methods: {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.selectedUrl   = `${TENANT_BASE_URL}app/sale-visit/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        openModal() {
            this.isModalActive = true;
            this.selectedUrl   = ''
        },
    }
}
</script>