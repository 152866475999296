<template>
    <modal id="template-modal"
           v-model="showModal"
           :title="generateModalTitle('template')"
           @submit="submitData" :loading="loading"
           :preloader="preloader">

        <form
            ref="form"
            :data-url='selectedUrl ? selectedUrl : TEMPLATE'
            @submit.prevent="submitData"
        >
            <app-form-group
                :label="$t('name')"
                :placeholder="$placeholder('name')"
                v-model="formData.name"
                :required="true"
                :error-message="$errorMessage(errors, 'name')">
            </app-form-group>

            <div class="row">
                <div class="col-md-6">
                    <app-form-group-selectable
                        :label="$t('number_type')"
                        type="search-select"
                        v-model="formData.number_type"
                        list-value-field="name"
                        :placeholder="$t('number_type')"
                        :error-message="$errorMessage(errors, 'number_type')"
                        :options="[
                            {name: $t('auto'), id: 1},
                            {name: $t('manual'), id: 2}
                        ]"
                    />
                </div>

                <div class="col-md-6">
                    <app-form-group
                        :label="$t('prefix')"
                        :placeholder="$placeholder('prefix')"
                        v-model="formData.prefix"
                        :required="true"
                        :error-message="$errorMessage(errors, 'prefix')">
                    </app-form-group>
                </div>
            </div>

            <app-form-group
                type="textarea"
                :label="$t('remark')"
                :placeholder="$textAreaPlaceHolder('remark')"
                v-model="formData.remark"
                :required="false"
                :text-area-rows="3"
                :error-message="$errorMessage(errors, 'remark')">
            </app-form-group>

            <div class="form-group">
                <label>{{ $t('content') }}</label>
                <app-input
                    type="text-editor"
                    v-model="formData.content"
                    id="text-editor-for-template"
                    :placeholder="$textAreaPlaceHolder('content')"
                    row="5"
                    :error-message="$errorMessage(errors, 'content')"
                />
            </div>

            <div class="form-group text-center">
                <button
                    type="button"
                    class="btn btn-sm btn-primary px-3 py-1 ml-2 mt-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    v-for="item in markItems"
                    :title="item"
                    @click="addMark(item)"
                >
                    {{ item }}
                </button>
            </div>

        </form>
    </modal>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import ModalMixin from "../../../../../common/Mixin/Global/ModalMixin";
import {AGREEMENT_ITEM, TEMPLATE} from "../../../../Config/ApiUrl";
import {axiosGet} from "../../../../../common/Helper/AxiosHelper";
import {LANGUAGES} from "../../../../../common/Config/apiUrl";

export default {
        name: "TemplateCreateEditModal",
        mixins: [FormHelperMixins, ModalMixin],
        data() {
            return {
                formData: {},
                TEMPLATE,
                markItems: [
                    '{date}', '{first_name}', '{last_name}', '{birthday}',
                    '{mobile}', '{email}', '{address}',
                ],
            }
        },
        methods: {
            afterSuccess({data}) {
                this.formData = {};
                $('#template-modal').modal('hide');
                this.$emit('input', false);
                this.toastAndReload(data.message, 'template-table');
            },
            afterSuccessFromGetEditData(response) {
                this.preloader = false;
                this.formData = response.data;
            },
            addMark(item) {
                const editor = $("#text-editor-for-template");
                editor.summernote('editor.saveRange');
                editor.summernote('editor.restoreRange');
                editor.summernote('editor.focus');
                editor.summernote('editor.insertText', item);
            },
        },
        mounted() {
            /*
            axiosGet(`${AGREEMENT_ITEM}?get-all=1`).then(({data}) => {
                this.agreementItems = data || [];
            });
            */
        }
}
</script>

