<template>
    <div>
        <app-page-top-section :title="$t('status')">
            <app-default-button
                :title="$fieldTitle('add', 'status', true)"
                v-if="$can('create_hotel_house_keeping_status')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-overlay-loader v-if="preloader"/>

        <div v-else>
            <draggable :forceFallback="true"
                tag="div"
                :list="statusList"
                v-bind="dragOptions"
                @start="dragstart($event)"
                @end="dragend">
                <div class="row align-items-center py-half-primary" v-for="(status, index) in statusList" :key="'status-'+index">
                    <div class="col-4">
                        <div class="d-flex align-items-center text-capitalize">
                            <div class="cursor-grab mr-3">
                                <app-icon name="align-justify" class="text-muted"/>
                            </div>
                            {{ status.name }}
                        </div>
                    </div>
                    <div class="col-4">
                        <span class="badge" :style="{'background-color': status.color}">{{ status.color }}</span>
                    </div>
                    <div class="col-4">
                        <div class="text-right mt-3 mt-lg-0">
                            <div class="btn-group btn-group-action" role="group">
                                <button class="btn"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('edit')"
                                    @click="editToggle(status)"
                                    v-if="$can('update_hotel_house_keeping_status')">
                                    <app-icon name="edit"/>
                                </button>
                                <button class="btn"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    :title="$t('delete')"
                                    @click="openDeleteModal(status)"
                                    v-if="$can('delete_hotel_house_keeping_status')">
                                    <app-icon name="trash-2"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>

        <!--
        <app-table
            id="status-table"
            :options="options"
            @action="triggerActions"
        />
        -->

        <app-hotel-house-keeping-status-create
            v-if="isModalActive"
            v-model="isModalActive"
            :hotel="tab.hotel"
            :selected-url="roomUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="deleteModalConfirm"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import FormHelperMixins from "../../../../../common/Mixin/Global/FormHelperMixins";
import {TENANT_BASE_URL} from "../../../../../common/Config/UrlHelper";
import HotelHouseKeepingStatusMixins from "../../../Mixins/HotelHouseKeepingStatusMixins";
import {axiosDelete, axiosGet, axiosPatch} from "../../../../../common/Helper/AxiosHelper";
import draggable from "vuedraggable";

export default {
    name      : "HouseKeepingStatus",
    components: {draggable},
    mixins    : [FormHelperMixins, HotelHouseKeepingStatusMixins],
    props     : {
        id: {
            type: String
        }
    },
    data() {
        return {
            tab          : this.$parent.$props.tabs.find(e => e.component === 'app-hotel-house-keeping-status'),
            preloader    : true,
            isModalActive: false,
            roomUrl      : '',
            deleteUrl    : '',
            statusList   : [],
        }
    },
    mounted() {
        this.reloadStatus()
        this.$hub.$on('reload-status-table', () => this.reloadStatus())
    },
    computed: {
        url() {
            return `${TENANT_BASE_URL}app/hotel/house-keeping-status?hotel_id=${this.tab.hotel.id}&per_page=0`;
        },
        dragOptions() {
            return {
                animation: 300
            };
        },
    },
    methods : {
        triggerActions(row, action, active) {
            if (action.title === this.$t('edit')) {
                this.roomUrl       = `${TENANT_BASE_URL}app/hotel/house-keeping-status/${row.id}`;
                this.isModalActive = true;
            } else {
                this.getAction(row, action, active)
            }
        },
        reloadStatus() {
            this.preloader = true;

            axiosGet(this.url).then(({data}) => this.statusList = data).catch(error => {
                if (error.response) {
                    this.toastException(error.response.data)
                }
            }).finally(() => {
                this.preloader = false;
            })
        },
        openModal() {
            this.isModalActive = true;
            this.roomUrl       = ''
        },
        editToggle(row) {
            this.roomUrl       = `${TENANT_BASE_URL}app/hotel/house-keeping-status/${row.id}`;
            this.isModalActive = true;
        },
        deleteModalConfirm() {
            return axiosDelete(this.deleteUrl).then(response => {
                this.toastAndReload(response.data.message, 'status-table')
                this.confirmationModalActive = false;
            }).catch((error) => {
                if (error.response) {
                    this.toastException(error.response.data)
                }
            });
        },
        openDeleteModal(row) {
            this.deleteUrl               = `${TENANT_BASE_URL}app/hotel/house-keeping-status/${row.id}`
            this.confirmationModalActive = true
        },
        dragstart(ev) {
            this.drag = true;
            ev.item.classList.add('catch-item');
            ev.target.classList.add('catch-container');
        },
        async dragend(ev) {
            this.preloader = true;
            this.drag      = false;
            ev.item.classList.remove('catch-item');
            ev.target.classList.remove('catch-container');

            for (const index in this.statusList) {
                try {
                    const status    = this.statusList[index];
                    const updateUrl = `${TENANT_BASE_URL}app/hotel/house-keeping-status/${status.id}`;
                    status.order    = index

                    await axiosPatch(updateUrl, status)
                } catch (e) {
                    this.toastException(e)
                }
            }

            this.preloader = false;
        },
    },
}
</script>