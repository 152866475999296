import {urlGenerator} from "../../../common/Helper/AxiosHelper";
import {TENANT_BASE_URL} from "../../../common/Config/UrlHelper";
import DatatableHelperMixin from "../../../common/Mixin/Global/DatatableHelperMixin";

export default {
    mixins: [DatatableHelperMixin],
    data() {
        const tab = this.$parent.$props.tabs.find(e => e.component === 'app-hotel-room')
        return {
            urlGenerator,
            options: {
                name             : this.$t('room'),
                url              : `${TENANT_BASE_URL}app/hotel/room?hotel_id=${this.hotel ? this.hotel.id : tab.hotel.id}`,
                showHeader       : true,
                showCount        : true,
                showClearFilter  : true,
                tableShadow      : false,
                tablePaddingClass: 'pt-primary',
                cardViewComponent: 'app-hotel-room-card',
                columns          : [
                    {
                        title    : this.$t('floor'),
                        type     : 'text',
                        key      : 'floor',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('room_number'),
                        type     : 'text',
                        key      : 'number',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('type'),
                        type     : 'custom-html',
                        key      : 'type',
                        isVisible: true,
                        modifier : (type) => {
                            return type ? type.name : '';
                        }
                    },
                    {
                        title    : this.$t('remark'),
                        type     : 'text',
                        key      : 'remark',
                        isVisible: true,
                    },
                    {
                        title    : this.$t('actions'),
                        type     : 'action',
                        key      : 'id',
                        isVisible: true
                    },
                ],
                filters          : [
                    {
                        title         : this.$t('floor'),
                        type          : "multi-select-filter",
                        key           : "floor",
                        option        : [],
                        listValueField: 'name',
                    },
                    {
                        title : this.$t('created'),
                        type  : "range-picker",
                        key   : "date",
                        option: ["today", "thisMonth", "last7Days", "thisYear"]
                    },
                ],
                paginationType   : "pagination",
                responsive       : true,
                rowLimit         : 10,
                showAction       : true,
                orderBy          : 'desc',
                actionType       : "dropdown",
                actions          : [
                    {
                        title   : this.$t('edit'),
                        name    : 'edit',
                        type    : 'modal',
                        modifier: row => this.$can('update_hotel_room')
                    },
                    {
                        title    : this.$t('delete'),
                        name     : 'delete',
                        type     : 'modal',
                        component: 'app-confirmation-modal',
                        modalId  : 'app-confirmation-modal',
                        url      : `${TENANT_BASE_URL}app/hotel/room/`,
                        modifier : row => this.$can('delete_hotel_room')
                    }
                ],
            }
        }
    },
}