<template>
    <div class="content-wrapper">
        <app-page-top-section :title="$t('template')">
            <app-default-button
                :title="$fieldTitle('add', 'template', true)"
                v-if="$can('create_designations')"
                @click="openModal()"
            />
        </app-page-top-section>

        <app-table
            id="template-table"
            :options="options"
            @action="triggerActions"
        />

        <app-template-modal
            v-if="isModalActive"
            v-model="isModalActive"
            :selected-url="selectedUrl"
            @close="isModalActive = false"
        />

        <app-confirmation-modal
            v-if="confirmationModalActive"
            icon="trash-2"
            modal-id="app-confirmation-modal"
            @confirmed="confirmed('template-table')"
            @cancelled="cancelled"
        />
    </div>
</template>

<script>
import HelperMixin from "../../../../../common/Mixin/Global/HelperMixin";
import { TEMPLATE } from "../../../../Config/ApiUrl";
import TemplateMixin from "../../../Mixins/TemplateMixin";

export default {
        name: "AppTemplate",
        mixins: [HelperMixin, TemplateMixin],
        data() {
            return {
                isModalActive: false,
                selectedUrl: '',
            }
        },

        methods: {
            async triggerActions(row, action, active) {
                if (action.title === this.$t('edit')) {
                    this.selectedUrl = `${TEMPLATE}/${row.id}`;
                    this.isModalActive = true;
                } else if (action.title === this.$t('copy')) {
                    try {
                        await navigator.clipboard.writeText(row.sign_contract_url);
                        this.$toastr.s("", this.$t('copied'));
                    } catch (err) {
                        this.$toastr.s("", this.$t('failed_to_copy'));
                    }
                } else if (action.title === this.$t('setting')) {
                    window.location.href = row.advanced_edit_url;
                } else {
                    this.getAction(row, action, active)
                }
            },

            openModal() {
                this.isModalActive = true;
                this.selectedUrl = ''
            },
        },
    }
</script>
